<template>
    <div class="base-content container">
        <div class="row flex-lg-row flex-column-reverse">
            <div class="col-lg-7 order-lg-1 order-2 d-flex">
                <el-skeleton :rows="12" :loading="loading" class="goboard" />
                <div ref="parent" class="goboard" v-show="!loading">
                    <canvas ref="canvas" tabindex="0"></canvas>
                </div>
            </div>
            <div class="col-lg-5 order-lg-2 order-1">
                <div class="mode-select">
                    <el-tabs type="border-card" :stretch=true tab-position="top" class="play-board">
                        <el-tab-pane label="挑战AI">
                            <AISettings @update_handicap="update_handicap" @update_size="update_size" />
                        </el-tab-pane>
                        <el-tab-pane label="匹配对手">
                            <GoMatchBoard />
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { IndexBoard } from '@/assets/scripts/board/IndexBoard';
import GoMatchBoard from '@/components/go/GoMatchBoard.vue'
import AISettings from '@/components/go/AISettings.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { ref } from 'vue';
import { onMounted } from 'vue';
import { ElMessageBox } from 'element-plus';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    components: {
        GoMatchBoard,
        AISettings,
    },

    setup() {
        const store = useStore();
        const router = useRouter();
        let parent = ref(null);
        let canvas = ref(null);
        let loading = ref(true);
        let handicap = ref(0);
        let size = ref(19);

        let b;

        onMounted(() => {
            b = new IndexBoard(canvas.value.getContext('2d'), parent.value, size, handicap);
            loading.value = false;
            if (store.state.gogame.room_id != null && store.state.gogame.room_id != "" && store.state.gogame.socket.readyState === 1) {
                ElMessageBox.confirm('您有未下完的棋局，是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    center: true,
                    type: 'warning'
                }).then(() => {
                    // 进入房间
                    sessionStorage.setItem('game_status', 'playing');
                    router.push({
                        name: 'playroom',
                        params: {
                            roomId: store.state.gogame.room_id,
                        },
                    });
                }).catch(() => {
                    sessionStorage.setItem('game_status', 'waiting');
                    store.commit("updateRoomId", null);
                    socket.send(JSON.stringify({
                        event: "play",
                        x: -1,
                        y: -1,
                    }));
                });
            }
        })

        const update_handicap = (_handicap) => {
            handicap.value = _handicap;
        }

        const update_size = (_size) => {
            size.value = _size;
        }

        onBeforeRouteLeave((to, from, next) => {
            b.destory();
            next();
        })

        return {
            parent,
            canvas,
            loading,
            update_handicap,
            update_size,
        }
    },
}

</script>

<style scoped>
.base-content {
    margin-top: 9vh;
}

canvas {
    display: inline-block;
    background-image: url('https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/board.90ff7d60.png');
    background-size: 70%;
    cursor: pointer;
    box-shadow: 3px 3px 3px #b9b9b9;
}

.play-board {
    border: 2px solid #d6d1d1;
    background-color: rgb(230, 231, 234);
    width: auto;
    height: auto;
    box-shadow: 3px 3px 3px #b9b9b9;
}

.goboard {
    width: 100%;
    height: 90vh;
}

.mode-select {
    width: 40vw;
    height: auto;
}

/* 在小于1000px的屏幕上，取消宽度设置 */
@media (max-width: 991.98px) {
    .goboard {
        width: 100%;
        height: 85vh;
    }

    .mode-select {
        width: auto;
        height: auto;
    }
}
</style>