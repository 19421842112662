<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card">
                    <div class="card-body" style="text-align: center;">
                        <img class="photo" :src="$store.state.user.avatar" alt="头像">
                        <hr>
                        <div class="d-grid gap-2 col-4 mx-auto">
                            <el-upload :action="`${baseURL}/user/update/avatar`" :headers="headers" :show-file-list=false
                                name="file" :before-upload="beforeAvatarUpload" :limit="1" :on-success="uploadSuccess">
                                <el-button type="info" plain>点击上传</el-button>
                            </el-upload>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card">
                    <div class="card-body">
                        <h3>个人信息</h3>
                        <hr>
                        <div class="mb-3 row mx-auto">
                            <label for="username" class="col-sm-2 col-form-label user-info"
                                style="float: right;">用户名</label>
                            <div class="col-sm-4">
                                <input v-model="username" type="text" class="form-control" id="username" maxlength="15">
                            </div>
                        </div>
                        <div class="mb-3 row mx-auto">
                            <label for="profile" class="col-sm-2 col-form-label user-info">个人简介</label>
                            <div class="col-sm-4">
                                <input v-model="profile" type="text" class="form-control" id="profile" rows="1"
                                    maxlength="10" />
                            </div>
                        </div>

                        <div class="d-grid gap-2 col-2 mx-auto">
                            <el-button type="primary" @click="update_user">更新信息</el-button>
                        </div>
                    </div>

                </div>
                <div class="card" style="margin-top: 5vh;">
                    <div class="card-body">
                        <h3>个人安全</h3>
                        <hr>
                        <div class="row user-security">
                            <div class="col-sm-4">
                                <img src="https://cdn.acwing.com/static/web/img/user/profile/security/password.jpg"
                                    style="width: 25px; margin-left:10px;" alt="">
                                <span>&nbsp;密码</span>
                            </div>
                            <div class="col-sm-4">
                                <span style="color: rgb(82, 153, 82);">已设置</span>
                            </div>
                            <div class="col-sm-4 mx-auto">
                                <!-- Button trigger modal -->
                                <button type="button" class="btn btn-link" style="text-decoration:none"
                                    data-bs-toggle="modal" data-bs-target="#modifyPassword">修改密码</button>
                            </div>
                            <!-- Modal -->
                            <div class="modal fade" id="modifyPassword" tabindex="-1">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">修改密码</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>

                                        <div class="modal-body">
                                            <form>
                                                <div class="mb-3">
                                                    <label for="old_password" class="form-label">原密码</label>
                                                    <input v-model="updatePassword.old_password" type="password"
                                                        class="form-control" id="old_password">
                                                </div>
                                                <div class="mb-3">
                                                    <label for="new_password" class="form-label">新密码</label>
                                                    <input v-model="updatePassword.new_password" type="password"
                                                        class="form-control" id="new_password">
                                                </div>
                                                <div class="mb-3">
                                                    <label for="confirm_password" class="form-label">确认密码</label>
                                                    <input v-model="updatePassword.confirm_password" type="password"
                                                        class="form-control" id="confirm_password">
                                                </div>
                                            </form>
                                        </div>
                                        <div class="modal-footer modal-center-component">
                                            <el-button @click="update_password" type="primary" plain>确认</el-button>
                                            <el-button type="info" plain data-bs-dismiss="modal">取消</el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <!--update phone_number-->
                        <div class="row user-security">
                            <div class="col-sm-4">
                                <img src="https://cdn.acwing.com/static/web/img/user/profile/security/cell-phone.png"
                                    style="width: 25px; margin-left:10px;" alt="">
                                <span>&nbsp;手机号</span>
                            </div>
                            <div class="col-sm-4">
                                <span style="color: rgb(82, 153, 82);">已设置</span>
                            </div>
                            <div class="col-sm-4 mx-auto">
                                <button type="button" class="btn btn-link" style="text-decoration:none"
                                    data-bs-toggle="modal" data-bs-target="#modifyPhone">修改手机号</button>
                            </div>
                            <!-- 修改手机号模态框 -->
                            <div class="modal fade" id="modifyPhone" tabindex="-1">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">修改手机号</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>

                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-3">
                                                    <label for="old_phone" class="form-label">原手机号</label>
                                                </div>
                                                <div class="col-auto">
                                                    <input v-model="updatePhone.old_phone" type="text" class="form-control"
                                                        id="old_phone" disabled="true">
                                                </div>

                                            </div>
                                            <div class="row row-item">
                                                <div class="col-3">
                                                    <label for="new_phone" class="form-label">新手机号</label>
                                                </div>
                                                <div class="col-auto">
                                                    <input v-model="updatePhone.new_phone" type="text" class="form-control"
                                                        id="new_phone">
                                                </div>

                                            </div>
                                            <div class="row row-item">
                                                <div class="col-3">
                                                    <label for="verification_code" class="form-label">验证码</label>
                                                </div>
                                                <div class="col-auto">
                                                    <input v-model="updatePhone.verification_code" type="text"
                                                        class="form-control" id="verification_code">
                                                </div>

                                                <div class="col-3">
                                                    <button class="btn btn-light btn-sm" @click="send_verification_code"
                                                        :disabled="totalTime != 60" :v-text="countdown_content">
                                                        {{ countdown_content }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer modal-center-component">
                                            <el-button @click="update_phone" type="primary" plain>确认</el-button>
                                            <el-button type="info" plain data-bs-dismiss="modal">取消</el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { reactive, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap/dist/js/bootstrap'
import { ElMessage } from 'element-plus';
import axios from 'axios';

export default {
    setup() {
        const store = useStore();
        const baseURL = axios.defaults.baseURL;
        let profile = ref("");
        let username = ref("");
        let send_status = ref('');
        let countdown_content = ref('发送');
        let totalTime = ref(60);
        let fileList = ref([]);
        let updatePassword = reactive({
            old_password: "",
            new_password: "",
            confirm_password: "",
        });
        let updatePhone = reactive({
            old_phone: store.state.user.phone,
            new_phone: "",
            verification_code: "",
        });
        let update_success = ref(false);

        onMounted(() => {
            pull_user_info();
        });

        const pull_user_info = () => {
            axios.get('/user/info').then(resp => {
                profile.value = resp.data.profile;
                username.value = resp.data.username;
                updatePhone.old_phone = resp.data.phone;
            })
        };

        const update_user = () => {
            if (profile.value.trim().length > 10) {
                ElMessage.error("简介长度不能大于10");
                return;
            }
            axios.post('/user/update/info', {
                username: username.value,
                profile: profile.value
            }).then(resp => {
                if (resp.code === 200) {
                    ElMessage.success("更新成功！");
                    store.commit("updateUser", {
                        username: username.value,
                        profile: profile.value,
                    });
                } else if (resp.code == 400) {
                    ElMessage.warning(resp.data);
                    username.value = store.state.user.username;
                    profile.value = store.state.user.profile;
                }
            })
        }

        const update_password = () => {
            store.dispatch("update_password", {
                ...updatePassword,
                success() {
                    Modal.getInstance("#modifyPassword").hide();
                    ElMessage.success("更新成功！");
                    updatePassword.old_password = "";
                    updatePassword.new_password = "";
                    updatePassword.confirm_password = "";
                },
                error(resp) {
                    ElMessage.warning(resp.data);
                }
            })
        }

        const update_phone = () => {
            store.dispatch("update_phone", {
                ...updatePhone,
                success() {
                    update_success.value = true;
                    Modal.getInstance("#modifyPhone").hide();
                    ElMessage.success("更新成功！");
                    updatePhone.old_phone = updatePhone.new_phone;
                    updatePhone.new_phone = "";
                    updatePhone.verification_code = "";
                },
                error(resp) {
                    ElMessage.warning(resp.data);
                }
            })
        }

        const send_verification_code = () => {
            if (updatePhone.new_phone == '') {
                ElMessage.error("新手机号不能为空");
                return;
            } else if (updatePhone.old_phone == updatePhone.new_phone) {
                ElMessage.error("新旧手机号不能相同");
                return;
            }
            update_success.value = false;
            axios.get('/account/password/update/verification', {
                params: {
                    phone_number: updatePhone.new_phone,
                }
            }).then(resp => {
                if (resp.code != 200) {
                    ElMessage.error(resp.data);
                } else {
                    send_status.value = "发送成功！";
                    let clock = window.setInterval(() => {
                        totalTime.value--;
                        countdown_content.value = totalTime.value + 's';
                        if (totalTime.value < 0 || update_success.value) {     // 当倒计时小于0时清除定时器
                            window.clearInterval(clock);
                            countdown_content.value = '发送';
                            send_status.value = "";
                            totalTime.value = 60;
                        }
                    }, 1000);
                }
            }).catch({

            })
        }

        return {
            profile,
            username,
            updatePassword,
            updatePhone,
            fileList,
            totalTime,
            countdown_content,
            baseURL,
            update_user,
            update_password,
            update_phone,
            send_verification_code,
            headers: {
                Authorization: "Bearer " + store.state.user.token,
            },
        }
    },

    methods: {
        // 上传之前的回调
        beforeAvatarUpload(file) {
            const isLt50M = file.size / 1024 / 1024 < 5;
            const fileType = file.type;
            const isJPG = fileType === 'image/jpg' || fileType === 'image/jpeg' || fileType === 'image/png'
            if (!isJPG) {
                this.$message.error('上传图片的格式只能是 JPG或PNG 格式!')
                return false;
            }
            if (!isLt50M) {
                this.$message.error('上传视频大小不能超过 5MB!');
                return false;
            }
            return true;
        },

        // 上传成功的回调
        uploadSuccess(obj) {
            if (obj.code == 200) {
                this.$message.success({
                    dangerouslyUseHTMLString: true,
                    message: "上传成功",
                })
                this.$store.commit("updateAvatar", obj.data);
            } else {
                this.$message.warning(obj.data);
            }
        },
    }
}
</script>


<style scoped>
.user-security {
    display: flex;
    align-items: center;
}

span {
    padding: 6px, 12px;
}

.photo {
    width: 80%;
    height: 80%;
}

.card {
    margin-top: 10vh;
}

.user-info {
    text-align: right;
}

.row-item {
    margin-top: 20px;
}

.modal-center-component {
    display: flex;
    justify-content: center;
}
</style>