<template>
    <div class="d-flex justify-content-center">
        <div ref="year_record_heatmap" class="heatmap-chart"></div>
    </div>
    <div class="d-flex justify-content-center">
        <div ref="year_puzzle_heatmap" class="heatmap-chart"></div>
    </div>
    <div class="d-flex justify-content-evenly">
        <el-card class="box-card-flex">
            <el-row>
                <el-col :span="6">
                    <span class="card-name">解题数量</span>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10" class="mt-3">
                    <el-progress type="circle" :percentage="finished_rate">
                        <span style="font-size: xx-large; color: black; font-weight: 500;">{{ puzzleCount }}</span>
                        <br>
                        <div class="mt-2">
                            <el-text type="info" size="large">解决问题</el-text>
                        </div>
                    </el-progress>
                </el-col>
                <el-col :span="14">
                    <el-row>
                        <el-col :span="24">
                            <div class="mb-1">
                                <div class="d-flex justify-content-between">
                                    <span class="card-name">简单</span>
                                    <span class="card-name">
                                        <span class="finished-count">{{ solvedEasy }} </span>/ {{ totalEasy }}</span>
                                </div>
                            </div>
                            <el-progress :text-inside="true" :stroke-width="12" :percentage="solvedEasy * 100 / totalEasy"
                                status="success" :show-text="false" class="progress-success" color="#00b386" />
                        </el-col>
                    </el-row>
                    <el-row class="mt-2">
                        <el-col :span="24">
                            <div class="mb-1">
                                <div class="d-flex justify-content-between">
                                    <span class="card-name">中等</span>
                                    <span class="card-name">
                                        <span class="finished-count">{{ solvedMiddle }} </span>/ {{ totalMiddle }}</span>
                                </div>
                            </div>
                            <el-progress :text-inside="true" :stroke-width="12"
                                :percentage="solvedMiddle * 100 / totalMiddle" status="warning" :show-text="false"
                                class="progress-warning" color="#ffb31a" />
                        </el-col>
                    </el-row>
                    <el-row class="mt-2">
                        <el-col :span="24">
                            <div class="mb-1">
                                <div class="d-flex justify-content-between">
                                    <span class="card-name">困难</span>
                                    <span class="card-name">
                                        <span class="finished-count">{{ solvedHard }} </span>/ {{ totalHard }}</span>
                                </div>
                            </div>
                            <el-progress :text-inside="true" :stroke-width="12" :percentage="solvedHard * 100 / totalHard"
                                status="exception" :show-text="false" class="progress-danger" color="#ff3333" />
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="box-card-flex">
            <el-row>
                <el-col :span="12">
                    <span class="card-name">勋章成就
                        <span class="finished-count">
                            0
                        </span>
                    </span>
                </el-col>
                <el-col :span="12">
                    <span class="card-name">解题积分
                        <span class="finished-count">
                            {{ puzzlePoint }}
                        </span>
                    </span>
                </el-col>
            </el-row>
        </el-card>
    </div>
    <el-divider />
    <div class="d-flex justify-content-center">
        <div ref="total_record_pie" class="record-chart"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
    props: {
        userId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        let year_record_heatmap = ref();
        let year_puzzle_heatmap = ref();
        let total_record_pie = ref();

        let records_year_count;
        let puzzle_year_count;
        let win = ref(0);
        let lose = ref(0);
        let solvedEasy = ref(0);
        let solvedMiddle = ref(0);
        let solvedHard = ref(0);
        let puzzlePoint = ref(0);
        let totalEasy = ref(0);
        let totalMiddle = ref(0);
        let totalHard = ref(0);
        let playCount = ref(0);
        let puzzleCount = ref(0);
        let total_record_option, year_record_option, year_puzzle_option;
        let finished_rate = ref(0.0);

        onMounted(() => {
            get_records_year_count();
        })

        const echats_init = () => {
            // 年度对局记录统计
            let year_record_chart = echarts.init(year_record_heatmap.value, null, { renderer: 'svg' });
            let year_puzzle_chart = echarts.init(year_puzzle_heatmap.value, null, { renderer: 'svg' });
            // 总战绩统计
            let total_record_chart = echarts.init(total_record_pie.value);
            year_record_option && year_record_chart.setOption(year_record_option);
            year_puzzle_option && year_puzzle_chart.setOption(year_puzzle_option);
            total_record_option && total_record_chart.setOption(total_record_option);
        }

        const init_options = () => {
            // 总战绩config
            total_record_option = {
                tooltip: {
                    trigger: 'item'
                },
                title: {
                    text: '总战绩',
                    left: '5%'
                },
                color: ['green', 'lightgray'],
                legend: {
                    left: 'center'
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        position: 'left',
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'left'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: win.value, name: '胜' },
                            { value: lose.value, name: '负' }
                        ]
                    }
                ]
            };
            // 年度对局统计config
            year_record_option = {
                title: {
                    top: 20,
                    left: 70,
                    text: '过去一年共对局' + playCount.value + '场',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: (p) => {
                        let a = p.data;
                        return a[0] + "日 对局" + a[1] + "次";
                    }
                },
                visualMap: {
                    min: 0,
                    max: 15,
                    type: 'piecewise',
                    orient: 'horizontal',
                    right: 70,
                    top: 20,
                    target: {
                        inRange: {
                            color: ['#ccffcc', '#80ff80', '#00e600', '#009900', '#006600']
                        },
                        outOfRange: {
                            color: '#006600'
                        }
                    },
                    controller: {
                        inRange: {
                            color: ['#ccffcc', '#80ff80', '#00e600', '#009900', '#006600']
                        },
                        outOfRange: {
                            color: '#006600'
                        }
                    },
                },
                calendar: {
                    top: 80,
                    left: 30,
                    right: 30,
                    cellSize: [15, 15],
                    range: '2023',
                    splitLine: {
                        show: false
                    },
                    dayLabel: {
                        show: false
                    }
                },
                series: {
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    data: records_year_count
                }
            };
            // 年度做题统计config
            year_puzzle_option = {
                title: {
                    top: 5,
                    left: 70,
                    text: '过去一年共解题' + puzzleCount.value + '道',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: (p) => {
                        let a = p.data;
                        return a[0] + "日 做题" + a[1] + "道";
                    }
                },
                visualMap: {
                    min: 0,
                    max: 100,
                    type: 'piecewise',
                    orient: 'horizontal',
                    right: 70,
                    top: 5,
                    target: {
                        inRange: {
                            color: ['#ccffcc', '#80ff80', '#00e600', '#009900', '#006600']
                        },
                        outOfRange: {
                            color: '#006600'
                        }
                    },
                    controller: {
                        inRange: {
                            color: ['#ccffcc', '#80ff80', '#00e600', '#009900', '#006600']
                        },
                        outOfRange: {
                            color: '#006600'
                        },
                    },
                },
                calendar: {
                    top: 60,
                    left: 30,
                    right: 30,
                    cellSize: [15, 15],
                    range: '2023',
                    splitLine: {
                        show: false
                    },
                    dayLabel: {
                        show: false
                    }
                },
                series: {
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    data: puzzle_year_count
                }
            };
            echats_init();
        }

        const get_records_year_count = () => {
            axios.get('/record/get/year', {
                params: {
                    userId: props.userId,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    const data = resp.data;
                    win.value = data.win;
                    lose.value = data.lose;
                    totalEasy.value = data.totalEasy;
                    totalMiddle.value = data.totalMiddle;
                    totalHard.value = data.totalHard;
                    solvedEasy.value = data.solvedEasy;
                    solvedMiddle.value = data.solvedMiddle;
                    solvedHard.value = data.solvedHard;
                    puzzlePoint.value = data.puzzlePoint;
                    records_year_count = data.playRecords;
                    puzzle_year_count = data.puzzleRecords;
                    playCount.value = data.playCount;
                    puzzleCount.value = data.puzzleCount;
                    finished_rate.value = (parseInt(solvedEasy.value) + parseInt(solvedMiddle.value) + parseInt(solvedHard.value)) * 100 / (parseInt(totalEasy.value) + parseInt(totalMiddle.value) + parseInt(totalHard.value));
                    init_options();
                }
            })
        }

        return {
            totalEasy,
            totalMiddle,
            totalHard,
            solvedEasy,
            solvedMiddle,
            solvedHard,
            finished_rate,
            puzzlePoint,
            year_record_heatmap,
            total_record_pie,
            playCount,
            puzzleCount,
            year_puzzle_heatmap,
        }
    }
}
</script>

<style scoped>
.heatmap-chart {
    width: 920px;
    height: 200px;
}

.record-chart {
    width: 470px;
    height: 250px;
}

.box-card-flex {
    flex: 1;
    max-width: 45%;
}

.card-name {
    color: gray;
    font-size: medium;
}

::v-deep .progress-success .el-progress-bar__outer {
    background-color: rgb(229, 255, 229);
}

::v-deep .progress-warning .el-progress-bar__outer {
    background-color: rgb(255, 238, 204);
}

::v-deep .progress-danger .el-progress-bar__outer {
    background-color: rgb(255, 128, 128, 0.3);
}

.finished-count {
    color: black;
    font-size: medium;
    font-weight: 500;
}
</style>