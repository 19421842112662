<template>
    <div class="d-flex justify-content-start">
        <ContentBase class="goboard" v-show="loading">
            <el-skeleton :rows="6" :loading="loading" />
        </ContentBase>

        <div ref="parent" class="goboard" v-show="!loading">
            <canvas ref="canvas" tabindex="0"></canvas>
        </div>
        <div class="play-info-base">
            <div class="container">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-around">
                            <span class="black_highlight">房间号：{{ roomId }}</span>
                            <button plain type="button" class="btn btn-danger btn-leave" @click="leave_room">离开房间</button>

                        </div>
                        <RoomUserList :roomId="roomId" />
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import RoomUserList from '@/components/room/RoomUserList.vue';
import ContentBase from '@/components/base/ContentBase.vue';
import { PracticeBoard } from '@/assets/scripts/board/PracticeBoard';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import { ElMessageBox } from 'element-plus';
import { useStore } from 'vuex';
import router from '@/router';
import { onMounted, ref } from "vue";
import { users_in_room } from '@/components/room/RoomUserList.vue';
import axios from 'axios';

export let room_dissolve = ElMessageBox;
export default {
    components: {
        ContentBase,
        RoomUserList,
    },

    setup() {
        const store = useStore();
        const route = useRouter();
        const roomId = route.currentRoute.value.params.roomId;
        let parent = ref(null);
        let canvas = ref(null);
        let loading = ref(true);
        let b;

        let is_master;
        onMounted(() => {
            axios.post('/room/getInfo', {
                roomId: roomId,
            }).then(resp => {
                if (resp.code === 400) {
                    router.push({
                        name: '404',
                    })
                } else if (resp.code == 200) {
                    is_master = resp.data.info.master_user_id == store.state.user.id
                    users_in_room.data = resp.data.users;
                    store.commit("updateBoard", resp.data.info.board_state);
                    store.commit("updateLastStep", {
                        last_x: resp.data.info.last_x,
                        last_y: resp.data.info.last_y
                    });
                    b = new PracticeBoard(canvas.value.getContext('2d'), parent.value, resp.data.info.size, store, roomId, is_master);
                    loading.value = false;
                }
            })
        })

        const leave_room = () => {
            router.push({
                name: 'playhall',
            })
        }

        onBeforeRouteLeave((to, from, next) => {
            if (is_master) {
                ElMessageBox.confirm("是否解散房间？", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    center: true,
                    type: "warning"
                }).then(() => {
                    axios.get('/room/leave', {
                        params: {
                            room_id: roomId,
                        }
                    }).then(resp => {
                        if (resp.code == 200) {
                            b.destory();
                            next();
                            store.commit("updateBoard", null);
                            store.commit("updateLastStep", {
                                last_x: -1,
                                last_y: -1,
                            })
                        }
                    })
                });
            } else {
                axios.get('/room/leave', {
                    params: {
                        room_id: roomId,
                    }
                }).then(resp => {
                    if (resp.code == 200) {
                        b.destory();
                        next();
                        store.commit("updateBoard", null);
                        store.commit("updateLastStep", {
                            last_x: -1,
                            last_y: -1,
                        })
                    }
                })
            }
        })

        return {
            parent,
            canvas,
            loading,
            roomId,
            leave_room,
        }
    }
}
</script>

<style scoped>
.goboard {
    width: 50vw;
    height: 88vh;
    margin-top: 10vh;
}

canvas {
    margin-left: 8vw;
    display: inline-block;
    background-image: url('https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/board.90ff7d60.png');
    background-size: 70%;
    cursor: pointer;
    box-shadow: 3px 3px 3px #b9b9b9;
}

.play-info-base {
    width: 40vw;
    margin-left: 8vw;
    margin-top: 10vh;
}

.card {
    background-color: white;
}

.black_highlight {
    color: rgb(85, 102, 110);
    font-size: larger;
    font-weight: bolder;
    text-shadow: 0 5px 5px #6a80ab;
    /*设置文字阴影*/
}

.btn-leave {
    background-color: rgb(218, 88, 92);
    border-color: rgb(145, 66, 108);
}

.btn-leave:hover {
    background-color: rgb(203, 51, 51);
}
</style>