<template>
    <ContentBase>
        <el-row class="align-items-center">
            <el-col :span="6"></el-col>
            <el-col :span="12">
                <div class="title">
                    <span>天梯闯关 - 第{{ group_code }}关</span>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="d-flex flex-row-reverse align-items-center">
                    <el-button plain @click="back_to_ladder_level">返回
                        <el-icon class="el-icon--right">
                            <Back />
                        </el-icon>
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <hr />

        <!-- 所有题目 -->
        <div class="card-container">
            <el-card :class="puzzle.pass ? 'grid-item-pass' : 'grid-item-npass'" v-for="(puzzle) in puzzles"
                :key="puzzle.id" @click="push_to_puzzle(puzzle.id)">
                <div class="image-container">
                    <img :src="`https://fcjznkj.oss-cn-qingdao.aliyuncs.com/puzzles/images/${puzzle.id}.png`"
                        class="large-image" alt="全力补充中.."/>
                    <img src="@/assets/images/solved.png" class="small-image" alt="您已通过" title="你已通过这道题目"
                        v-show="puzzle.pass" />
                </div>
                <div class="grid-text">
                    <div class="text-line text-align-items">
                        <el-text :underline="false" class="puzzle-title mx-1"> 第{{ puzzle.position }}题</el-text>
                    </div>
                </div>
            </el-card>
        </div>

    </ContentBase>
</template>

<script>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import ContentBase from '@/components/base/ContentBase.vue';
import { Back } from '@element-plus/icons-vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';

export default {
    components: {
        ContentBase,
        Back,
    },

    setup() {
        const route = useRoute();
        const router = useRouter();
        const group_code = route.params.group_code;
        let puzzles = ref([])

        onMounted(() => {
            axios.get('/puzzle/ladder/level', {
                params: {
                    ladderId: group_code,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    puzzles.value = resp.data;
                }
            })
        })

        const back_to_ladder_level = () => {
            router.push({
                name: 'puzzle_ladder_level_practice',
                params: {
                    ladder_code: parseInt((group_code - 1) / 100 + 1)
                }
            });
        }

        const push_to_puzzle = (puzzle_id) => {
            router.push({
                name: "puzzle_details",
                params: {
                    puzzleId: puzzle_id,
                }
            })
        }

        return {
            group_code,
            puzzles,
            back_to_ladder_level,
            push_to_puzzle,
        }
    }
}
</script>

<style scoped>
.title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

.grid-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 10px;
    /* 间距 */
}

.image-container {
    position: relative;
    display: inline-block;
}

.grid-item-pass {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dddddd;
    cursor: pointer;
    width: 190px;
    height: 190px;
    margin: 15px;
    background-color: rgb(221, 238, 224);
}

.grid-item-npass {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dddddd;
    cursor: pointer;
    width: 190px;
    height: 190px;
    margin: 15px;
    background-color: rgb(235, 241, 243);
}

.large-image {
    width: 140px;
    /* 调整图片大小 */
    height: auto;
}

.small-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    /* 调整小图片的大小 */
    height: auto;
    z-index: 1;
    /* 确保小图片在大图片之上 */
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* 让卡片在容器中居中显示 */
    align-items: flex-start;
    margin: 0 -10px;
    /* 调整卡片之间的间距 */
}

/* 添加媒体查询以根据屏幕宽度调整卡片显示数量 */
@media screen and (max-width: 1200px) {
    .custom-card {
        flex: 1 0 calc(33% - 20px);
        /* 在较小屏幕上显示 3 个卡片 */
    }
}

@media screen and (max-width: 768px) {
    .custom-card {
        flex: 1 0 calc(50% - 20px);
        /* 在小屏幕上显示 2 个卡片 */
    }
}

@media screen and (max-width: 480px) {
    .custom-card {
        flex: 1 0 100%;
        /* 在更小屏幕上卡片占满一行 */
    }
}
</style>