<template>
    <ContentBase>
        <div class="d-flex justify-content-evenly" style="margin-top: 5vh; margin-bottom: 5vh;">
            <el-card class="custom-card" @click="click_puzzle_database">
                <div class="card-content">
                    <div class="left-content">
                        <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/puzzle_bank.png" class="image" />
                    </div>
                    <div class="right-content">
                        <p class="text">死活题库</p>
                    </div>
                </div>
            </el-card>
            <el-card class="custom-card" @click="click_puzzle_grade_practice">
                <div class="card-content">
                    <div class="left-content">
                        <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/puzzle_grade_practice.png"
                            class="image" />
                    </div>
                    <div class="right-content">
                        <p class="text">难度练习</p>
                    </div>
                </div>
            </el-card>
        </div>
        <div class="d-flex justify-content-evenly" style="margin-top: 5vh; margin-bottom: 5vh;">
            <el-card class="custom-card" @click="click_puzzle_type_practice">
                <div class="card-content">
                    <div class="left-content">
                        <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/puzzle_type_practice.png" class="image" />
                    </div>
                    <div class="right-content">
                        <p class="text">题型练习</p>
                    </div>
                </div>
            </el-card>
            <el-card class="custom-card" @click="click_puzzle_ladder_practice">
                <div class="card-content">
                    <div class="left-content">
                        <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/icon_puzzle_ladder.png" class="image" />
                    </div>
                    <div class="right-content">
                        <p class="text">天梯闯关</p>
                    </div>
                </div>
            </el-card>
        </div>
    </ContentBase>
    <el-backtop :right="30" :bottom="30" />
</template>

<script>
import ContentBase from '@/components/base/ContentBase.vue';
import router from '@/router';

export default {
    components: {
        ContentBase,
    },

    setup() {
        const click_puzzle_database = () => {
            router.push({
                name: 'puzzle_bank'
            })
        }

        const click_puzzle_grade_practice = () => {
            router.push({
                name: 'puzzle_grade_practice'
            })
        }

        const click_puzzle_type_practice = () => {
            router.push({
                name: 'puzzle_type_practice'
            })
        }

        const click_puzzle_ladder_practice = () => {
            router.push({
                name: 'puzzle_ladder_practice'
            })
        }

        return {
            click_puzzle_database,
            click_puzzle_grade_practice,
            click_puzzle_type_practice,
            click_puzzle_ladder_practice
        }
    },
}
</script>

<style scoped>
.custom-card:hover {
    transform: scale(1.2);
}

.custom-card {
    width: 265px;
    /* Set width as 50% of the parent container */
    height: 125px;
    /* Set height as 50% of the parent container */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeecec;
    cursor: pointer;
    /* 设置背景颜色为灰色，你可以使用任何颜色 */
}

.card-content {
    display: flex;
    width: 100%;
    height: 100%;
}

.left-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    height: auto;
}

.text {
    text-align: center;
    margin: 0;
    padding: 10px;
    font-size: 1.3vw;
    font-weight: 500;
    white-space: nowrap;
}
</style>