<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-4 rules">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect" v-model="selected_size">
                            <option selected :value=19>19✖️19</option>
                            <option :value=13>13✖️13</option>
                        </select>
                        <label for="floatingSelect">棋盘大小</label>
                    </div>
                </div>
                <div class="col-sm-4 rules">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect">
                            <option selected>黑贴3又3/4子</option>
                        </select>
                        <label for="floatingSelect">贴目</label>
                    </div>
                </div>
                <div class="col-sm-3 rules">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect" v-model="selected_handicap"
                            v-if="selected_size == 19">
                            <option selected :value=0>分先</option>
                            <option :value=2>让2子</option>
                            <option :value=3>让3子</option>
                            <option :value=4>让4子</option>
                            <option :value=5>让5子</option>
                            <option :value=6>让6子</option>
                            <option :value=7>让7子</option>
                            <option :value=8>让8子</option>
                            <option :value=9>让9子</option>
                        </select>
                        <select class="form-select" id="floatingSelect" v-model="selected_handicap"
                            v-if="selected_size == 13">
                            <option selected :value=0>分先</option>
                            <option :value=2>让2子</option>
                            <option :value=3>让3子</option>
                            <option :value=4>让4子</option>
                            <option :value=5>让5子</option>
                        </select>
                        <label for="floatingSelect">棋份</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-4 rules">
                    <div class="form-floating">
                        <select class="form-select" v-model="selected_side" id="floatingSelect">
                            <option :value=1>执黑</option>
                            <option :value=2 :disabled="selected_handicap != 0">执白</option>
                        </select>
                        <label for="floatingSelect">棋手</label>
                    </div>
                </div>
                <div class="col-sm-4 rules">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect">
                            <option selected>30分钟</option>
                        </select>
                        <label for="floatingSelect">时间</label>
                    </div>
                </div>
                <div class="col-sm-3 rules">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect" v-model="selected_level">
                            <option value="b">1级</option>
                            <option value="c">2级</option>
                            <option value="d">3级</option>
                            <option value="e">4级</option>
                            <option value="f">5级</option>
                            <option value="g">6级</option>
                            <option value="h">7级</option>
                            <option value="i">8级</option>
                            <option value="I">9级</option>
                            <option value="j">10级</option>
                            <option value="J">11级</option>
                            <option value="k">12级</option>
                            <option value="l">13级</option>
                            <option value="L">14级</option>
                            <option value="m">15级</option>
                            <option value="M">16级</option>
                            <option value="n">17级</option>
                            <option value="o">18级</option>
                            <option value="p">19级</option>
                            <option value="q" selected>20级</option>
                        </select>
                        <label for="floatingSelect">难度</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <el-button type="success" round class="match" @click="request_engine">开始挑战</el-button>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, watch } from 'vue';
import { ElMessage } from 'element-plus';

export default {
    emits: ['update_handicap', 'update_size'],

    setup(props, context) {
        const store = useStore();

        let selected_side = ref('1');
        let selected_size = ref(19);
        let selected_level = ref('q');
        let selected_handicap = ref('0');

        const request_engine = () => {
            let socket = store.state.gogame.socket;
            if (socket != null && socket.readyState === 1) {
                store.state.gogame.socket.send(JSON.stringify({
                    event: "engine_play",
                    side: selected_side.value,
                    size: selected_size.value,
                    level: selected_level.value,
                    handicap: selected_handicap.value,
                }));
            } else {
                ElMessage.warning("请先结束对局或检查网络连接");
            }
        }

        watch(() => selected_handicap.value, () => {
            context.emit("update_handicap", selected_handicap.value);
            if (selected_handicap.value != '0') {
                selected_side.value = '1';
            }
        }, { immediate: true });

        watch(() => selected_size.value, () => {
            context.emit("update_size", selected_size.value);
        }, { immediate: true });

        return {
            selected_side,
            selected_level,
            selected_handicap,
            selected_size,
            request_engine,
        }
    },
}
</script>

<style scoped>
.rules {
    margin-left: 1vw;
    margin-top: 25px;
}

.match {
    width: 50%;
    height: 40px;
    display: block;
    margin: 30px auto 5px auto;
}
</style>