<template>
    <ContentBase>
        <div class="title">
            <h2>对局大厅</h2>
            <div class="d-flex justify-content-end" v-if="$store.state.user.is_login">
                <button type="button" class="btn btn-primary btn-function" data-bs-toggle="modal"
                    data-bs-target="#create-room" style="margin-right: 1vw;">
                    创建房间
                    <img src="@/assets/images/upload.png" class="icon-upload" title="创建房间">
                </button>
                <button type="button" class="btn btn-primary btn-function" data-bs-toggle="modal"
                    data-bs-target="#enter-room">
                    加入房间
                    <img src="@/assets/images/room.png" class="icon-upload" title="加入房间">
                </button>
            </div>
            <hr />
        </div>
        <el-empty description=" " v-if="show_game_blank.show == true">
            <button type="button" class="btn btn-success btn-op-room" @click="play">
                去下棋！
            </button>
        </el-empty>
        <el-table :data="games.data" style="width: 100%; text-align: center;" align="center" stripe max-height="800" v-else>
            <el-table-column label="类型" align="center">
                <template v-slot="{ row }">
                    <el-tag type="info" round effect="plain" class="mx-1">
                        <span v-if="row.type == 1">
                            围棋
                        </span>
                        <span v-if="row.type == 2">
                            五子棋
                        </span>
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="黑方" align="center">
                <template v-slot="{ row }">
                    <img :src="row.black_avatar" alt="" class="user-avatar">
                    &nbsp;
                    <span> {{ row.black_username }}</span>
                </template>
            </el-table-column>
            <el-table-column label="棋力" align="center">
                <template v-slot="{ row }">
                    <el-tag :color="LEVEL[row.black_level].color">
                        <span style="color: white;">
                            {{ row.black_level }}
                        </span>
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="白方" align="center">
                <template v-slot="{ row }">
                    <img :src="row.white_avatar" alt="" class="user-avatar">
                    &nbsp;
                    <span> {{ row.white_username }}</span>
                </template>
            </el-table-column>
            <el-table-column label="棋力" align="center" width="80">
                <template v-slot="{ row }">
                    <el-tag :color="LEVEL[row.white_level].color">
                        <span style="color: white;">
                            {{ row.white_level }}
                        </span>
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="人数" align="center">
                <template v-slot="{ row }">
                    <span>
                        {{ row.users_count }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
                <template v-slot="{ row }">
                    <el-tag type="info" v-if="row.state === '布局'">
                        {{ row.state }}
                    </el-tag>
                    <el-tag type="warning" plain v-else-if="row.state === '中盘'"> {{ row.state }}
                    </el-tag>
                    <el-tag type="danger" plain v-else-if="row.state === '官子'"> {{ row.state }}
                    </el-tag>
                    <el-tag plain v-bind:color="row.state.charAt(0) === '黑' ? '#1a1a1a' : '#ffffff'" v-else>
                        {{ row.state }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button size="small" type="primary" plain @click="watch_game(row.id)"> 观战</el-button>
                </template>
            </el-table-column>
        </el-table>
    </ContentBase>

    <!-- 创建房间模态框 -->
    <div class="modal fade" id="create-room" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header modal-center-component">
                    <h1 class="modal-title fs-5">创建房间</h1>
                </div>
                <div class="modal-body">
                    <div class="modal-center-component">
                        <el-select v-model="room_config.room_size" placeholder="请选择棋盘大小">
                            <el-option label="19*19" :value=19></el-option>
                            <el-option label="13*13" :value=13></el-option>
                        </el-select>
                    </div>

                </div>
                <div class="modal-footer modal-center-component">
                    <button type="button" class="btn btn-success btn-op-room" @click="create_room">
                        创建
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- 加入房间模态框 -->
    <div class="modal fade" id="enter-room" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header modal-center-component">
                    <h1 class="modal-title fs-5">加入房间</h1>
                </div>
                <div class="modal-body">
                    <div class="modal-center-component">
                        <div>
                            <el-input v-model="enter_room_id" placeholder="请输入房间号"></el-input>
                        </div>
                    </div>
                </div>
                <div class="modal-footer modal-center-component">
                    <button type="button" class="btn btn-success btn-op-room" @click="enter_room">
                        加入
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import ContentBase from '@/components/base/ContentBase.vue';
import { onMounted, ref, reactive } from "vue";
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import { Modal } from 'bootstrap/dist/js/bootstrap';

export let games = reactive({
    data: [],
});
export let show_game_blank = reactive({
    show: true,
})
export default {
    components: {
        ContentBase,
    },

    setup() {
        const store = useStore();
        const route = useRouter();

        let enter_room_id = ref('');
        let room_config = reactive({
            room_size: 19,
        });
        const LEVEL = window.LEVEL;

        onMounted(() => {
            axios.get('/game/getInProcess').then(resp => {
                if (resp.code == 200) {
                    games.data = resp.data;
                    show_game_blank.show = games.data.length == 0;
                }
            })
        })

        const watch_game = (roomId) => {
            store.commit("updateBoard", null);
            route.push({
                name: 'playroom',
                params: {
                    roomId,
                }
            })
        }

        const create_room = () => {
            axios.post('/room/create', {
                size: room_config.room_size,
            }).then(resp => {
                if (resp.code == 200) {
                    Modal.getInstance("#create-room").hide();
                    route.push({
                        name: 'practice_room',
                        params: {
                            roomId: resp.data,
                        }
                    })
                } else {
                    ElMessage.error(resp.data);
                }
            })
        }

        const enter_room = () => {
            axios.get('/room/enter', {
                params: {
                    room_id: enter_room_id.value,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    Modal.getInstance("#enter-room").hide();
                    route.push({
                        name: 'practice_room',
                        params: {
                            roomId: enter_room_id.value,
                        }
                    })
                } else {
                    ElMessage.error(resp.data);
                }
            })
        }

        const play = () => {
            route.replace({ name: 'goplay' });
        }

        return {
            games,
            show_game_blank,
            LEVEL,
            room_config,
            enter_room_id,
            watch_game,
            play,
            create_room,
            enter_room,
        }
    },
}
</script>

<style scoped>
.title {
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
}

.user-avatar {
    border-radius: 50%;
    width: 4vh;
    height: 4vh;
}

.icon-upload {
    width: 2.5vh;
    height: 2.5vh;
}

.btn-function {
    background-color: rgb(140, 181, 235);
    border-color: aliceblue;
}


.btn-function:hover {
    background-color: rgb(65, 113, 143);
}

.modal-center-component {
    display: flex;
    justify-content: center;
}

.btn-op-room {
    background-color: rgb(82, 178, 130);
    border-color: aliceblue;
}

.btn-op-room:hover {
    background-color: rgb(52, 139, 97);
}
</style>