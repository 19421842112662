<template>
    <div class="col-12">
        <el-input type="textarea" :rows="2" v-model="comment_input" placeholder="在这里写评论...">
        </el-input>
    </div>
    <div class="row">
        <div class="d-flex flex-row-reverse send-comment">
            <el-button size="small" type="info" id="send" plain @click="comment_a_comment" :disabled="disable_comment">提交评论</el-button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';

export default {
    props: {
        comment_id: {
            type: Number,
            required: true,
        },
        post_id: {
            type: Number,
            required: true,
        },
        post_user_id: {
            type: Number,
            required: true,
        }
    },
    emits: ['get_post_comments', 'fold_comment_area', 'incre_comment_count'],

    setup(props, context) {
        let comment_input = ref('');
        let disable_comment = ref(false);

        const comment_a_comment = () => {
            if (comment_input.value.trim() == '') return;
            disable_comment.value = true;
            axios.post('/post/comment/', {
                postUserId: props.post_user_id,
                postId: props.post_id,
                parentCommentId: props.comment_id,
                content: comment_input.value,
            }).then(resp => {
                if (resp.code == 200) {
                    comment_input.value = '';
                    context.emit("get_post_comments");
                    context.emit("fold_comment_area");
                    context.emit("incre_comment_count");
                } else {
                    ElMessage.warning(resp.data);
                }
                disable_comment.value = false;
            })
        }

        return {
            comment_input,
            disable_comment,
            comment_a_comment,
        }
    }
}
</script>

<style scoped>
.send-comment {
    margin: 1vh -2vh auto auto;
}
</style>