import { GameObject } from "../GameObject";
import { Board } from "./Board";
import store from "@/store";

export class DynamicSizeBoard extends GameObject {
    constructor(ctx, parent, puzzle_id, rows, cols, initial_stones, move_tree, is_black, puzzle_level, ElMessage, fun_next_puzzle, fun_reset) {
        super();
        this.ctx = ctx;
        this.parent = parent;
        this.puzzle_id = puzzle_id;
        this.rows = rows;
        this.cols = cols;
        this.initial_stones = initial_stones;
        this.initial_size = this.initial_stones.length;
        this.move_tree = move_tree;
        this.black_move_list = new Array();
        this.white_move_list = new Array();
        this.answer_list = new Array();
        this.removed_branch = new Set();
        this.L = 0;
        this.cell_len = 0;
        this.is_black = is_black;
        this.puzzle_level = puzzle_level;
        this.fun_next_puzzle = fun_next_puzzle;
        this.fun_reset = fun_reset;
        this.virtual_x = -1;
        this.virtual_y = -1;
        this.last_x = -1;
        this.last_y = -1;
        this.board = new Board(cols, initial_stones, is_black, 0);
        this.idx = 0;
        this.ElMessage = ElMessage;
        this.done = false;
        // 棋子样式 落子声音文件
        this.black_image = new Image();
        this.white_image = new Image();
        this.audio_play = new Audio();
        this.audio_play.src = require('@/assets/audios/play.wav');
        this.black_image.src = require('@/assets/images/shell_stb1.png');
        this.white_image.src = require('@/assets/images/shell_stw9.png');
    }

    start() {
        let split = this.move_tree.split(',');
        for (let i = 0; i < split.length; i++) {
            this.answer_list.push(split[i]);
        }
        this.idx = 0;
    }

    init_stars() {
        for (let i = Math.min(4, this.rows); i <= Math.min(16, this.rows); i += 6) {
            for (let j = Math.min(4, this.cols); j <= Math.min(16, this.cols); j += 6) {
                this.draw_stars(i, j);
            }
        }
    }

    add_mouse_events() {
        this.ctx.canvas.addEventListener("mousemove", this.handle_mousemove);
        this.ctx.canvas.addEventListener("click", this.handle_mouseclick);
    }

    handle_mousemove = (e) => {
        let click_x = e.offsetX;
        let click_y = e.offsetY;
        if (click_x > this.parent.clientWidth || click_y > this.parent.clientHeight) {
            this.virtual_x = -1;
            this.virtual_y = -1;
            return;
        }
        let x = Math.round(click_y / this.cell_len);
        let y = Math.round(click_x / this.cell_len);
        this.virtual_x = -1;
        this.virtual_y = -1;
        if (x <= 0 || x > this.rows || y <= 0 || y > this.cols || this.board.board[x][y]) {
            return;
        }
        this.virtual_x = y;
        this.virtual_y = x;
    }

    handle_mouseclick = (e) => {
        let click_x = e.offsetX;
        let click_y = e.offsetY;
        if (click_x > this.parent.clientWidth || click_y > this.parent.clientHeight) {
            return;
        }
        let x = Math.round(click_y / this.cell_len);
        let y = Math.round(click_x / this.cell_len);
        this.virtual_x = -1;
        this.virtual_y = -1;
        if (x <= 0 || x > this.rows || y <= 0 || y > this.cols || this.board.board[x][y]) {
            return;
        }
        if (this.board.play(x, y)) {
            this.audio_play.play();
            this.last_x = x;
            this.last_y = y;
            let answer_aside_x, answer_aside_y;
            let branch = -1;
            for (let i = 0; i < this.answer_list.length; i++) {
                if (this.idx + 1 >= this.answer_list[i].length) continue;
                if (this.removed_branch.has(i)) continue;
                if ((x == this.answer_list[i][this.idx + 1].toUpperCase().charCodeAt() - 64) && (y == this.answer_list[i][this.idx].toUpperCase().charCodeAt() - 64)) {
                    branch = i;
                } else {
                    this.removed_branch.add(i);
                }
            }
            // 错误答案
            if (branch == -1) {
                this.ElMessage.error({
                    message: "你答错了！",
                    type: 'error',
                    center: true,
                    duration: 1000
                })
                this.done = true;
                store.dispatch("attemptPuzzle", {
                    puzzle_id: this.puzzle_id,
                });
                setTimeout(() => { this.fun_reset() }, 2000);
            } else {
                this.idx += 2;
                // 在某个正确答案分支上
                if (this.idx + 1 >= this.answer_list[branch].length) {
                    // 走完最后一步
                    this.done = true;
                    store.dispatch("solvePuzzle", {
                        puzzle_id: this.puzzle_id,
                        puzzle_level: this.puzzle_level,
                        success: (message) => {
                            this.ElMessage.success({
                                message: message,
                                type: 'success',
                                center: true,
                                duration: 500,
                            });
                            setTimeout(() => { this.fun_next_puzzle() }, 1000);
                        }
                    });
                } else {
                    answer_aside_x = this.answer_list[branch][[this.idx + 1]].toUpperCase().charCodeAt() - 64;
                    answer_aside_y = this.answer_list[branch][[this.idx]].toUpperCase().charCodeAt() - 64;
                    this.board.play(answer_aside_x, answer_aside_y);
                    this.last_x = answer_aside_x;
                    this.last_y = answer_aside_y;
                    this.idx += 2;
                    if (this.idx + 1 >= this.answer_list[branch].length) {
                        // 走完最后一步
                    this.done = true;
                    store.dispatch("solvePuzzle", {
                        puzzle_id: this.puzzle_id,
                        puzzle_level: this.puzzle_level,
                        success: (message) => {
                            this.ElMessage.success({
                                message: message,
                                type: 'success',
                                center: true,
                                duration: 500,
                            });
                            setTimeout(() => { this.fun_next_puzzle() }, 1000);
                        }
                    });
                    }
                }
            }
        }
        this.remove_mouse_events();
    }

    sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

    remove_mouse_events() {
        this.ctx.canvas.removeEventListener("mousemove", this.handle_mousemove);
        this.ctx.canvas.removeEventListener("click", this.handle_mouseclick);
    }

    draw_virtual_stone(x, y, side) {
        const center_x = x * this.cell_len;
        const center_y = y * this.cell_len;
        const r = this.cell_len / 3;
        if (side == 1) this.ctx.fillStyle = "black";
        else this.ctx.fillStyle = 'white';
        this.ctx.fillRect(center_x - this.cell_len / 6, center_y - this.cell_len / 6, r, r);
    }

    draw_lines() {
        this.ctx.lineWidth = 0.5;
        // 根据指定的长度画多少的行
        for (let i = 1; i <= this.rows; i++) {
            this.ctx.moveTo(this.cell_len, this.cell_len * i);
            this.ctx.lineTo(this.cell_len * this.cols, this.cell_len * i);
            this.ctx.stroke();
        }
        // 根据指定的长度画多少的列
        for (let i = 1; i <= this.cols; i++) {
            this.ctx.moveTo(this.cell_len * i, this.cell_len);
            this.ctx.lineTo(this.cell_len * i, this.cell_len * this.rows);
            this.ctx.stroke();
        }
    }

    draw_indexes() {
        let alp;
        for (let i = 1; i <= this.rows; i++) {
            this.ctx.fillStyle = "black";
            this.ctx.font = "12px Arial";
            alp = String.fromCharCode(64 + i);
            this.ctx.fillText(alp, this.cell_len * 0.2, this.cell_len * i + 5);
            this.ctx.fillText(alp, this.cell_len * (this.rows + 0.7), this.cell_len * i + 5);
        }
        for (let i = 1; i <= this.cols; i++) {
            this.ctx.fillStyle = "black";
            alp = String.fromCharCode(64 + i);
            this.ctx.fillText(alp, this.cell_len * i, this.cell_len * 0.4);
            this.ctx.fillText(alp, this.cell_len * i - 2, this.cell_len * (this.rows + 0.8));
        }
    }

    draw_stars(x, y) {
        this.ctx.fillStyle = "#000000";
        this.ctx.beginPath();
        this.ctx.arc(x * this.cell_len, y * this.cell_len, this.cell_len / 14, 0, Math.PI * 2);
        this.ctx.fill();
    }

    // 每秒根据浏览器更新大小
    update_size() {
        // 因为区域是不断变化的，所以要在每一帧里动态地求一个区域中可以包含的最大的矩形的一个格子的长和宽
        this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));
        this.ctx.canvas.width = this.L * this.cols
        this.ctx.canvas.height = this.L * this.rows;
        this.cell_len = parseInt(this.ctx.canvas.height / (this.rows + 1));
    }

    draw_stones(x, y, color) {
        const center_x = x * this.cell_len;
        const center_y = y * this.cell_len;
        const r = this.cell_len / 2;
        if (color === 1) {
            this.ctx.beginPath();
            this.ctx.arc(center_x, center_y, r, 0, Math.PI * 2);
            this.ctx.closePath();
            this.ctx.drawImage(this.black_image, center_x - r, center_y - r, r * 2, r * 2);
        } else if (color === 2) {
            this.ctx.beginPath();
            this.ctx.arc(center_x, center_y, r, 0, Math.PI * 2);
            this.ctx.closePath();
            this.ctx.drawImage(this.white_image, center_x - r, center_y - r, r * 2, r * 2);
        }
    }

    draw_red_point(x, y) {
        const center_x = x * this.cell_len;
        const center_y = y * this.cell_len;
        const r = this.cell_len / 8;
        this.ctx.fillStyle = 'red';
        this.ctx.strokeStyle = 'red';
        this.ctx.beginPath();
        this.ctx.arc(center_x, center_y, r, 0, Math.PI * 2);
        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.fillStyle = null;
    }

    on_destory() { }

    update() {
        if (!this.done) this.add_mouse_events();
        this.update_size();
        this.draw_lines();
        this.init_stars();
        this.draw_indexes();
        this.render();
    }

    render() {
        let b = this.board.board;
        for (let x = 1; x <= this.rows; x++) {
            for (let y = 1; y <= this.cols; y++) {
                if (b[x][y]) {
                    this.draw_stones(y, x, b[x][y]);
                }
            }
        }
        if (this.virtual_x != -1 && this.virtual_y != -1) {
            if (this.is_black) this.draw_virtual_stone(this.virtual_x, this.virtual_y, 1);
            else this.draw_virtual_stone(this.virtual_x, this.virtual_y, 2);
        }
        if (this.last_x != -1 && this.last_y != -1) this.draw_red_point(this.last_y, this.last_x);
    }
}