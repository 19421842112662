<template>
    <el-skeleton :loading="loading" :rows="6"/>
    <SmallContentBase v-show="!post_exist">
        <el-empty description="帖子不存在或已被删除"></el-empty>
    </SmallContentBase>
    <SmallContentBase v-if="post_exist">
        <div class="row">
            <div class="d-flex flex-row">
                <div class="p-2">
                    <span class="post-title">{{ post.title }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="d-flex flex-row align-items-center">
                <div class="p-2 post-info align-items-center">
                    <span>作者：</span>
                    <img :src="post.userAvatar" class="post-user-avatar"> &nbsp;
                    <span>{{ post.username }}</span>
                </div>
                <div class="p-2 post-info align-items-center">
                    <span>发布时间：{{ post.createTime }}</span>
                </div>
            </div>
        </div>
        <el-divider/>
        <div class="row">
            <div class="col-1">
                <div class="row">
                    <img src="@/assets/images/red_star.png" v-if="post.liked == false" @click="star_a_post(post)"
                        class="icon-like">&nbsp;
                    <img src="@/assets/images/gray_star.png" v-else @click="star_a_post(post)" class="icon-like">&nbsp;
                </div>
                <div class="row" style="margin-top: -15px;">
                    <span style="color: gray;">{{ post.stars }}</span>
                </div>
            </div>
            <div class="col-11">
                <div class="card">
                    <div class="card-body content" style="text-align: left;">
                        <v-md-preview :text="post.content"></v-md-preview>
                    </div>
                </div>
            </div>
        </div>
    </SmallContentBase>

    <SmallContentBase v-show="post_exist" style="margin-top: 2vh;">
        <CommentArea :post_id="postId" :post_user_id="post.userId"></CommentArea>
    </SmallContentBase>
</template>

<script>
import SmallContentBase from '@/components/base/SmallContentBase.vue';
import CommentArea from '@/components/comment/CommentArea.vue';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
    components: {
        SmallContentBase,
        CommentArea,
    },

    setup() {
        const router = useRouter();
        const postId = router.currentRoute.value.params.postId;

        let post = ref([]);
        let loading = ref(true);
        let post_exist = ref(true);

        onMounted(() => {
            get_post_details();
        });

        const get_post_details = () => {
            axios.get('/post/get/details', {
                params: {
                    post_id: postId,
                }
            }).then(resp => {
                loading.value = false;
                if (resp.code == 200) {
                    post.value = resp.data;
                } else if (resp.code == 404) {
                    post_exist.value = false;
                }
            })
        }

        const star_a_post = (post) => {
            axios.post('/post/star', {
                postId: post.id,
            }).then(resp => {
                if (resp.code == 200) {
                    if (!post.liked) {
                        post.liked = true;
                        post.stars++;
                    } else {
                        post.liked = false;
                        post.stars--;
                    }
                }
            })
        }

        return {
            post,
            postId,
            post_exist,
            loading,
            get_post_details,
            star_a_post,
        }
    }
}
</script>

<style scoped>
.post-title {
    text-align: left;
    font-size: xx-large;
    font-weight: 500;
    color: rgb(86, 116, 207);
}

.post-user-avatar {
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
}

.post-info {
    color: rgb(168, 165, 165);
    font-size: medium;
}

.content {
    background-color: rgb(219, 243, 249, 0.2);
}

.icon-like {
    cursor: pointer;
}
</style>