<template>
    <div v-for="(comment) in child_comments" :key="comment.id" class="comment-area align-items-center">
        <div class="comment-divider">
            <div class="col-1">
                <img :src="comment.userAvatar" class="comment-user-avatar" @click="nav_to_user_space(comment.userId)">
            </div>
            <div class="col-11">
                <div class="row">
                    <span class="d-flex comment-username align-items-center">
                        <span style="color: rgb(56, 110, 197);">{{ comment.username }} &nbsp;&nbsp;&nbsp;</span>
                        <span>{{ comment.presentCommentTime }} &nbsp;&nbsp;&nbsp;</span>
                        <span class="click-reply" @click="click_comment(comment)"
                            v-if="!show_comment_post_set.has(comment.id)">回复</span>
                        <span class="click-reply" @click="click_comment(comment)" v-else>收起</span>
                        <span class="click-reply"  @click="delete_comment(comment.id)" v-if="comment.userId === $store.state.user.id">&nbsp;&nbsp;&nbsp;删除</span>
                    </span>
                </div>
                <div class="row">
                    <span class="d-flex comment-content align-items-center">
                        {{ comment.content }}
                    </span>
                </div>
                <div class="row d-flex">
                    <ReplyCommentArea v-if="show_comment_post_set.has(comment.id)" :comment_id="comment_id"
                        :post_id="post_id" @get_post_comments="refresh" :post_user_id="post_user_id"
                        @fold_comment_area="fold_comment_area" @incre_comment_count="incre_comment_count_deep" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReplyCommentArea from './ReplyCommentArea.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { ref } from 'vue';
import axios from 'axios';

export default {
    props: {
        child_comments: {
            type: Object,
            required: true,
        },
        post_id: {
            type: Number,
            required: true,
        },
        comment_id: {
            type: Number,
            required: true,
        },
        post_user_id: {
            type: Number,
            required: true,
        }
    },
    components: {
        ReplyCommentArea,
    },
    emits: ['get_post_comments', 'incre_comment_count'],

    setup(props, context) {
        let show_comment_post_set = ref(new Set());
        let delete_disabled = ref(false);
        const click_comment = (comment) => {
            const click_comment_comment_id = comment.id;
            if (show_comment_post_set.value.has(click_comment_comment_id)) show_comment_post_set.value.delete(click_comment_comment_id);
            else show_comment_post_set.value.add(click_comment_comment_id);
        }

        const refresh = () => {
            context.emit("get_post_comments");
        }

        const incre_comment_count_deep = () => {
            context.emit("incre_comment_count");
        }

        const fold_comment_area = () => {
            show_comment_post_set.value.clear();
        }

        const delete_comment = (comment_id) => {
            if (delete_disabled.value) return;
            ElMessageBox.confirm("您确定删除这条评论吗", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true,
            }).then(() => {
                delete_disabled.value = true;
                axios.post('/post/comment/delete', {
                    commentId: comment_id,
                }).then(resp => {
                    if (resp.code == 200) {
                        ElMessage({
                            message: "删除成功",
                            duration: 1000,
                            type: "success"
                        });
                        refresh();
                    } else {
                        ElMessage({
                            message: resp.data,
                            duration: 1000,
                            type: "error"
                        });
                    }
                    delete_disabled.value = false;
                })
            });
        }

        return {
            show_comment_post_set,
            delete_disabled,
            click_comment,
            refresh,
            fold_comment_area,
            incre_comment_count_deep,
            delete_comment,
        }
    }
}
</script>

<style scoped>
.comment-area {
    margin: 0 auto;
}

.comment-divider {
    margin-top: 1vh;
}

.comment-user-avatar {
    float: left;
    border-radius: 50%;
    padding: 0px 0px 0px 0px;
    margin: 0 0 0 -1vw;
    width: 5vh;
    height: 5vh;
}

.comment-username {
    margin: 0 auto 0 0;
    color: rgba(179, 171, 171, 0.788)
}

.comment-content {
    margin: 0 auto 1vh 0;
}

.click-reply:hover {
    color: rgb(56, 110, 197);
    cursor: pointer;
}
</style>