LEVEL = {
    "1级": {
        level: 1,
        color: "#d1d1e1",
    },
    "2级": {
        level: 1,
        color: "#d1d1e1",
    },
    "3级": {
        level: 1,
        color: "#d1d1e1",
    },
    "4级": {
        level: 1,
        color: "#d1d1e1",
    },
    "5级": {
        level: 1,
        color: "#d1d1e1",
    },
    "6级": {
        level: 1,
        color: "#d1d1e1",
    },
    "7级": {
        level: 1,
        color: "#d1d1e1",
    },
    "8级": {
        level: 1,
        color: "#d1d1e1",
    },
    "9级": {
        level: 1,
        color: "#d1d1e1",
    },
    "10级": {
        level: 1,
        color: "#d1d1e1",
    },
    "11级": {
        level: 1,
        color: "#d1d1e1",
    },
    "12级": {
        level: 2,
        color: "#b2b2cc",
    },
    "13级": {
        level: 3,
        color: "#b3d1ff",
    },
    "14级": {
        level: 4,
        color: "#66a3ff",
    },
    "15级": {
        level: 5,
        color: "#ccb3ff",
    },
    "16级": {
        level: 6,
        color: "#a44dff",
    },
    "17级": {
        level: 7,
        color: "#cc6600",
    },
    "18级": {
        level: 8,
        color: "#ff794d",
    },
    "19级": {
        level: 9,
        color: "#e63900",
    },
    "20级": {
        level: 10,
        color: "#ffcc33",
    },
    "1段": {
        level: 1,
        color: "#d1d1e1",
    },
    "2段": {
        level: 2,
        color: "#b2b2cc",
    },
    "3段": {
        level: 3,
        color: "#b3d1ff",
    },
    "4段": {
        level: 4,
        color: "#66a3ff",
    },
    "5段": {
        level: 5,
        color: "#ccb3ff",
    },
    "6段": {
        level: 6,
        color: "#a44dff",
    },
    "7段": {
        level: 7,
        color: "#cc6600",
    },
    "8段": {
        level: 8,
        color: "#ff794d",
    },
    "9段": {
        level: 9,
        color: "#e63900",
    },
    "10段": {
        level: 10,
        color: "#ffcc33",
    }
}