<template>
    <MediumContentBase>
        <div class="title">
            <h2>充值服务</h2>
        </div>
        <el-divider />
        <el-card class="box-card" shadow="always">
            <template #header>
                <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <img :src="$store.state.user.avatar" class="user-avatar">&nbsp;
                            <span class="text">{{ $store.state.user.username }}</span>&nbsp;
                            <span class="text">您当前余额：￥ {{ my_account_currency }} CNY</span>
                        </div>
                        <div>
                            <router-link :to="{ name: 'user_payment_record' }" class="router-link-exact-active">
                                <el-button class="button" text>充值记录</el-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </template>
            <div class="row align-items-center">
                <div class="col-2">
                    <span class="text">充值金额</span>
                </div>
                <div class="col-10">
                    <el-radio-group v-model="amount" size="medium">
                        <el-radio-button :label="1">￥0.01 CNY</el-radio-button>
                        <el-radio-button :label="5">￥5 CNY</el-radio-button>
                        <el-radio-button :label="20">￥20 CNY</el-radio-button>
                        <el-radio-button :label="100">￥100 CNY</el-radio-button>
                        <el-radio-button :label="200">￥200 CNY</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <div class="row align-items-center mt-4">
                <div class="col-2">
                    <span class="text">支付方式</span>
                </div>
                <div class="col-10">
                    <el-radio-group v-model="pay_type" size="large">
                        <el-radio :label="1" border>
                            <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/alipay.c4da24dd.png" class="pay_img">
                        </el-radio>
                        <el-radio :label="2" disabled border>
                            <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/wechatpay.cf77ab2e.png"
                                class="pay_img">
                        </el-radio>
                        <el-radio :label="3" disabled border>
                            <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/paypal.3b9dad5f.png" class="pay_img">
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
        </el-card>
        <el-checkbox v-model="protocal_checked" class="mt-4">
            <span data-bs-toggle="modal" data-bs-target="#recharge_protocal">充值协议</span>
        </el-checkbox>
        <el-tooltip :disabled="protocal_checked" content="请先查阅并同意“充值协议”" placement="top" effect="dark" :manual=true
            :hide-after="1">
            <el-button :disabled="!protocal_checked" class="mt-3" type="primary" size="large"
                style="width: 20%;color: white;" @click="pay">立即支付</el-button>
        </el-tooltip>
        <div style="text-align: center;">
            <div style="display: flex; flex-direction: column; align-items: flex-start;">
                <span class="mt-3" style="font-weight: bold; font-size: small;">• 充值余额（CNY）可以用来购买会员服务、研究道具等</span>
                <span class="mt-1" style="font-weight: bold; font-size: small;">• 充值余额（CNY）不能兑换现金，不能转账交易</span>
                <span class="mt-1" style="font-weight: bold; font-size: small;">• 如有充值完成未到账的情况，请联系我们，我们会尽快为您解决</span>
            </div>
        </div>
    </MediumContentBase>

    <!-- Modal -->
    <div class="modal fade" id="recharge_protocal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header modal-center-component">
                    <h1 class="modal-title fs-5">充值协议</h1>
                </div>
                <div class="modal-body modal-center-component">
                    本充值协议（以下简称“协议”）由以下双方（以下简称“用户”和“网站”）共同遵守，并规定了用户在网站上进行充值操作的相关条款和条件。在进行任何充值之前，请务必仔细阅读本协议，并确保您充分理解并同意所有内容。如果您不同意本协议的任何部分，请勿使用网站的充值服务。

                    定义
                    1.1. "用户"指的是访问和使用本网站的任何个人或实体。

                    1.2. "网站"指的是提供充值服务的网站，包括其所有者、运营者和关联公司。

                    1.3. "充值"是指用户在网站上为其账户或用户账户添加资金或货币的行为。

                    充值流程
                    2.1. 用户可以通过网站上提供的指定支付方式进行充值。

                    2.2. 用户必须提供准确、完整的充值信息，包括但不限于充值金额和支付方式。

                    2.3. 用户在进行充值前需确保自己有合法的权利使用所选支付方式。

                    账户余额
                    3.1. 用户的充值将会在成功处理后显示在其账户余额上。

                    3.2. 用户的账户余额可用于网站上提供的各种服务和产品的支付。

                    收费和费率
                    4.1. 用户在进行充值时可能会被收取一定的费用或手续费，具体费率将根据网站的政策和用户所选择的支付方式而定。

                    4.2. 网站有权根据业务需要随时更改收费标准和费率，并将在网站上公布任何更改。

                    退款政策
                    5.1. 用户一旦完成充值，通常情况下将不允许退款。然而，网站保留根据具体情况决定是否提供退款的权利。

                    5.2. 如果用户认为存在错误充值或未经授权的充值行为，请立即联系网站客服，并提供相关证据以协助调查。

                    安全性和责任
                    6.1. 用户有责任妥善保管其充值相关信息，包括但不限于支付账户信息和密码，以防止未经授权的使用。

                    6.2. 用户应确保其计算机或设备免受病毒、恶意软件和未经授权的访问的威胁，以保护其账户安全。

                    6.3. 网站将采取合理的安全措施来保护用户的充值信息，但不能对任何未经授权的访问或数据泄露承担责任。

                    终止
                    7.1. 网站有权根据其自身政策随时终止或暂停用户的充值服务，而无需提前通知。

                    7.2. 用户有权随时终止其账户的使用，但可能会受到一定的限制和条件。

                    适用法律和争议解决
                    8.1. 本协议受到适用法律的管辖，任何因本协议引起的争议应通过友好协商解决。

                    8.2. 如果无法通过协商解决争议，双方同意将争议提交适用法院解决。

                    其他条款
                    9.1. 本协议构成用户和网站之间关于充值服务的完整协议，取代了任何先前的口头或书面协议。

                    9.2. 网站保留根据业务需要随时修改本协议的权利，修改后的协议将在网站上公布，并自生效日起生效。

                    请确保您已充分阅读并理解本充值协议的所有条款和条件。通过进行充值，您表示您已接受并同意遵守本协议的规定。如果您有任何疑问或需要进一步解释，建议您在进行充值之前与网站联系，以获得必要的支持和指导。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MediumContentBase from '@/components/base/MediumContentBase.vue';
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

export default {
    components: {
        MediumContentBase,
    },

    setup() {
        const store = useStore();
        let my_account_currency = ref("");
        let amount = ref(5);
        let pay_type = ref(1);
        let protocal_checked = ref(false);
        onMounted(() => {
            axios.get("/user-account/info").then(resp => {
                if (resp.code == 200) {
                    my_account_currency.value = resp.data.currency;
                    store.commit("updateCurrency", resp.data.currency);
                }
            })
        })

        const pay = () => {
            axios.post("/alipay/pay", {
                rechargeType: amount.value,
            }).then(resp => {
                // 使用window.open()在新窗口中打开数据页面
                const newWindow = window.open('', '_blank');
                newWindow.document.write(resp.data);
            }).catch(err => {
                console.log(err);
            })
        }

        return {
            amount,
            pay_type,
            my_account_currency,
            protocal_checked,
            pay
        }
    },
}
</script>

<style scoped>
.title {
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
}

.text {
    font-size: 18px;
}

.box-card {
    text-align: center;
    align-items: center;
    width: 80%;
}

.user-avatar {
    width: 3vw;
    height: 3vw;
}

.pay_img {
    width: 5vw;
    height: 4vh;
}

.modal-center-component {
    display: flex;
    justify-content: center;
}
</style>