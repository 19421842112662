import { GameObject } from "../GameObject";

export class IndexBoard extends GameObject {
    constructor(ctx, parent, size, handicap) {
        super();
        this.ctx = ctx;
        this.parent = parent;
        this.size = size;
        this.handicap = handicap;
        this.L = 0;
        this.cell_len = 0;
        this.is_black = true;
        this.virtual_x = -1;
        this.virtual_y = -1;
        const black_image = new Image();
        const white_image = new Image();
        black_image.src = require('@/assets/images/shell_stb1.png');
        white_image.src = require('@/assets/images/shell_stw9.png');
        this.black_image = black_image;
        this.white_image = white_image;
    }

    init_stars() {
        if (this.size.value == 19) {
            for (let i = 4; i <= 16; i += 6) {
                for (let j = 4; j <= 16; j += 6) {
                    this.draw_stars(i, j);
                }
            }
        } else if (this.size.value == 13) {
            this.draw_stars(4, 4);
            this.draw_stars(7, 7);
            this.draw_stars(4, 10);
            this.draw_stars(10, 4);
            this.draw_stars(10, 10);
        }
    }

    start() {
        this.draw_stones(4, 4, 1);
    }

    draw_lines() {
        this.ctx.lineWidth = 0.5;
        for (let i = 1; i <= this.size.value; i++) {
            this.ctx.moveTo(this.cell_len, this.cell_len * i);
            this.ctx.lineTo(this.cell_len * this.size.value, this.cell_len * i);
            this.ctx.stroke();
            this.ctx.moveTo(this.cell_len * i, this.cell_len);
            this.ctx.lineTo(this.cell_len * i, this.cell_len * this.size.value);
            this.ctx.stroke();
        }
    }

    draw_stones(x, y, color) {
        const center_x = x * this.cell_len;
        const center_y = y * this.cell_len;
        const r = this.cell_len / 2;
        if (color == 1) {
            this.ctx.beginPath();
            this.ctx.arc(center_x, center_y, r, 0, Math.PI * 2);
            this.ctx.closePath();
            this.ctx.drawImage(this.black_image, center_x - r, center_y - r, r * 2, r * 2);
        } else if (color == 2) {
            this.ctx.beginPath();
            this.ctx.arc(center_x, center_y, r, 0, Math.PI * 2);
            this.ctx.closePath();
            this.ctx.drawImage(this.white_image, center_x - r, center_y - r, r * 2, r * 2);
        }
    }

    draw_initial_stoens() {
        if (this.size.value == 19) {
            if (this.handicap.value == 2) {
                this.draw_stones(4, 4, 1);
                this.draw_stones(16, 16, 1);
            } else if (this.handicap.value == 3) {
                this.draw_stones(4, 4, 1);
                this.draw_stones(16, 16, 1);
                this.draw_stones(4, 16, 1);
            } else if (this.handicap.value == 4) {
                this.draw_stones(4, 4, 1);
                this.draw_stones(16, 16, 1);
                this.draw_stones(16, 4, 1);
                this.draw_stones(4, 16, 1);
            } else if (this.handicap.value == 5) {
                this.draw_stones(4, 4, 1);
                this.draw_stones(16, 16, 1);
                this.draw_stones(16, 4, 1);
                this.draw_stones(4, 16, 1);
                this.draw_stones(10, 10, 1);
            } else if (this.handicap.value == 6) {
                this.draw_stones(4, 4, 1);
                this.draw_stones(16, 16, 1);
                this.draw_stones(16, 4, 1);
                this.draw_stones(4, 16, 1);
                this.draw_stones(4, 10, 1);
                this.draw_stones(16, 10, 1);
            } else if (this.handicap.value == 7) {
                this.draw_stones(4, 4, 1);
                this.draw_stones(16, 16, 1);
                this.draw_stones(16, 4, 1);
                this.draw_stones(4, 16, 1);
                this.draw_stones(4, 10, 1);
                this.draw_stones(16, 10, 1);
                this.draw_stones(10, 10, 1);
            } else if (this.handicap.value == 8) {
                this.draw_stones(4, 4, 1);
                this.draw_stones(16, 16, 1);
                this.draw_stones(16, 4, 1);
                this.draw_stones(4, 16, 1);
                this.draw_stones(4, 10, 1);
                this.draw_stones(16, 10, 1);
                this.draw_stones(10, 4, 1);
                this.draw_stones(10, 16, 1);
            } else if (this.handicap.value == 9) {
                this.draw_stones(4, 4, 1);
                this.draw_stones(16, 16, 1);
                this.draw_stones(16, 4, 1);
                this.draw_stones(4, 16, 1);
                this.draw_stones(4, 10, 1);
                this.draw_stones(16, 10, 1);
                this.draw_stones(10, 4, 1);
                this.draw_stones(10, 16, 1);
                this.draw_stones(10, 10, 1);
            }
        } 
        else if (this.size.value == 13) {
            if (this.handicap.value == 2) {
                this.draw_stones(4, 10, 1);
                this.draw_stones(10, 4, 1);
            } else if (this.handicap.value == 3) {
                this.draw_stones(4, 10, 1);
                this.draw_stones(10, 4, 1);
                this.draw_stones(10, 10, 1);
            } else if (this.handicap.value == 4) {
                this.draw_stones(4, 4, 1);
                this.draw_stones(4, 10, 1);
                this.draw_stones(10, 4, 1);
                this.draw_stones(10, 10, 1);
            } else if (this.handicap.value == 5) {
                this.draw_stones(4, 4, 1);
                this.draw_stones(10, 10, 1);
                this.draw_stones(4, 10, 1);
                this.draw_stones(10, 4, 1);
                this.draw_stones(7, 7, 1);
            }
        }
    }

    draw_stars(x, y) {
        this.ctx.fillStyle = "#000000";
        this.ctx.beginPath();
        this.ctx.arc(x * this.cell_len, y * this.cell_len, this.cell_len / 14, 0, Math.PI * 2);
        this.ctx.fill();
    }

    draw_indexes() {
        for (let i = this.size.value; i >= 1; i--) {
            this.ctx.fillStyle = "black";
            this.ctx.font = "12px Arial";
            this.ctx.fillText(this.size.value + 1 - i, this.cell_len * 0.3, this.cell_len * i + 5);
            this.ctx.fillText(this.size.value + 1 - i, this.cell_len * (this.size.value + 0.5), this.cell_len * i + 5);
        }
        let alp;
        for (let i = 1; i <= this.size.value; i++) {
            this.ctx.fillStyle = "black";
            alp = i >= 9 ? String.fromCharCode(65 + i) : String.fromCharCode(64 + i);
            this.ctx.fillText(alp, this.cell_len * i - 2, this.cell_len * (this.size.value + 0.7));
            this.ctx.fillText(alp, this.cell_len * i - 2, this.cell_len * 0.5);
        }
    }

    // 每秒根据浏览器更新大小
    update_size() {
        // 因为区域是不断变化的，所以要在每一帧里动态地求一个区域中可以包含的最大的矩形的一个格子的长和宽
        this.L = parseInt(Math.min(this.parent.clientWidth / this.size.value, this.parent.clientHeight / this.size.value));
        this.ctx.canvas.width = this.L * this.size.value;
        this.ctx.canvas.height = this.L * this.size.value;
        this.cell_len = parseInt(this.ctx.canvas.width / (this.size.value + 1));
    }

    on_destory() {
    }

    update() {
        this.update_size();
        this.draw_lines();
        this.init_stars();
        this.draw_indexes();
        if (this.handicap.value != 0) this.draw_initial_stoens();
        this.render();
    }

    render() {
    }
}