<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 rules">
                <div class="form-floating">
                    <select class="form-select" id="floatingSelect" v-model="selected_size">
                        <option selected :value=19>19×19</option>
                        <option :value=13>13×13</option>
                    </select>
                    <label for="floatingSelect">棋盘大小</label>
                </div>
            </div>
            <div class="col-md-4 rules">
                <div class="form-floating">
                    <select class="form-select" id="floatingSelect">
                        <option selected>黑贴3又3/4子</option>
                    </select>
                    <label for="floatingSelect">贴目</label>
                </div>
            </div>
            <div class="col-md-3 rules">
                <div class="form-floating">
                    <select class="form-select" id="floatingSelect" v-model="selected_type">
                        <option selected :value=1>围棋</option>
                        <option :value=2>五子棋</option>
                    </select>
                    <label for="floatingSelect">类别</label>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 rules">
                <div class="form-floating">
                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                        <option selected>猜先</option>
                    </select>
                    <label for="floatingSelect">棋手</label>
                </div>
            </div>
            <div class="col-md-4 rules">
                <div class="form-floating">
                    <select class="form-select" id="floatingSelect" v-model="selected_time">
                        <option selected :value=30>30分钟</option>
                        <option :value=10>10分钟</option>
                    </select>
                    <label for="floatingSelect">时间</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="status === 'waiting' || status === 'playing'">
        <el-button type="success" round class="match" @click="click_match"
            :disabled="status === 'playing' ? true : false">开始匹配</el-button>
    </div>
    <div class="row" v-if="status === 'matching'">
        <el-button type="info" round class="match" @click="click_match">取消匹配...</el-button>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import { ref, watch } from 'vue';

export default {
    setup() {
        const store = useStore();
        let selected_type = ref(1);
        let selected_size = ref(19);
        let selected_time = ref(30);
        let status = ref('waiting');
        if (sessionStorage.getItem('game_status') != null && sessionStorage.getItem('game_status') !== undefined) {
            status.value = sessionStorage.getItem('game_status');
        }

        const click_match = () => {
            let socket = store.state.gogame.socket;
            if (socket != null) {
                if (status.value == 'matching') {
                    store.state.gogame.socket.send(JSON.stringify({
                        event: "cancel",
                    }));
                    status.value = 'waiting';
                    sessionStorage.setItem('game_status', 'waiting');
                } else if (status.value == 'waiting') {
                    sessionStorage.setItem('game_status', 'matching');
                    status.value = 'matching';
                    store.state.gogame.socket.send(JSON.stringify({
                        event: "start",
                        type: selected_type.value,
                    }));
                }
            } else {
                ElMessage.warning("请保持您只有一个账号在线");
            }
        }

        watch(() => selected_type.value, () => {
            if (selected_type.value == 2) {
                selected_size.value = 13;
                selected_time.value = 10;
            }
            if (selected_type.value == 1) {
                selected_time.value = 30;
            }
        }, { immediate: true });

        watch(() => selected_size.value, () => {
            if (selected_size.value == 19) {
                selected_type.value = 1;
                selected_time.value = 30;
            }
        }, { immediate: true });

        watch(() => selected_time.value, () => {
            if (selected_time.value == 10) {
                selected_type.value = 2;
            }
            if (selected_time.value == 30) {
                selected_type.value = 1;
            }
        }, { immediate: true });

        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('game_status', 'waiting');
        })

        return {
            click_match,
            status,
            selected_type,
            selected_size,
            selected_time
        }
    }
}
</script>

<style scoped>
.rules {
    text-align: center;
    margin-left: 1vw;
    margin-top: 25px;
}

.match {
    width: 50%;
    height: 40px;
    display: block;
    margin: 30px auto 5px auto;
}
</style>