import store from ".";
import router from "@/router";
import axios from "axios";

const UserModule = {
    state: {
        id: "",
        username: "",
        avatar: "",
        phone: "",
        token: "",
        profile: "",
        type: "",
        currency: "",
        is_login: false,
        is_member: false,
    },
    getters: {
    },
    // 在mutations里更新state的状态 在actions里调用, 在mutations里不能执行异步操作
    mutations: {
        updateUserInfo(state, user) {
            state.id = user.id;
            state.username = user.username;
            state.avatar = user.avatar;
            state.phone = user.phone;
            state.type = user.type,
            state.currency = user.currency;
            state.is_login = user.is_login;
            state.is_member = user.member;
        },
        updateToken(state, token) {
            state.token = token;
        },
        updateLogin(state, is_login) {
            state.is_login = is_login;
        },
        updateUser(state, user) {
            state.username = user.username;
            state.profile = user.profile;
        },
        updateAvatar(state, avatar) {
            state.avatar = avatar;
        },
        udatePhone(state, phone) {
            state.phone = phone;
        },
        updateCurrency(state, currency) {
            state.currency = currency;
        },
        updateMember(state, is_member) {
            state.is_member = is_member;
        },
        logout(state) {
            state.id = "";
            state.username = "";
            state.avatar = "";
            state.token = "";
            state.profile = "";
            state.currency = "";
            state.phone = "";
            state.type = "";
            state.is_login = false;
        },
    },
    actions: {
        // context为上下文 用户点击登陆时调用
        password_login(context, userInfo) {
            axios.post('/account/token', {
                account: userInfo.account,
                password: userInfo.password,
            }).then(resp => {
                if (resp.code === 200) {
                    context.commit("updateToken", resp.data);  // 登陆成功后更新token
                    userInfo.success(resp); // 成功的回调函数
                } else {
                    userInfo.error(resp);   // 失败的回调函数
                }
            }).catch(error => {
                userInfo.error(error);
            })
        },

        verification_code_login(context, userInfo) {
            axios.post('/account/login/code', {
                phoneNumber: userInfo.phone_number,
                verificationCode: userInfo.verification_code,
            }).then(resp => {
                if (resp.code == 200) {
                    context.commit("updateToken", resp.data);  // 登陆成功后更新token
                    userInfo.success(resp); // 成功的回调函数
                } else {
                    userInfo.error(resp);   // 失败的回调函数
                }
            }).catch(error => {
                userInfo.error(error);
            })
        },

        //  从后端获取当前登陆的用户的信息，包括用户名、头像
        getInfo(context, data) {
            axios.get('/user/info').then(resp => {
                if (resp.code === 200) {
                    context.commit("updateUserInfo", {          // 更新用户信息 存储到store中
                        ...resp.data,    // 解构resp 直接填充到函数的参数列表中
                        is_login: true,
                    });
                    data.success(resp);
                } else {
                    data.error(resp);
                }
            }).catch(error => {
                data.error(error);
            })
        },

        logout(context) {
            context.commit("logout");
            router.push({
                name: 'login',
            })
            if (store.state.gogame.socket != null) {
                store.state.gogame.socket.close();
                store.commit("updateSocket", null);
            }
        },

        update_password(context, data) {
            if (data.old_password == data.new_password) {
                data.error({
                    data: "新旧密码不能相同",
                });
                return;
            } else if (data.new_password != data.confirm_password) {
                data.error({
                    data: "两次新密码不一致",
                });
                return;
            }
            axios.post('/user/update/password', {
                oldPassword: data.old_password,
                newPassword: data.new_password,
                confirmPassword: data.confirm_password,
            }).then(resp => {
                if (resp.code === 200) {
                    data.success(resp);
                } else {
                    data.error(resp);
                }
            }).catch(error => {
                data.error(error);
            })
        },

        update_phone(context, data) {
            axios.post('/user/update/phone', {
                oldPhone: data.old_phone,
                newPhone: data.new_phone,
                verificationCode: data.verification_code,
            }).then(resp => {
                if (resp.code === 200) {
                    data.success(resp);
                } else {
                    data.error(resp);
                }
            }).catch(error => {
                data.error(error);
            })
        }

    },
    modules: {
    }
}

export default UserModule;
