import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import uploader from 'vue-simple-uploader';
import VMdEditor from '@kangc/v-md-editor';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import 'element-plus/theme-chalk/index.css';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import '@kangc/v-md-editor/lib/style/preview.css';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import Prism from 'prismjs';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import './assets/scripts/common/enums';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import axios from 'axios';
import { total_notification_unread } from './components/base/NavBar.vue';

VMdEditor.use(vuepressTheme, {
    Prism
});

VMdPreview.use(vuepressTheme, {
    Prism
})

createApp(App).use(store).use(router).use(ElementPlus, { locale: zhCn }).use(uploader).use(VMdEditor).use(VMdPreview).mount('#app')

/**
 * axios拦截器
 */
axios.interceptors.request.use(function (config) {
    const _token = store.state.user.token;
    if (_token) {
        config.headers.Authorization = "Bearer " + _token;
    }
    if (config.headers['Content-Type'] === undefined) {
        config.headers['Content-Type'] = 'application/json';
    }
    //console.log('请求参数：', config);
    return config;
}, error => {
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
    //console.log('返回结果：', response.data);
    return response.data;
}, error => {
    //console.log('返回错误：', error);
    const response = error.response;
    const status = response.status;
    if (status === 401 || status === 403) {
        // 判断状态码是401 跳转到登录页
        if (router.currentRoute.value.name != 'login') {
            store.commit("logout");
            router.push({
                name: "login"
            })
        } else {
            return response.data;
        }
    }
    return Promise.reject(error);
});

axios.defaults.baseURL = "https://web.fcjznkj.com/api";

if (store.state.user.is_login) {
    axios.get('/notification/getCount').then(resp => {
        if (resp.code == 200) {
            total_notification_unread.value = resp.data;
        }
    })
}