<template>
    <el-skeleton :loading="loading" :rows="6" animated></el-skeleton>
    <el-empty description="这里空空如也" v-if="show_blank == true">
    </el-empty>
    <div class="card-body" v-else>
        <div v-for="post in posts" :key="post.id" style="margin-top:2vh" :id="'post_id_' + post.id">
            <div class="card post-card">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start align-items-center" style="min-width: 300px;">
                                <img :src="guests.avatar" id="user_avatar" /> &nbsp;&nbsp;&nbsp;
                                <span id="tag">{{ guests.username }}</span> &nbsp;&nbsp;&nbsp;
                                <span id="post_time"> 发布于&nbsp;{{ post.modifyTime }}</span>
                            </div>
                            <div class="d-flex flex-row-reverse align-items-center">
                                <button type="button" class="btn btn-danger btn-sm op btn-delete" v-if="is_me"
                                    data-bs-toggle="modal" plain @click="delete_post(post.id)">删除</button>
                                <button type="button" class="btn btn-primary btn-sm op btn-update" v-if="is_me"
                                    data-bs-toggle="modal" plain :data-bs-target="'#update_post_modal_' + post.id"
                                    @click="click_edit_post(post)">修改</button>
                                <span class="title">{{ post.title }}</span> &nbsp;
                            </div>
                        </div>
                        <!--修改帖子模态框-->
                        <div class="modal fade" :id="'update_post_modal_' + post.id" tabindex="-1">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="post_title">编辑新鲜事</h1>
                                    </div>
                                    <div class="modal-body">
                                        <div class="mb-3">
                                            <label for="post_title" class="form-label" style="float: left;">请填写标题</label>
                                            <input v-model="edit_post.title" type="text" class="form-control"
                                                id="post_title" placeholder="请填写标题">
                                        </div>
                                        <div class="mb-3">
                                            <label for="post_content" class="form-label"
                                                style="float: left;">写点什么(支持markdown语法)</label>
                                            <v-md-editor v-model="edit_post.content" height="400px"
                                                id="post_content"></v-md-editor>
                                        </div>
                                    </div>
                                    <div class="modal-footer modal-center-component">
                                        <div class="error_message">{{ edit_post.error_message }}</div>
                                        <el-button type="primary" @click="update_a_post(post)">保存修改</el-button>
                                        <el-button type="info" data-bs-dismiss="modal">取消</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="card">
                        <div class="card-body content" style="text-align: left;">
                            <v-md-preview :text="post.content"></v-md-preview>
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="d-flex justify-content-evenly">
                        <div class="stars">
                            <img src="@/assets/images/gray_star.png" v-if="post.liked == false"
                                @click="star_a_post(post)">&nbsp;
                            <img src="@/assets/images/red_star.png" v-else @click="star_a_post(post)">&nbsp;
                            <el-button link type="primary" v-if="post.stars != 0" style="color: gray;">{{ post.stars
                            }}</el-button>
                            <el-button link type="primary" v-else style="color: gray;">赞</el-button>
                        </div>
                        <div class="comment-button" @click="click_comment(post)">
                            <img src="@/assets/images/comment.png">
                            <el-button link type="primary" v-if="post.commentsCount == 0" style="color: gray;">
                                评论</el-button>
                            <el-button link type="primary" v-else style="color: gray;">&nbsp;{{ post.commentsCount
                            }}</el-button>
                        </div>
                    </div>
                    <CommentArea v-if="show_comment_post_set.has(post.id)" :post_id="post.id" :post_user_id="post.userId">
                    </CommentArea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import CommentArea from '../comment/CommentArea.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import axios from 'axios';

export default {
    components: {
        CommentArea,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
    },

    setup(props) {
        const store = useStore();
        const loading = ref(true);
        const route = useRouter();
        const is_me = computed(() => props.userId == store.state.user.id);
        const add_post = reactive({
            title: "",
            content: "",
            error_message: "",
        });
        const edit_post = reactive({
            title: "",
            content: "",
            error_message: "",
        });

        let posts = ref([]);
        let guests = ref([]);
        let show_blank = ref(false);
        let show_comment_post_set = ref(new Set());
        let delete_disabled = ref(false);

        const click_edit_post = (post) => {
            edit_post.title = post.title;
            edit_post.content = post.content;
            edit_post.error_message = post.error_message;
        }

        onMounted(() => {
            pull_all_posts();
            pull_user_info();
        });

        const pull_user_info = () => {
            axios.get('/user/get/id', {
                params: {
                    userid: props.userId,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    guests.value = resp.data;
                } else if (resp.code == 404) {
                    route.push({
                        name: "404",
                    });
                }
            })
        }

        const pull_all_posts = () => {
            loading.value = true;
            axios.get('/post/get/user', {
                params: {
                    userId: props.userId,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    posts.value = resp.data;
                    if (resp.data.length == 0) show_blank.value = true;
                    loading.value = false;
                }
            })
        }

        const delete_post = (post_id) => {
            if (delete_disabled.value) return;
            ElMessageBox.confirm("您确定删除这条帖子吗", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true,
            }).then(() => {
                delete_disabled.value = true;
                axios.post('/post/remove', {
                    postId: post_id,
                }).then(resp => {
                    if (resp.code == 200) {
                        pull_all_posts();
                        ElMessage({
                            message: "删除成功",
                            duration: 1000,
                            type: "success"
                        });
                        Modal.getInstance("#remove_post_modal_" + post_id).hide();
                    }
                    else {
                        ElMessage.error(resp.data);
                    }
                    delete_disabled.value = false;
                })
            });
        }

        const update_a_post = (post) => {
            add_post.error_message = "";
            axios.post('/post/update', {
                postId: post.id,
                title: edit_post.title,
                content: edit_post.content,
            }).then(resp => {
                if (resp.code === 200) {
                    Modal.getInstance("#update_post_modal_" + post.id).hide();
                    pull_all_posts();
                } else {
                    edit_post.error_message = resp.data;
                }
            })
        }

        const star_a_post = (post) => {
            axios.post('/post/star', {
                postId: post.id,
            }).then(resp => {
                if (resp.code == 200) {
                    if (!post.liked) {
                        post.liked = true;
                        post.stars++;
                    } else {
                        post.liked = false;
                        post.stars--;
                    }
                }
            })
        }

        const click_comment = (post) => {
            const click_comment_post_id = post.id;
            if (show_comment_post_set.value.has(click_comment_post_id)) show_comment_post_set.value.delete(click_comment_post_id);
            else show_comment_post_set.value.add(click_comment_post_id);
        }


        return {
            posts,
            add_post,
            edit_post,
            is_me,
            loading,
            show_comment_post_set,
            show_blank,
            guests,
            pull_all_posts,
            update_a_post,
            star_a_post,
            click_comment,
            click_edit_post,
            delete_post,
        }
    },
}
</script>

<style scoped>
.title {
    font-weight: 500;
    font-size: medium;
    text-align: center;
    margin-top: 5px;
}

.content {
    background-color: rgb(219, 243, 249, 0.2);
}

#tag {
    font-weight: 500;
    font-size: medium;
    text-align: center;
    margin-top: 5px;
}

#user_avatar {
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 30%;
}

#post_time {
    color: gray;
    font-weight: 400;
    font-size: medium;
    text-align: center;
    margin-top: 5px;
}

.op {
    margin-left: 1vh;
    height: 30px;
}

.post-card {
    box-shadow: 1px 1px 1px #b9b9b9;
}

div.error_message {
    font-weight: 700;
    color: red;
}

.hr {
    color: rgb(166, 163, 163, 0.5);
}

img {
    width: 1.5vw;
    cursor: pointer;
}

.stars {
    font-size: medium;
    padding: 0px 0px 0px 0px;
}

.comment-button {
    font-size: medium;
    padding: 0px 0px 0px 0px;
}

.modal-center-component {
    display: flex;
    justify-content: center;
}

.btn-delete {
    background-color: rgb(218, 88, 92);
    border-color: rgb(145, 66, 108);
}

.btn-delete:hover {
    background-color: rgb(203, 51, 51);
}

.btn-update {
    background-color: rgb(73, 127, 189);
    border-color: rgb(42, 73, 176);
}

.btn-update:hover {
    background-color: rgb(39, 115, 202);
}
</style>