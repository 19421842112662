<template>
    <ContentBase>
        <PuzzleListView></PuzzleListView>
    </ContentBase>
</template>

<script>
import ContentBase from '@/components/base/ContentBase.vue';
import PuzzleListView from '@/components/puzzle/PuzzleListView.vue';
export default {
    components: {
        ContentBase,
        PuzzleListView
    }
}
</script>

<style scoped></style>