import { createRouter, createWebHistory } from 'vue-router'
import PlayHallView from '../views/community/PlayHallView'
import PlayRoomView from '../views/play/PlayRoomView'
import FriendIndexView from '../views/friend/FriendIndexView'
import CommunityIndexView from '../views/community/CommunityIndexView'
import UserIndexView from '../views/user/index/UserIndexView'
import UserNotificationView from '../views/user/index/UserNotificationView'
import NotFoundView from '../views/error/NotFoundView'
import NotAuthView from '../views/error/NotAuthView.vue'
import LoginView from '../views/user/account/LoginView'
import UpdateUserInfoView from '../views/user/index/UpdateUserInfoView'
import GoPlayView from '../views/play/GoPlayView'
import UserPostsDetailsView from '../views/post/UserPostsDetailsView.vue'
import PublicPuzzlesView from '../views/puzzle/PublicPuzzlesView.vue'
import PuzzleDetailsView from '../components/puzzle/PuzzleDetailsView.vue'
import PracticeRoomView from '../views/play/PracticeRoomView.vue'
import ShopIndexView from '../views/shop/ShopIndexView'
import NormalRechargeView from '../views/recharge/NormalRechargeView.vue'
import UserPaymentRecordView from '../views/user/index/UserPaymentRecordView.vue'
import UserPurchaseRecordView from '../views/user/index/UserPurchaseRecordView.vue'
import RechargeSuccessView from '../views/recharge/RechargeSuccessView.vue'
import RechargeFailView from '../views/recharge/RechargeFailView.vue'
import PuzzleBankView from '../views/puzzle/PuzzleBankView.vue'
import PuzzleGradePracticeView from '../views/puzzle/PuzzleGradePractice.vue'
import PuzzleTypePracticeView from '../views/puzzle/PuzzleTypePractice.vue'
import PuzzleLadderPracticeView from '../views/puzzle/PuzzleLadderPractice.vue'
import PuzzleGradeListView from '../views/puzzle/PuzzleGradeListView'
import PuzzleTypeListView from '../views/puzzle/PuzzleTypeListView.vue'
import PuzzleLadderLevelView from '../views/puzzle/PuzzleLadderLevelView.vue'
import PuzzleLadderLevelGroupView from '../views/puzzle/PuzzleLadderLevelGroupView.vue'
// admin
import store from '../store/index'

const routes = [
    {
        path: '/',
        name: "home",
        redirect: "/goplay/",
        meta: {
            requestAuth: true,
            title: "方寸围棋"
        }
    },
    {
        path: '/playhall/',
        name: 'playhall',
        component: PlayHallView,
        meta: {
            requestAuth: false,
            title: "对局大厅 - 方寸围棋"
        }
    },
    {
        path: '/goplay/',
        name: 'goplay',
        component: GoPlayView,
        meta: {
            requestAuth: true,
            title: "对弈 - 方寸围棋"
        }
    },
    {
        path: '/playroom/:roomId/',
        name: 'playroom',
        component: PlayRoomView,
        meta: {
            requestAuth: true,
            title: "对弈 - 方寸围棋"
        }
    },
    {
        path: '/room/practice/:roomId/',
        name: 'practice_room',
        component: PracticeRoomView,
        meta: {
            requestAuth: true,
            title: "练习房间 - 方寸围棋"
        }
    },
    {
        path: '/record/index/:recordId/',
        name: 'record_review',
        component: () => import('../views/record/RecordReviewView'),
        meta: {
            requestAuth: true,
            title: "复盘 - 方寸围棋"
        }
    },
    {
        path: '/record/mina/responsive/:recordId/',
        name: 'record_review_responseable',
        component: () => import('../views/record/ResponseableRecordReview'),
        meta: {
            requestAuth: false,
            title: "复盘 - 方寸围棋"
        }
    },
    {
        path: '/record/index',
        name: 'record',
        component: () => import('../views/record/RecordIndexView'),
        meta: {
            requestAuth: true,
            title: "复盘 - 方寸围棋"
        }
    },
    {
        path: '/friend/',
        name: 'friend',
        component: FriendIndexView,
        meta: {
            requestAuth: true,
            title: "好友 - 方寸围棋"
        }
    },
    {
        path: '/community/',
        name: 'community',
        component: CommunityIndexView,
        meta: {
            requestAuth: true,
            title: "社区 - 方寸围棋"
        }
    },
    {
        path: '/user/myspace/index/:userId/',
        name: 'user_index',
        component: UserIndexView,
        meta: {
            requestAuth: true,
            title: "个人空间 - 方寸围棋"
        }
    },
    {
        path: '/user/login/',
        name: 'login',
        component: LoginView,
        meta: {
            requestAuth: false,
            title: "登录 - 方寸围棋"
        }
    },
    {
        path: '/user/myspace/update/',
        name: 'update_info',
        component: UpdateUserInfoView,
        meta: {
            requestAuth: true,
            title: "个人信息 - 方寸围棋"
        }
    },
    {
        path: '/user/notification/index',
        name: 'user_notification',
        component: UserNotificationView,
        meta: {
            requestAuth: true,
            title: "个人空间 - 方寸围棋"
        }
    },
    {
        path: '/user/payment/record/index',
        name: 'user_payment_record',
        component: UserPaymentRecordView,
        meta: {
            requestAuth: true,
            title: "充值记录 - 方寸围棋"
        }
    },
    {
        path: '/user/purchase/record/index',
        name: 'user_purchase_record',
        component: UserPurchaseRecordView,
        meta: {
            requestAuth: true,
            title: "消费记录 - 方寸围棋"
        }
    },
    {
        path: '/user/post/:postId',
        name: 'post_details',
        component: UserPostsDetailsView,
        meta: {
            requestAuth: true,
            title: "帖子详情 - 方寸围棋"
        }
    },
    {
        path: '/puzzles/',
        name: 'public_puzzles',
        component: PublicPuzzlesView,
        meta: {
            requestAuth: true,
            title: "练习 - 方寸围棋"
        }
    },
    {
        path: '/puzzles/bank',
        name: 'puzzle_bank',
        component: PuzzleBankView,
        meta: {
            requestAuth: true,
            title: "题库 - 方寸围棋"
        }
    },
    {
        path: '/puzzles/grade/prictice',
        name: 'puzzle_grade_practice',
        component: PuzzleGradePracticeView,
        meta: {
            requestAuth: true,
            title: "难度练习 - 方寸围棋"
        }
    },
    {
        path: '/puzzles/type/prictice',
        name: 'puzzle_type_practice',
        component: PuzzleTypePracticeView,
        meta: {
            requestAuth: true,
            title: "分类练习 - 方寸围棋"
        }
    },
    {
        path: '/puzzles/ladder/prictice',
        name: 'puzzle_ladder_practice',
        component: PuzzleLadderPracticeView,
        meta: {
            requestAuth: true,
            title: "天梯闯关 - 方寸围棋"
        }
    },
    {
        path: '/puzzles/ladder/level/:ladder_code',
        name: 'puzzle_ladder_level_practice',
        component: PuzzleLadderLevelView,
        meta: {
            requestAuth: true,
            title: "天梯闯关 - 方寸围棋"
        }
    },
    {
        path: '/puzzles/ladder/level/group/:group_code',
        name: 'puzzle_ladder_level_group',
        component: PuzzleLadderLevelGroupView,
        meta: {
            requestAuth: true,
            title: "天梯闯关 - 方寸围棋"
        }
    },
    {
        path: '/puzzles/grade/prictice/list/:collectionName',
        name: 'puzzle_grade_practice_list',
        component: PuzzleGradeListView,
        meta: {
            requestAuth: true,
            title: "难度练习 - 方寸围棋"
        }
    },
    {
        path: '/puzzles/type/prictice/list/:typeCode',
        name: 'puzzle_type_practice_list',
        component: PuzzleTypeListView,
        meta: {
            requestAuth: true,
            title: "分类练习 - 方寸围棋"
        }
    },
    {
        path: '/puzzles/details/:puzzleId',
        name: 'puzzle_details',
        component: PuzzleDetailsView,
        meta: {
            requestAuth: true,
            title: "题目练习 - 方寸围棋"
        }
    },
    {
        path: '/shop/index/',
        name: 'shop_index',
        component: ShopIndexView,
        meta: {
            requestAuth: true,
            title: "商城 - 方寸围棋"
        }
    },
    {
        path: '/recharge/normal/',
        name: 'recharge_normal',
        component: NormalRechargeView,
        meta: {
            requestAuth: true,
            title: "充值 - 方寸围棋"
        }
    },
    {
        path: '/admin/index/',
        name: 'admin_base',
        component: () => import('../views/admin/AdminIndexView.vue'),
        meta: {
            requestAuth: true,
            admin: true,
            title: "管理员 - 方寸围棋"
        }
    },
    {
        path: '/admin/post/',
        name: 'admin_post',
        component: () => import('../components/admin/AdminPostView.vue'),
        meta: {
            requestAuth: true,
            admin: true,
            title: "管理员 - 方寸围棋"
        }
    },
    {
        path: '/recharge/result/success',
        name: 'recharge_success',
        component: RechargeSuccessView,
        meta: {
            requestAuth: true,
            title: "充值成功 - 方寸围棋"
        }
    },
    {
        path: '/recharge/result/fail',
        name: 'recharge_fail',
        component: RechargeFailView,
        meta: {
            requestAuth: true,
            title: "充值结果 - 方寸围棋"
        }
    },
    {
        path: '/404/',
        name: '404',
        component: NotFoundView,
        meta: {
            requestAuth: false,
            title: "找不到页面 - 方寸围棋"
        }
    },
    {
        path: '/405/',
        name: '405',
        component: NotAuthView,
        meta: {
            requestAuth: false,
            title: "找不到页面 - 方寸围棋"
        }
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404/',
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// 一个跳转页面的api 在router加载之前起作用 from..to 来自哪个页面和目标页面 next 要不要进行下一步
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if ((to.meta.requestAuth && !store.state.user.is_login)) {
        next({ name: "login" });
    }
    else if (to.meta.admin && store.state.user.type !== 'ADMIN') {
        next({ name: "405" });
    }
    else {
        next();
    }
})

export default router
