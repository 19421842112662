<template>
    <el-skeleton :loading="friends_loading" :rows="6" animated></el-skeleton>
    <el-empty description="您还没有好友" v-if="friends_empty_status == true">
    </el-empty>
    <div v-else-if="!friends_loading">
        <el-table :data="friends" style="width: 100%; text-align: center;" align="center" stripe v-if="!friends_loading"
            max-height="600">
            <el-table-column align="left" header-align="center" label="用户" width="180">
                <template v-slot="{ row }">
                    <div style="padding-left: 20px;">
                        <router-link :to="{ name: 'user_index', params: { userId: row.id } }">
                            <img :src="row.avatar" alt="" class="user-avatar" @click="nav_to_user_space(row.id)" />
                        </router-link>
                        &nbsp;
                        <span class="user-info"> {{ row.username }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center" :sortable="true" :sort-method="sortStatus">
                <template v-slot="{ row }">
                    <div v-if="row.state == 1">
                        <el-tag type="success" v-if="row.invitation_state == 1">在线</el-tag>
                        <el-tag v-else>在线 拒绝</el-tag>
                    </div>
                    <div v-else>
                        <el-tag type="info">不在线</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="棋力" align="center" :sortable="true" :sort-method="sortLevel" width="80">
                <template v-slot="{ row }">
                    <el-tag :color="LEVEL[row.level].color">
                        <span style="color: white;">
                            {{ row.level }}
                        </span>
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="战绩" align="center">
                <template v-slot="{ row }">
                    <el-tag> {{ row.win + "胜" }} &nbsp;{{ row.lose + "负" }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button type="primary" plain size="small"
                        v-if="row.status === 'stand' && row.state == 1 && row.invitation_state == 1" data-bs-toggle="modal"
                        data-bs-target="#invite" @click="click_invite(row.id)">邀请对局</el-button>
                    <el-button type="info" plain size="small"
                        v-if="row.status === 'stand' && (row.state != 1 || row.invitation_state != 1)"
                        disabled>邀请对局</el-button>
                    <el-button type="info" size="small" v-else-if="row.status === 'playing'" disabled
                        :loading="true">对局中</el-button>
                    <el-button type="info" size="small" v-else-if="row.status === 'matching'" disabled
                        :loading="true">匹配中</el-button>
                </template>
            </el-table-column>

        </el-table>
        <!-- 邀请好友模态框 -->
        <div class="modal fade" id="invite" tabindex="-1">
            <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header modal-center-component">
                        <h1 class="modal-title fs-5">邀请好友</h1>
                    </div>
                    <div class="modal-body">
                        <div class="modal-center-component">
                            <el-form>
                                <el-form-item label="类型">
                                    <el-select v-model="room_config.room_type" placeholder="五子棋/围棋"
                                        @change="select_type_change">
                                        <el-option label="围棋" :value=1></el-option>
                                        <el-option label="五子棋" :value=2></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="大小">
                                    <el-select v-model="room_config.room_size" placeholder="请选择棋盘大小">
                                        <el-option label="19*19" :value=19
                                            :disabled="room_config.room_type == 2"></el-option>
                                        <el-option label="13*13" :value=13></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="棋手">
                                    <el-select v-model="room_config.order" placeholder="请选择先后手">
                                        <el-option label="执黑" value="1"></el-option>
                                        <el-option label="执白" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div class="modal-footer modal-center-component">
                        <button type="button" class="btn btn-primary btn-function" @click="invite_play"> 邀请
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { request_play } from '@/App.vue';
import { ElMessage } from 'element-plus';

export default {
    props: {
        friends: {
            type: Array,
            required: true,
        },
        friends_empty_status: {
            type: Boolean,
            required: true,
        },
        friends_loading: {
            type: Boolean,
            required: true,
        }
    },
    setup() {
        const store = useStore();
        const LEVEL = window.LEVEL;

        let invite_id = ref('');

        let room_config = reactive({
            room_size: 19,
            room_type: 1,
            order: "1",
        });

        onMounted(() => {

        });

        const click_invite = (friend_id) => {
            invite_id.value = friend_id;
        }

        const invite_play = () => {
            Modal.getInstance("#invite").hide();
            let socket = store.state.gogame.socket;
            if (socket != null) {
                store.state.gogame.socket.send(JSON.stringify({
                    event: "request_play",
                    user: "web",
                    order: room_config.order,
                    friend_id: invite_id.value,
                    size: room_config.room_size,
                    type: room_config.room_type,
                }));
                request_play.alert('等待对方回应', {
                    confirmButtonText: '取消',
                    type: 'info',
                    center: true,
                    callback: () => {
                        store.state.gogame.socket.send(JSON.stringify({
                            event: "request_cancel",
                            friend_id: invite_id.value,
                        }));
                    }
                });
            } else {
                ElMessage.warning("请保持您只有一个账号在线");
            }
        }

        const select_type_change = () => {
            if (room_config.room_type == 2) {
                room_config.room_size = 13;
            }
        }

        return {
            LEVEL,
            room_config,
            invite_play,
            click_invite,
            select_type_change,
        }
    },

    methods: {
        sortLevel(a, b) {
            const aL = this.LEVEL[a.level].level;
            const bL = this.LEVEL[b.level].level;
            if (aL > bL) return 1;
            else if (aL < bL) return -1;
            else return 0;
        },

        sortStatus(a, b) {
            let aS = a.state == true ? 1 : -1;
            let bS = b.state == true ? 1 : -1;
            if (aS > bS) return -1;
            else if (aS < bS) return 1;
            else return 0;
        }
    }
}
</script>

<style scoped>
.user-info {
    font-weight: 500;
}

img.user-avatar {
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
}


.modal-center-component {
    display: flex;
    justify-content: center;
}

.btn-function {
    background-color: rgb(140, 181, 235);
    border-color: aliceblue;
}

.btn-function:hover {
    background-color: rgb(65, 113, 143);
}
</style>