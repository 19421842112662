<template>
    <div class="d-flex justify-content-start">
        <div ref="parent" class="goboard">
            <canvas ref="canvas" tabindex="0"></canvas>
        </div>
        <div class="play-info-base">
            <div class="card info-board">
                <div class="card-body" style="background-color: rgb(238, 237, 237);">
                    <el-row justify="center">
                        <el-col :span="4">
                            <div class="center-layout">
                                <div class="image-container">
                                    <div
                                        style="display: inline-block; border-radius: 10%; overflow: hidden; background-color: white;">
                                        <img :src="room_info.data.black_avatar" alt="" class="player-avatar">
                                        <img src="../../assets/images/shell_stb1.png" alt="" class="small-image-right">
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="16">
                            <el-row>
                                <el-col :span="4">
                                    <el-row justify="center">
                                        <div class="username center-layout">
                                            <span>{{ room_info.data.black_username }}</span>
                                        </div>
                                    </el-row>
                                    <el-row justify="center">
                                        <div class="username center-layout">
                                            <span>{{ room_info.data.black_level }}</span>
                                        </div>
                                    </el-row>
                                    <el-row justify="center">
                                        <el-countdown :value="room_info.data.black_time * 1000 + Date.now()"
                                            v-if="room_info.data.current == 1 && room_info.data.result === ''" />
                                    </el-row>
                                </el-col>
                                <el-col :span="16">
                                    <el-row justify="center">
                                        <el-col :span="24">
                                            <div class="center-layout">
                                                <span style="font-size: 14px;font-weight: 400; color: black;">第{{
                                                    room_info.data.play_count }}手</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row justify="center">
                                        <el-col :span="24">
                                            <div class="center-layout">
                                                <span
                                                    style="font-size: 14px;font-weight: 400; color: black;">黑贴3又3/4子</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row justify="center">
                                        <el-col :span="24">
                                            <div class="center-layout"
                                                v-if="show_lead === true && room_info.data.type == 1">
                                                <el-text type="success" size="large">{{ room_info.data.score_lead
                                                }}</el-text>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row justify="center">
                                        <el-col :span="24">
                                            <div v-if="room_info.data.result != null || room_info.data.result != ''"
                                                class="center-layout">
                                                <el-text size="large" type="success"> {{ room_info.data.result }}</el-text>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </el-col>
                                <el-col :span="4">
                                    <el-row justify="center">
                                        <div class="username center-layout">
                                            <span>{{ room_info.data.white_username }}</span>
                                        </div>
                                    </el-row>
                                    <el-row justify="center">
                                        <div class="username center-layout">
                                            <span>{{ room_info.data.white_level }}</span>
                                        </div>
                                    </el-row>
                                    <el-row justify="center">
                                        <el-countdown :value="room_info.data.white_time * 1000 + Date.now()"
                                            v-if="room_info.data.current == 2 && room_info.data.result === ''" />
                                    </el-row>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="4">
                            <div class="center-layout">
                                <div class="image-container">
                                    <div
                                        style="display: inline-block; border-radius: 10%; overflow: hidden; background-color: white;">
                                        <img :src="room_info.data.white_avatar" alt="" class="player-avatar">
                                    </div>
                                    <img src="../../assets/images/shell_stw9.png" alt="" class="small-image-left">
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <div v-if="(room_info.data.black_id == $store.state.user.id || room_info.data.white_id == $store.state.user.id)
                        && room_info.data.has_engine && room_info.data.type == 1 && $store.state.gogame.which == 0">
                        <el-tabs type="border-card" :stretch=true tab-position="top" class="play-board">
                            <AISettings @update_handicap="update_handicap" @update_size="update_size"
                                @leave_room="leave_room" />
                        </el-tabs>
                    </div>
                    <div v-else-if="$store.state.gogame.which == 0" class="d-flex justify-content-center"
                        style="margin-top: 2vh;">
                        <el-button round type="danger" @click="leave_room(null)" class="action-button">
                            离开房间
                            <el-icon size="20">
                                <HomeFilled />
                            </el-icon>
                        </el-button>
                    </div>

                    <div class="d-flex justify-content-evenly" v-else style="margin-top: 4vh;">
                        <el-button round v-if="room_info.data.type == 1" @click="request_draw" class="action-button">
                            申请和棋
                            <el-icon size="20">
                                <Connection />
                            </el-icon>
                        </el-button>
                        <el-button round :type="room_info.data.judge_count > 0 ? 'warning' : 'info'" @click="judge"
                            v-if="has_engine && room_info.data.type == 1" class="action-button">
                            形势判断({{ room_info.data.judge_count }})
                            <el-icon size="20">
                                <Histogram />
                            </el-icon>
                        </el-button>
                        <el-button round :type="room_info.data.regret_count > 0 ? 'success' : 'info'"
                            :disabled="regret && has_engine" @click="request_regret" class="action-button">
                            申请悔棋({{ room_info.data.regret_count }})
                            <el-icon size="20">
                                <RefreshLeft />
                            </el-icon>
                        </el-button>
                        <el-button type="danger" round v-if="room_info.data.type == 1" @click="click_resign"
                            class="action-button">
                            放弃
                            <el-icon size="20">
                                <Flag />
                            </el-icon>
                        </el-button>
                    </div>
                    <RoomUserList :roomId="roomId" />
                </div>
            </div>
        </div>
    </div>

    <el-dialog v-model="outerVisible" title="道具购买" center :close-on-press-escape="false" :close-on-click-modal="false">
        <template #default>
            <el-card class="box-card" shadow="always">
                <template #header>
                    <div class="card-header">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <img :src="$store.state.user.avatar" class="user-avatar">&nbsp;
                                <span class="text">{{ $store.state.user.username }}</span>&nbsp;
                                <span class="text">您当前余额：￥{{ my_account_currency }} CNY</span>
                            </div>
                            <div>
                                <el-checkbox v-model="protocal_checked">
                                    <span @click="protocal_visible = true">充值协议</span>
                                </el-checkbox>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="row align-items-center">
                    <div class="col-2">
                        <span class="text">充值金额</span>
                    </div>
                    <div class="col-10">
                        <el-radio-group v-model="amount" size="medium">
                            <el-radio-button :label="5">￥5 CNY</el-radio-button>
                            <el-radio-button :label="20">￥20 CNY</el-radio-button>
                            <el-radio-button :label="100">￥100 CNY</el-radio-button>
                            <el-radio-button :label="200">￥200 CNY</el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="row align-items-center mt-4">
                    <div class="col-2">
                        <span class="text">支付方式</span>
                    </div>
                    <div class="col-10">
                        <el-radio-group v-model="pay_type" size="large">
                            <el-radio :label="1" border>
                                <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/alipay.c4da24dd.png"
                                    class="pay_img">
                            </el-radio>
                            <el-radio :label="2" disabled border>
                                <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/wechatpay.cf77ab2e.png"
                                    class="pay_img">
                            </el-radio>
                            <el-radio :label="3" disabled border>
                                <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/paypal.3b9dad5f.png"
                                    class="pay_img">
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </el-card>
            <div class="d-flex justify-content-center">
                <el-tooltip :disabled="protocal_checked" content="请先查阅并同意“充值协议”" placement="top" effect="dark" :manual=true
                    :hide-after="1">
                    <el-button :disabled="!protocal_checked" class="mt-2 mb-2" type="primary"
                        style="width: 20%;color: white;" @click="pay">立即支付</el-button>
                </el-tooltip>
            </div>

            <div class="d-flex justify-content-evenly">
                <!--悔棋卡-->
                <el-card :body-style="{ padding: '0px' }">
                    <el-container style="padding: 0px;">
                        <el-main>
                            <el-tooltip class="item" effect="light" content="下错了随时悔棋" placement="right">
                                <img src="../../assets/images/icon_regret_card.png" />
                            </el-tooltip>
                        </el-main>
                        <el-footer style="padding: 0px; margin-top: -20px;">
                            <el-row>
                                <el-col :span="12" :offset="8">
                                    <span>悔棋道具</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12" :offset="8">
                                    <el-button text @click="regret_visible = true">购买</el-button>
                                </el-col>
                            </el-row>
                        </el-footer>
                    </el-container>
                </el-card>
                <!--形势判断卡-->
                <el-card :body-style="{ padding: '0px' }">
                    <el-container style="padding: 0px;">
                        <el-main>
                            <el-tooltip class="item" effect="light" content="随时计算盘面局面" placement="right">
                                <img src="../../assets/images/icon_area_card.png" />
                            </el-tooltip>
                        </el-main>
                        <el-footer style="padding: 0px; margin-top: -20px;">
                            <el-row>
                                <el-col :span="12" :offset="8">
                                    <span>领地道具</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12" :offset="8">
                                    <el-button text @click="score_visible = true">购买</el-button>
                                </el-col>
                            </el-row>
                        </el-footer>
                    </el-container>
                </el-card>
            </div>
        </template>
    </el-dialog>

    <!-- 购买悔棋模态框 -->
    <el-dialog v-model="regret_visible" title="悔棋卡购买" center width="30%" append-to-body :close-on-press-escape="false"
        :close-on-click-modal="false">
        <div class="d-flex justify-content-evenly align-items-center">
            <el-input-number v-model="regret_num" :min="10" :max="100" step="10" @change="handleRegretChange" />
            <span>{{ regret_count }} ¥</span>
        </div>
        <template #footer>
            <button type="button" class="btn btn-success btn-sm btn-do-op" @click="do_buy_regret">
                购买
            </button>
        </template>
    </el-dialog>

    <!-- 购买领地模态框 -->
    <el-dialog v-model="score_visible" title="领地卡购买" center width="30%" append-to-body :close-on-press-escape="false"
        :close-on-click-modal="false">
        <div class="d-flex justify-content-evenly align-items-center">
            <el-input-number v-model="score_num" :min="10" :max="100" step="10" @change="handleScoreChange" />
            <span>{{ score_count }} ¥</span>
        </div>
        <template #footer>
            <button type="button" class="btn btn-success btn-sm btn-do-op" @click="do_buy_score">
                购买
            </button>
        </template>
    </el-dialog>

    <!-- 购买领地模态框 -->
    <el-dialog v-model="protocal_visible" title="充值协议" center append-to-body :close-on-press-escape="false"
        :close-on-click-modal="false" :show-close="false">
        <div class="d-flex justify-content-evenly align-items-center">
            <div class="modal-center-component">
                本充值协议（以下简称“协议”）由以下双方（以下简称“用户”和“网站”）共同遵守，并规定了用户在网站上进行充值操作的相关条款和条件。在进行任何充值之前，请务必仔细阅读本协议，并确保您充分理解并同意所有内容。如果您不同意本协议的任何部分，请勿使用网站的充值服务。

                定义
                1.1. "用户"指的是访问和使用本网站的任何个人或实体。

                1.2. "网站"指的是提供充值服务的网站，包括其所有者、运营者和关联公司。

                1.3. "充值"是指用户在网站上为其账户或用户账户添加资金或货币的行为。

                充值流程
                2.1. 用户可以通过网站上提供的指定支付方式进行充值。

                2.2. 用户必须提供准确、完整的充值信息，包括但不限于充值金额和支付方式。

                2.3. 用户在进行充值前需确保自己有合法的权利使用所选支付方式。

                账户余额
                3.1. 用户的充值将会在成功处理后显示在其账户余额上。

                3.2. 用户的账户余额可用于网站上提供的各种服务和产品的支付。

                收费和费率
                4.1. 用户在进行充值时可能会被收取一定的费用或手续费，具体费率将根据网站的政策和用户所选择的支付方式而定。

                4.2. 网站有权根据业务需要随时更改收费标准和费率，并将在网站上公布任何更改。

                退款政策
                5.1. 用户一旦完成充值，通常情况下将不允许退款。然而，网站保留根据具体情况决定是否提供退款的权利。

                5.2. 如果用户认为存在错误充值或未经授权的充值行为，请立即联系网站客服，并提供相关证据以协助调查。

                安全性和责任
                6.1. 用户有责任妥善保管其充值相关信息，包括但不限于支付账户信息和密码，以防止未经授权的使用。

                6.2. 用户应确保其计算机或设备免受病毒、恶意软件和未经授权的访问的威胁，以保护其账户安全。

                6.3. 网站将采取合理的安全措施来保护用户的充值信息，但不能对任何未经授权的访问或数据泄露承担责任。

                终止
                7.1. 网站有权根据其自身政策随时终止或暂停用户的充值服务，而无需提前通知。

                7.2. 用户有权随时终止其账户的使用，但可能会受到一定的限制和条件。

                适用法律和争议解决
                8.1. 本协议受到适用法律的管辖，任何因本协议引起的争议应通过友好协商解决。

                8.2. 如果无法通过协商解决争议，双方同意将争议提交适用法院解决。

                其他条款
                9.1. 本协议构成用户和网站之间关于充值服务的完整协议，取代了任何先前的口头或书面协议。

                9.2. 网站保留根据业务需要随时修改本协议的权利，修改后的协议将在网站上公布，并自生效日起生效。

                请确保您已充分阅读并理解本充值协议的所有条款和条件。通过进行充值，您表示您已接受并同意遵守本协议的规定。如果您有任何疑问或需要进一步解释，建议您在进行充值之前与网站联系，以获得必要的支持和指导。
            </div>
        </div>
        <template #footer>
            <button type="button" class="btn btn-success btn-sm btn-do-op"
                @click="protocal_visible = false; protocal_checked = true">
                我已阅读
            </button>
        </template>
    </el-dialog>
</template>

<script>
import { GoBoard } from '@/assets/scripts/board/GoBoard';
import RoomUserList from '@/components/room/RoomUserList.vue';
import AISettings from '@/components/go/AISettingsAfterPlay.vue';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { onMounted, computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import router from '@/router';
import { onBeforeRouteLeave } from "vue-router";
import { ElMessageBox, ElMessage } from 'element-plus';
import { users_in_room } from '@/components/room/RoomUserList.vue';
import { ElLoading, ElNotification } from 'element-plus';
import { HomeFilled, Connection, Histogram, RefreshLeft, Flag } from '@element-plus/icons-vue'
import axios from 'axios';

export let request_draw_eb = ElMessageBox;
export let go_resign = ElMessageBox;
export let room_destroy = ElMessageBox;
export let room_info = reactive({
    data: [],
});
export default {
    components: {
        RoomUserList,
        Connection,
        Histogram,
        RefreshLeft,
        Flag,
        AISettings,
        HomeFilled,
    },

    setup() {
        const store = useStore();
        const route = useRouter();
        const roomId = route.currentRoute.value.params.roomId;

        let regret = computed(() => store.state.gogame.which != store.state.gogame.current);
        let has_engine = computed(() => store.state.gogame.opponent_userid == -1);
        let parent = ref(null);
        let canvas = ref(null);
        let show_lead = ref(false);
        let show_on_black = ref(true);

        let regret_num = ref(10);
        let score_num = ref(10);
        let regret_count = ref(parseFloat(0.01));
        let score_count = ref(parseFloat(0.01));
        const outerVisible = ref(false);
        const regret_visible = ref(false);
        const score_visible = ref(false);

        let my_account_currency = ref("");
        let amount = ref(5);
        let pay_type = ref(1);
        let protocal_checked = ref(false);
        let protocal_visible = ref(false);

        let handicap = ref(0);
        let size = ref(19);

        let b;

        onMounted(() => {
            let loading = ElLoading.service({ fullscreen: true });
            axios.post('/room/getInfo', {
                roomId: roomId,
            }).then(resp => {
                if (resp.code == 400) {
                    router.push({
                        name: '404',
                    })
                } else if (resp.code == 200) {
                    room_info.data = resp.data.info;
                    if (store.state.gogame.which == 1) {
                        room_info.data.regret_count = resp.data.info.black_regret_count;
                        room_info.data.judge_count = resp.data.info.black_judge_count;
                    } else if (store.state.gogame.which == 2) {
                        room_info.data.regret_count = resp.data.info.white_regret_count;
                        room_info.data.judge_count = resp.data.info.white_judge_count;
                    }
                    users_in_room.data = resp.data.users;
                    store.commit("updateBoard", resp.data.info.board_state);
                    store.commit("updateLastStep", {
                        last_x: resp.data.info.last_x,
                        last_y: resp.data.info.last_y
                    });
                    loading.close();
                    b = new GoBoard(canvas.value.getContext('2d'), parent.value, resp.data.info.size, store, roomId);
                }
            }).catch(error => {
                console.log(error);
            })
        })

        const click_resign = () => {
            go_resign.confirm('是否确定放弃？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true,
                type: 'warning'
            }).then(() => {
                store.state.gogame.socket.send(JSON.stringify({
                    event: "play",
                    x: -1,
                    y: -1,
                }));
            });
        }

        const request_draw = () => {
            store.state.gogame.socket.send(JSON.stringify({
                event: "request_draw",
                which: store.state.gogame.which,
            }));
            request_draw_eb.alert('等待对方回应', {
                confirmButtonText: '取消',
                type: 'info',
                center: true,
                showClose: false,
                callback: () => {
                    store.state.gogame.socket.send(JSON.stringify({
                        event: "request_cancel",
                        friend_id: store.state.gogame.opponent_userid,
                    }));
                }
            })
        }

        const request_regret = () => {
            if (room_info.data.regret_count <= 0) {
                axios.get("/user-account/info").then(resp => {
                    if (resp.code == 200) {
                        my_account_currency.value = resp.data.currency;
                        store.commit("updateCurrency", resp.data.currency);
                        outerVisible.value = true;
                    }
                })
            } else if (room_info.data.play_count >= 2) {
                store.state.gogame.socket.send(JSON.stringify({
                    event: "request_regret",
                    which: store.state.gogame.which,
                }));
                if (!has_engine.value) {
                    request_draw_eb.alert('等待对方回应', {
                        confirmButtonText: '取消',
                        type: 'info',
                        center: true,
                        showClose: false,
                        callback: () => {
                            store.state.gogame.socket.send(JSON.stringify({
                                event: "request_cancel",
                                friend_id: store.state.gogame.opponent_userid,
                            }));
                        }
                    });
                }
            }
        }

        const judge = () => {
            if (room_info.data.judge_count <= 0) {
                axios.get("/user-account/info").then(resp => {
                    if (resp.code == 200) {
                        my_account_currency.value = resp.data.currency;
                        store.commit("updateCurrency", resp.data.currency);
                        outerVisible.value = true;
                    }
                })
            }
            else if (show_lead.value) {
                show_lead.value = false;
                room_info.data.score_lead = "";
            } else {
                axios.get('/room/judge', {
                    params: {
                        room_id: roomId,
                        side: store.state.gogame.which,
                    }
                }).then(resp => {
                    if (resp.code == 200) {
                        room_info.data.judge_count--;
                        room_info.data.score_lead = resp.data;
                        if (String(resp.data).charAt(0) === '黑') show_on_black.value = true;
                        else if (String(resp.data).charAt(0) === '白') show_on_black.value = false;
                        show_lead.value = true;
                    } else {
                        ElMessage.warning("网络波动，请刷新重试");
                    }
                })
            }
        }

        const leave_room = (next) => {
            axios.get('/room/leave', {
                params: {
                    room_id: roomId,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    b.destory();
                    if (next != null) next();
                    else {
                        router.push({
                            name: 'playhall',
                        })
                    }
                }
            })
        }

        const do_buy_regret = () => {
            axios.post('/shopping/buy', {
                count: regret_num.value,
                type: 1,
                roomId: store.state.gogame.room_id,
                side: store.state.gogame.which,
            }).then(resp => {
                outerVisible.value = false;
                regret_visible.value = false;
                if (resp.code == 200) {
                    ElNotification({
                        message: '购买成功！',
                        showClose: false,
                        duration: 1500,
                        type: 'success',
                    });
                    store.commit("updateCurrency", resp.data);
                    room_info.data.regret_count = regret_num.value;
                }
                else {
                    ElNotification({
                        message: '余额不足，请先充值',
                        showClose: false,
                        duration: 1500,
                        type: 'error',
                    })
                }
            }).catch(() => {
                ElNotification({
                    message: '网络错误，购买失败',
                    showClose: false,
                    duration: 1500,
                    type: 'error',
                })
            })
        }

        const do_buy_score = () => {
            axios.post('/shopping/buy', {
                count: score_num.value,
                type: 2,
                roomId: store.state.gogame.room_id,
                side: store.state.gogame.which,
            }).then(resp => {
                outerVisible.value = false;
                score_visible.value = false;
                if (resp.code == 200) {
                    ElNotification({
                        message: '购买成功！',
                        showClose: false,
                        duration: 1500,
                        type: 'success',
                    });
                    store.commit("updateCurrency", resp.data);
                    room_info.data.judge_count = score_num.value;
                }
                else {
                    ElNotification({
                        message: '余额不足，请先充值',
                        showClose: false,
                        duration: 1500,
                        type: 'error',
                    })
                }
            }).catch(() => {
                ElNotification({
                    message: '网络错误，购买失败',
                    showClose: false,
                    duration: 1500,
                    type: 'error',
                })
            })
        }

        const handleRegretChange = (regret_num) => {
            regret_count.value = 0.01 * regret_num / 10;
        }

        const handleScoreChange = (score_num) => {
            score_count.value = 0.01 * score_num / 10;
        }

        const update_handicap = (_handicap) => {
            handicap.value = _handicap;
        }

        const update_size = (_size) => {
            size.value = _size;
        }

        onBeforeRouteLeave((to, from, next) => {
            if (store.state.gogame.which != 0) {
                ElMessageBox.confirm("您的棋局还未下完，是否认输？", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    showClose: false,
                    center: true,
                    type: "warning"
                }).then(() => {
                    store.state.gogame.socket.send(JSON.stringify({
                        event: "play",
                        x: -1,
                        y: -1,
                    }));
                })
                    .catch(() => {
                    });
            } else {
                leave_room(next);
            }
        })

        const pay = () => {
            axios.post("/alipay/pay", {
                rechargeType: amount.value,
            }).then(resp => {
                // 使用window.open()在新窗口中打开数据页面
                const newWindow = window.open('', '_blank');
                newWindow.document.write(resp.data);
            }).catch(err => {
                console.log(err);
            })
        }

        return {
            parent,
            canvas,
            roomId,
            regret,
            room_info,
            show_on_black,
            show_lead,
            has_engine,
            regret_num,
            score_num,
            regret_count,
            score_count,
            outerVisible,
            score_visible,
            regret_visible,
            amount,
            pay_type,
            my_account_currency,
            protocal_checked,
            protocal_visible,
            pay,
            request_draw,
            click_resign,
            leave_room,
            request_regret,
            judge,
            handleRegretChange,
            handleScoreChange,
            do_buy_score,
            do_buy_regret,
            update_handicap,
            update_size,
        }
    },

    watch: {
        $route() {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        }
    },
}

</script>

<style scoped>
.goboard {
    width: 50vw;
    height: 88vh;
    margin-top: 10vh;
}

canvas {
    margin-left: 8vw;
    display: inline-block;
    background-image: url('https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/board.90ff7d60.png');
    background-size: 70%;
    cursor: pointer;
    box-shadow: 3px 3px 3px #b9b9b9;
}

.play-info-base {
    width: 41vw;
    margin-left: 7vw;
}

.row {
    margin-top: 1vh;
}

.card {
    background-color: rgb(238, 237, 237);
    margin-top: 2vh;
}

.info-result {
    min-width: 50px;
}

div.user-avatar {
    text-align: center;
}

div.user-avatar>img {
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
}

div.username {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.info-board {
    background-color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    margin-top: 10vh;
}

.stone-display>img {
    width: 5vh;
    height: 5vh;
}

.action-button {
    max-width: 130px;
    /* 调整按钮的最大宽度 */
    min-width: 80px;
    /* 设置最小宽度 */
    white-space: nowrap;
    /* 防止按钮内文本换行 */
    overflow: hidden;
}

el-card {
    min-height: 100px;
    min-width: 100px;
}

.btn-do-op {
    background-color: rgb(82, 178, 130);
    border-color: aliceblue;
}

.btn-do-op:hover {
    background-color: rgb(52, 139, 97);
}

.text {
    font-size: 18px;
}

.box-card {
    text-align: center;
    align-items: center;
    width: 100%;
}

.user-avatar {
    width: 3vw;
    height: 3vw;
}

.pay_img {
    width: 5vw;
    height: 4vh;
}

.modal-center-component {
    display: flex;
    justify-content: center;
}

.player-avatar {
    width: 8vh;
    height: 8vh;
    border-radius: 10%;
}

.center-layout {
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: center;
}

.image-container {
    position: relative;
    display: inline-block;
}

.small-image-right {
    position: absolute;
    bottom: 5px;
    right: 2px;
    width: 15px;
    /* 调整小图片的大小 */
    height: auto;
    z-index: 1;
    /* 确保小图片在大图片之上 */
}

.small-image-left {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 15px;
    /* 调整小图片的大小 */
    height: auto;
    z-index: 1;
    /* 确保小图片在大图片之上 */
}


.play-board {
    border: 2px solid #d6d1d1;
    background-color: rgb(230, 231, 234);
    width: auto;
    margin-top: 2vh;
    box-shadow: 3px 3px 3px #b9b9b9;
}</style>