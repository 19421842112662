<template>
    <ContentBase>
        <el-row class="align-items-center">
            <el-col :span="6"></el-col>
            <el-col :span="12">
                <div class="title">
                    <span>练习 - {{ collectionName }}</span>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="d-flex flex-row-reverse align-items-center">
                    <el-button plain @click="back_to_puzzle_index">返回
                        <el-icon class="el-icon--right">
                            <Back />
                        </el-icon>
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <hr />
        <el-skeleton :loading="loading" :rows="8" animated></el-skeleton>

        <!-- 所有题目 -->
        <div class="grid-layout" v-if="!loading">
            <div :class="puzzle.pass ? 'grid-item-pass' : 'grid-item-npass'" v-for="(puzzle) in puzzles" :key="puzzle.id"
                @click="push_to_puzzle(puzzle.id)">
                <div class="image-container">
                    <img :src="`https://fcjznkj.oss-cn-qingdao.aliyuncs.com/puzzles/images/${puzzle.id}.png`"
                        class="large-image" alt="全力补充中.." />
                    <img src="@/assets/images/solved.png" class="small-image" alt="您已通过" title="你已通过这道题目"
                        v-show="puzzle.pass" />
                </div>
                <div class="grid-text">
                    <div class="text-line text-align-items">
                        <el-rate v-model="puzzle.rating" disabled text-color="#ff9900" size="small" />
                    </div>
                    <div class="text-line text-align-items">
                        <el-text :underline="false" class="puzzle-title mx-1"> {{ puzzle.id }}</el-text>
                        <el-text :type="getType(puzzle.gradeLevel)" class="mx-1">
                            {{ puzzle.grade }}
                        </el-text>
                    </div>
                    <div class="text-line puzzle-passrate text-align-items">通过率: {{ puzzle.passRate }}</div>
                </div>
            </div>
        </div>

        <!-- puzzle分页器 -->
        <div class="block" style="margin-top:10px; float: right">
            <el-pagination @current-change="handle_current_puzzle_change" :current-page="current_puzzle_page" background
                hide-on-single-page :page-size="page_size" layout="prev, pager, next, jumper" :total="total_puzzle">
            </el-pagination>
        </div>
    </ContentBase>
</template>

<script>
import ContentBase from '@/components/base/ContentBase.vue';
import { Back } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
import axios from 'axios';

export default {
    components: {
        ContentBase,
        Back
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        let puzzles = ref([]);
        let current_puzzle_page = ref(1);
        let total_puzzle = ref(0);
        let page_size = 20; // 每页的数据条数
        const collectionName = route.params.collectionName;
        const loading = ref(true);

        onMounted(() => {
            axios.get('/puzzle/grade/list', {
                params: {
                    pageNum: 0,
                    pageSize: page_size,
                    grade: collectionName,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    total_puzzle.value = resp.data.totalElements;
                    puzzles.value = resp.data.dataList;
                    loading.value = false;
                }
            })
        })

        const handle_current_puzzle_change = (val) => {
            loading.value = true;
            axios.get('/puzzle/grade/list', {
                params: {
                    pageNum: val - 1,
                    pageSize: page_size,
                    grade: collectionName,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    current_puzzle_page.value = val;
                    total_puzzle.value = resp.data.totalElements;
                    puzzles.value = resp.data.dataList;
                    loading.value = false;
                }
            })
        }

        const back_to_puzzle_index = () => {
            router.push({
                name: 'puzzle_grade_practice'
            })
        }

        const push_to_puzzle = (puzzle_id) => {
            router.push({
                name: "puzzle_details",
                params: {
                    puzzleId: puzzle_id,
                }
            })
        }

        const getType = (level) => {
            if (level <= 18) return "success";
            else if (level <= 22) return "warning";
            else return "danger";
        }

        return {
            loading,
            total_puzzle,
            puzzles,
            current_puzzle_page,
            page_size,
            collectionName,
            handle_current_puzzle_change,
            back_to_puzzle_index,
            push_to_puzzle,
            getType
        }
    }
}
</script>

<style scoped>
.title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

a {
    text-decoration: none;
}

router-link-active {
    text-decoration: none;
}

.puzzle-title {
    font-size: small;
    cursor: pointer;
    color: rgb(40, 116, 159);
    margin: 5px auto;
}

.puzzle-title:hover {
    text-decoration: underline;
}

.puzzle-passrate {
    margin: 5px auto;
    text-align: center;
    font-size: small;
    color: rgb(88, 86, 86, 0.7);
}

.puzzle-title {
    font-size: small;
    cursor: pointer;
    color: rgb(40, 116, 159);
    margin: 5px auto;
}

.grid-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 10px;
    /* 间距 */
}

.grid-item-pass {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: rgba(209, 227, 240, 0.5);
    border: 1px solid #dddddd;
    cursor: pointer;
}

.grid-item-npass {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #dddddd;
    cursor: pointer;
}

.large-image {
    width: 130px;
    /* 调整图片大小 */
    height: auto;
}

.small-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    /* 调整小图片的大小 */
    height: auto;
    z-index: 1;
    /* 确保小图片在大图片之上 */
}

.grid-text {
    text-align: center;
}

.text-line {
    margin: 0 auto;
}

.image-container {
    position: relative;
    display: inline-block;
}
</style>