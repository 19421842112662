<template>
    <el-skeleton :loading="loading" :rows="6" animated></el-skeleton>
    <el-empty description="这里没有棋谱" v-if="show_blank == true">
        <el-button type="success" @click="nav_to_play" v-if="is_me">去下棋！</el-button>
    </el-empty>
    <div v-else>
        <!-- 表格 -->
        <el-table :data="records" style="width: 100%; text-align: center;" align="center" v-if="!loading"
            :row-class-name="tableRowClassName">
            <el-table-column label="黑方" align="center">
                <template v-slot="{ row }">
                    <img :src="row.blackAvatar" alt="" class="record-user-avatar" @click="nav_to_user_space(row.blackId)">
                    &nbsp;
                    <span class="record-user-username"> {{ row.blackUsername }}</span>
                </template>
            </el-table-column>
            <el-table-column label="棋力" align="center" width="80">
                <template v-slot="{ row }">
                    <el-tag :color="LEVEL[row.blackLevel].color" round>
                        <span style="color: white;">
                            {{ row.blackLevel }}
                        </span>
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="白方" align="center">
                <template v-slot="{ row }">
                    <img :src="row.whiteAvatar" alt="" class="record-user-avatar" @click="nav_to_user_space(row.whiteId)">
                    &nbsp;
                    <span class="record-user-username"> {{ row.whiteUsername }}</span>
                </template>
            </el-table-column>
            <el-table-column label="棋力" align="center" width="80">
                <template v-slot="{ row }">
                    <el-tag :color="LEVEL[row.whiteLevel].color" round>
                        <span style="color: white;">
                            {{ row.whiteLevel }}
                        </span>
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="对局结果" align="center">
                <template v-slot="{ row }">
                    <el-tag v-bind:color="row.result === '黑中盘胜' ? '#1a1a1a' : '#ffffff'">{{ row.result }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="对局时间" align="center">
                <template v-slot="{ row }">
                    <el-tag color="#cceeff">{{ row.createTime }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button class="op" size="small" plain @click="open_record(row.id)">复盘</el-button>
                    <el-button class="op" size="small" plain @click="share_record(row.id)">分享</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block" style="margin-top:10px; float: right">
            <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
                background hide-on-single-page layout="prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
        <ElDialog v-model="shareDialogVisible" title="分享棋谱">
            <ElForm label-width="100px" :model="shareForm">
                <ElFormItem label="好友" prop="shareFriendId" :required="true" error="请选择好友">
                    <ElSelect v-model="shareForm.shareFriendId" placeholder="请选择好友">
                        <ElOption v-for="item in friends" :key="item.id" :label="item.username" :value="item.id"></ElOption>
                    </ElSelect>
                </ElFormItem>
                <ElFormItem label="留言" prop="shareMessage">
                    <ElInput v-model="shareForm.shareMessage"></ElInput>
                </ElFormItem>

                <el-form-item>
                    <el-button type="primary" @click="shareRecordToFriend()">确认分享</el-button>
                    <el-button @click="shareDialogVisible = false">取消</el-button>
                </el-form-item>
            </ElForm>
        </ElDialog>
    </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import { useRouter } from 'vue-router';
import router from '@/router';
import { ElMessage } from "element-plus";
import axios from 'axios';

export default {
    props: {
        userId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const LEVEL = window.LEVEL;
        const store = useStore();
        const route = useRouter();
        const loading = ref(true);
        const shareDialogVisible = ref(false);
        const sharePuzzleId = ref('');
        const friends = ref([]);
        const shareForm = ref({
            shareFriendId: null,
            shareMessage: null
        })
        const is_me = computed(() => props.userId == store.state.user.id);
        let pageSize = 10; // 每页的数据条数

        let records = ref([]);
        let pages = ref([]);
        let show_blank = ref(false);
        let currentPage = ref(1);
        let total = ref(0);

        onMounted(() => {
            axios.get('/record/getMy', {
                params: {
                    pageNum: 0,
                    pageSize: pageSize,
                    userId: props.userId,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    records.value = resp.data.records;
                    total.value = resp.data.records_count;
                    if (total.value == 0) show_blank.value = true;
                    loading.value = false;
                } else if (resp.code == 400) {
                    ElMessage.warning(resp.data);
                }
            })

            axios.get('/friend/get').then(resp => {
                if (resp.code === 200) {
                    friends.value = resp.data;
                }
            })
        })

        //当前页改变时触发 跳转其他页
        const handleCurrentChange = (val) => {
            loading.value = true;
            axios.get('/record/getMy', {
                params: {
                    pageNum: val - 1,
                    pageSize: pageSize,
                    userId: props.userId,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    currentPage.value = val;
                    records.value = resp.data.records;
                    total.value = resp.data.records_count;
                    if (total.value == 0) show_blank.value = true;
                    loading.value = false;
                }
            })
        }

        const open_record = recordId => {
            route.push({
                name: 'record_review',
                params: {
                    recordId
                },
            })
        }

        const nav_to_user_space = (user_id) => {
            route.push({
                name: "user_index",
                params: {
                    userId: user_id
                }
            })
        }

        const nav_to_play = () => {
            router.replace({ name: 'goplay' });
        }

        const tableRowClassName = ({ row }) => {
            const my_id = store.state.user.id;
            if (my_id == row.blackId && row.result === '黑中盘胜' || my_id == row.whiteId && row.result === '白中盘胜') {
                return 'win-row';
            }
        }

        return {
            pages,
            records,
            loading,
            show_blank,
            total,
            currentPage,
            pageSize,
            LEVEL,
            is_me,
            shareDialogVisible,
            sharePuzzleId,
            friends,
            shareForm,
            tableRowClassName,
            open_record,
            nav_to_user_space,
            nav_to_play,
            handleCurrentChange,
        }
    },
    methods: {
        share_record(recordId) {
            this.sharePuzzleId = recordId
            this.shareForm.shareFriendId = null;
            this.shareDialogVisible = true;
        },
        shareRecordToFriend() {
            if (this.shareForm.shareFriendId === null) {
                ElMessage.error("请选择所分享的好友")
                return
            }
            axios.post('/record/share', {
                toUid: this.shareForm.shareFriendId,
                recordId: this.sharePuzzleId,
                message: this.shareForm.shareMessage
            }).then(resp => {
                if (resp.code === 200) {
                    ElMessage.info("分享成功")
                    this.shareDialogVisible = false
                } else {
                    ElMessage.error(resp.data)
                }
            }).catch(error => {
                console.log(error)
                ElMessage.error("分享失败，请稍后重试")
            })
        }
    }
}
</script>

<style scoped>
img.record-user-avatar {
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;
}

.op {
    box-shadow: 2px 2px 2px #b9b9b9;
}

.record-user-username {
    font-weight: 500;
}

.el-table>>>.win-row {
    background: #f0f9eb;
}
</style>