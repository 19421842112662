<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card">
                    <div class="card-body">
                        <UserInfo :userId="userId" />
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card">
                    <div class="card-body">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item tab-index" role="presentation">
                                <button class="nav-link tab-btn" id="data-tab" data-bs-toggle="tab"
                                    data-bs-target="#data-tab-pane" type="button" role="tab">
                                    <span style="font-size: large;">数据</span>
                                </button>
                            </li>
                            <li class="nav-item tab-index" role="presentation">
                                <button class="nav-link tab-btn" id="post-tab" data-bs-toggle="tab"
                                    data-bs-target="#post-tab-pane" type="button" role="tab">
                                    <span style="font-size: large;">新鲜事</span>
                                </button>
                            </li>
                            <li class="nav-item tab-index" role="presentation">
                                <button class="nav-link tab-btn" id="record-tab" data-bs-toggle="tab"
                                    data-bs-target="#record-tab-pane" type="button" role="tab">
                                    <span style="font-size: large;">棋谱</span>
                                </button>
                            </li>
                            <li class="nav-item tab-index" role="presentation">
                                <button class="nav-link tab-btn" id="board-tab" data-bs-toggle="tab"
                                    data-bs-target="#board-tab-pane" type="button" role="tab">
                                    <span style="font-size: large;">棋盘</span>
                                </button>
                            </li>
                            <li class="nav-item tab-index">
                                <router-link :to="{ name: 'user_notification' }" class="router-link-exact-active">
                                    <button class="nav-link tab-btn" id="notification-tab" type="button">
                                        <span style="font-size: large;">通知</span>
                                    </button>
                                </router-link>
                            </li>
                            <li class="nav-item tab-index" role="presentation">
                                <button class="nav-link tab-btn active" id="payment-tab" data-bs-toggle="tab"
                                    data-bs-target="#payment-tab-pane" type="button" role="tab">
                                    <span style="font-size: large;">充值</span>
                                </button>
                            </li>
                            <li class="nav-item tab-index">
                                <router-link :to="{ name: 'user_purchase_record' }" class="router-link-exact-active">
                                    <button class="nav-link tab-btn" id="payment-tab" type="button">
                                        <span style="font-size: large;">购买</span>
                                    </button>
                                </router-link>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade" id="data-tab-pane" role="tabpanel" tabindex="0">
                                <RecordsData :userId="userId" />
                            </div>
                            <div class="tab-pane fade" id="post-tab-pane" role="tabpanel" tabindex="0">
                                <UserPosts :userId="userId" />
                            </div>
                            <div class="tab-pane fade" id="record-tab-pane" role="tabpanel" tabindex="0">
                                <MyRecords :userId="userId" />
                            </div>
                            <div class="tab-pane fade" id="board-tab-pane" role="tabpanel" tabindex="0">
                                <UserBoard :userId="userId" />
                            </div>
                            <div class="tab-pane fade show active" id="payment-tab-pane" role="tabpanel" tabindex="0">
                                <UserPayment />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserInfo from '@/components/user/UserInfo.vue';
import UserPosts from '@/components/user/UserPosts.vue';
import UserBoard from '@/components/user/UserBoard.vue';
import MyRecords from '@/components/record/MyRecords.vue';
import RecordsData from '@/components/user/UserRecordsData.vue';
import UserPayment from '@/components/user/UserPayment.vue';
import { useStore } from 'vuex';

export default {
    components: {
        UserInfo,
        UserPosts,
        UserBoard,
        MyRecords,
        RecordsData,
        UserPayment
    },
    setup() {
        const store = useStore();
        const userId = store.state.user.id;

        return {
            userId,
        }
    }
}
</script>

<style scoped>
.tab-index:hover {
    background-color: rgb(198, 194, 194, 0.3);
}

.tab-index {
    border-radius: 6%;
}

.tab-btn {
    color: rgb(109, 109, 238);
}

.card {
    margin-top: 10vh;
}

.router-link-exact-active {
    text-decoration: none;
}
</style>