<template>
    <div class="comment">
        <div class="row">
            <div class="col-2 comment-count">
                <span>{{ comments_count }} &nbsp;评论</span>
            </div>
        </div>
        <div class="row comment-area">
            <div class="col-1">
                <img :src="$store.state.user.avatar" class="aside-user-avatar">
            </div>
            <div class="col-11">
                <el-input type="textarea" :rows="2" v-model="comment_input" placeholder="在这里写评论...">
                </el-input>
            </div>
            <div class="row">
                <div class="d-flex flex-row-reverse send-comment">
                    <el-button size="small" type="info" id="send" plain @click="comment_a_post"
                        :disabled=disable_comment>提交评论</el-button>
                </div>
            </div>
        </div>
        <div v-for="comment in posts_comments" :key="comment.id" class="row comment-area">
            <el-divider />
            <div class="col-1">
                <img :src="comment.userAvatar" class="comment-user-avatar" @click="nav_to_user_space(comment.userId)">
            </div>
            <div class="col-11">
                <div class="row">
                    <div class="d-flex comment-username align-items-center">
                        <span style="color: rgb(56, 110, 197);">{{ comment.username }} &nbsp;&nbsp;&nbsp;</span>
                        <span>{{ comment.presentCommentTime }} &nbsp;&nbsp;&nbsp;</span>
                        <span class="click-reply" @click="click_comment(comment)"
                            v-if="!show_comment_post_set.has(comment.id)">回复</span>
                        <span class="click-reply" @click="click_comment(comment)" v-else>收起</span>
                        <span class="click-reply" @click="delete_comment(comment.id)"
                            v-if="comment.userId === $store.state.user.id">&nbsp;&nbsp;&nbsp;删除</span>
                    </div>
                </div>
                <div class="row">
                    <span class="d-flex comment-content align-items-center">
                        {{ comment.content }}
                    </span>
                </div>
                <div class="row d-flex">
                    <!-- 帖子回复区 -->
                    <ReplyCommentArea v-if="show_comment_post_set.has(comment.id)" :comment_id="comment.id"
                        :post_id="post_id" @get_post_comments="get_post_comments" :post_user_id="post_user_id"
                        @incre_comment_count="incre_comment_count" />
                    <!-- 帖子回复列表 -->
                </div>
                <div class="row d-flex">
                    <CommentReplyList :child_comments="comment.childComments" @get_post_comments="get_post_comments"
                        :post_id="post_id" :comment_id="comment.id" :post_user_id="post_user_id"
                        @incre_comment_count="incre_comment_count"></CommentReplyList>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReplyCommentArea from './ReplyCommentArea.vue';
import CommentReplyList from './CommentReplyList.vue';
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import router from '@/router';

export default {
    components: {
        ReplyCommentArea,
        CommentReplyList,
    },
    props: {
        post_id: {
            type: Number,
            required: true,
        },
        post_user_id: {
            type: Number,
            required: true,
        }
    },
    emits: ['get_post_comments', 'incre_root_page_comment_count'],

    setup(props, context) {
        let posts_comments = ref([]);
        let comments_count = ref(0);
        let comment_input = ref('');
        let show_comment_post_set = ref(new Set());
        let disable_comment = ref(false);

        onMounted(() => {
            get_post_comments();
        })

        const comment_a_post = () => {
            if (comment_input.value.trim() == '') {
                return;
            }
            disable_comment.value = true;
            axios.post('/post/comment/', {
                postId: props.post_id,
                postUserId: props.post_user_id,
                parentCommentId: -1,
                content: comment_input.value,
            }).then(resp => {
                if (resp.code == 200) {
                    comment_input.value = '';
                    get_post_comments();
                } else {
                    ElMessage.warning(resp.data);
                }
                disable_comment.value = false;
            })
        }

        const get_post_comments = () => {
            axios.get('/post/comment/get', {
                params: {
                    post_id: props.post_id,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    posts_comments.value = resp.data.comments;
                    comments_count.value = resp.data.commentsCount;
                }
            })
        }

        const delete_comment = (comment_id) => {
            ElMessageBox.confirm("您确定删除这条评论吗", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true,
            }).then(() => {
                axios.post('/post/comment/delete', {
                    commentId: comment_id,
                }).then(resp => {
                    if (resp.code == 200) {
                        ElMessage({
                            message: "删除成功",
                            duration: 1000,
                            type: "success"
                        });
                        get_post_comments();
                    } else {
                        ElMessage({
                            message: resp.data,
                            duration: 1000,
                            type: "error"
                        });
                    }
                })
            });
        }

        const nav_to_user_space = (user_id) => {
            router.push({
                name: "user_index",
                params: {
                    userId: user_id
                }
            })
        }

        const click_comment = (comment) => {
            const click_comment_comment_id = comment.id;
            if (show_comment_post_set.value.has(click_comment_comment_id)) show_comment_post_set.value.delete(click_comment_comment_id);
            else show_comment_post_set.value.add(click_comment_comment_id);
        }

        const incre_comment_count = () => {
            comments_count.value += 1;
            context.emit("incre_root_page_comment_count", props.post_id);
        }

        return {
            posts_comments,
            comments_count,
            comment_input,
            show_comment_post_set,
            disable_comment,
            get_post_comments,
            comment_a_post,
            nav_to_user_space,
            click_comment,
            incre_comment_count,
            delete_comment,
        }
    }
}
</script>

<style scoped>
.comment {
    border-radius: 10px;
    height: auto;
    width: auto;
}

.comment-count {
    font-weight: 400;
    font-size: 24px;
}

.send-comment {
    margin: 1vh -2vh 1vh auto;
}

.comment-area {
    margin: 1vh auto;
}

.aside-user-avatar {
    float: right;
    /* border: 0.5px solid rgba(170, 164, 164); */
    border-radius: 50%;
    padding: 0px 0px 0px 0px;
    margin: 0 auto;
    width: 5vh;
    height: 5vh;
}

.comment-user-avatar {
    float: right;
    /* border: 0.5px solid rgba(170, 164, 164); */
    border-radius: 50%;
    padding: 0px 0px 0px 0px;
    margin: 0 auto;
    width: 5vh;
    height: 5vh;
}

.comment-username {
    margin: 0 auto 0 -2vh;
    color: rgba(179, 171, 171, 0.788)
}

.comment-content {
    margin: 0 auto 1vh -2vh;
}

.click-reply:hover {
    color: rgb(56, 110, 197);
    cursor: pointer;
}
</style>