<template>
    <SmallContentBase>
        <div>
            <el-input v-model="user_search" placeholder="搜索好友" class="search-user" @focus="focus" @blur="blur"></el-input>
            <img src="@/assets/images/icon_search.png" class="img-search" @click="search_user" data-bs-toggle="modal"
                data-bs-target="#search" @keydown="keydown_search">

            <el-switch style="margin-left: 50px;" v-model="invitation_status" active-color="#13ce66"
                :active-text="invitation_text" @click="change_invitation_state">
            </el-switch>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="search" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header modal-center-component">
                        <h1 class="modal-title fs-5">查找用户</h1>
                    </div>
                    <div class="modal-body">
                        <el-table :data="users" v-if="users != null && users.length > 0">
                            <el-table-column label="用户" align="center">
                                <template v-slot="{ row }">
                                    <img :src="row.avatar" alt="用户头像" class="search-user-avatar"
                                        @click="nav_to_user_space(row.id)">
                                    &nbsp;
                                    <span style="font-weight: 400;"> {{ row.username }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="棋力" align="center">
                                <template v-slot="{ row }">
                                    <el-tag :color="LEVEL[row.level].color">
                                        <span style="color: white;">
                                            {{ row.level }}
                                        </span>
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template v-slot="{ row }">
                                    <el-button type="success" @click="follow(row)" size="small"
                                        v-if="row.username != $store.state.user.username && row.relation === 'stranger' || row.relation === 'follower'">关注</el-button>
                                    <el-button @click="follow(row)" size="small"
                                        v-else-if="row.username != $store.state.user.username && row.relation === 'following'">已关注</el-button>
                                    <el-button @click="follow(row)" size="small"
                                        v-else-if="row.username != $store.state.user.username && row.relation === 'friend'">互相关注</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <span v-else style="font-weight: 600; color: red;">没有匹配到任何用户</span>
                    </div>
                    <div class="modal-footer modal-center-component">
                        <el-button type="primary" data-bs-dismiss="modal" plain>确定</el-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="card-body">
                <el-tabs :stretch=true tab-position="top" @tab-click="reload" v-model="active_name">
                    <el-tab-pane label="我的好友" name="friends">
                        <component :is="load_tab('MyFriendPage')" :friends="friends"
                            :friends_empty_status="friends_empty_status" :friends_loading="friends_loading"
                            @change_reload_status="change_reload_status" />
                    </el-tab-pane>
                    <el-tab-pane label="我的关注" name="followings">
                        <component :is="load_tab('MyFollowingPage')" :followings="followings"
                            :followings_empty_status="followings_empty_status" :following_loading="following_loading"
                            @change_reload_status="change_reload_status" />
                    </el-tab-pane>
                    <el-tab-pane label="我的粉丝" name="followers">
                        <component :is="load_tab('MyFollowerPage')" :followers="followers"
                            :followers_empty_status="followers_empty_status" :follower_loading="follower_loading"
                            @change_reload_status="change_reload_status" />
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </SmallContentBase>
    <el-backtop :right="30" :bottom="30" />
</template>

<script>
import SmallContentBase from '@/components/base/SmallContentBase.vue';
import MyFriendPage from '@/components/friend/MyFriendPage.vue';
import MyFollowingPage from '@/components/friend/MyFollowingPage.vue';
import MyFollowerPage from '@/components/friend/MyFollowerPage.vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { ref, onMounted, defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import axios from 'axios';

export default {
    // 存放templates中用到的其他组件
    components: {
        SmallContentBase,
        MyFriendPage,
        MyFollowingPage,
        MyFollowerPage,
    },

    setup() {
        const store = useStore();
        const route = useRouter();
        const LEVEL = window.LEVEL;
        let m = null;
        const componentCache = {}; // 创建组件缓存对象
        let local_state = store.state.gogame.allow_invitation;
        if (local_state === undefined) {
            store.commit("updateInvitationState", true);
            local_state = true;
        }
        let active_name = ref('friends');
        let should_reload = ref(false);
        let local_friend_active_name = sessionStorage.getItem('friend_active_name');
        if (local_friend_active_name != null && local_friend_active_name !== undefined) active_name.value = local_friend_active_name;
        let user_search = ref('');
        let users = ref([]);
        let friends = ref([]);
        let friends_empty_status = ref(false);
        let friends_loading = ref(true);
        let following_loading = ref(true);
        let follower_loading = ref(true);
        let followers = ref([]);
        let followers_empty_status = ref(false);
        let followings = ref([]);
        let followings_empty_status = ref(false);

        let invitation_status = ref(local_state);
        let invitation_text = ref(local_state == true ? '允许邀请' : '拒绝邀请');

        onMounted(() => {
            m = new Modal('#search');
            get_friends();
            get_followers();
            get_followings();
        })

        const focus = () => {
            window.addEventListener('keydown', keydown_search);
        }

        const blur = () => {
            window.removeEventListener('keydown', keydown_search);
        }

        const get_friends = () => {
            friends_loading.value = true;
            axios.get('/friend/get').then(resp => {
                if (resp.code == 200) {
                    friends.value = resp.data;
                    if (friends.value.length == 0) friends_empty_status.value = true;
                    friends_loading.value = false;
                }
            })
        }

        const get_followings = () => {
            following_loading.value = true;
            axios.get('/friend/getFollowing').then(resp => {
                if (resp.code == 200) {
                    followings.value = resp.data;
                    if (followings.value.length == 0) followings_empty_status.value = true;
                    for (let user of followings.value) {
                        user.follow = true
                    }
                    following_loading.value = false;
                }
            })
        }

        const get_followers = () => {
            follower_loading.value = true;
            axios.get('/friend/getFollowers').then(resp => {
                if (resp.code == 200) {
                    followers.value = resp.data;
                    if (followers.value.length == 0) followers_empty_status.value = true;
                    follower_loading.value = false;
                }
            })
        }

        const search_user = () => {
            if (user_search.value.trim() === '' || user_search.value === '') {
                users.value = [];
                return;
            }
            axios.get('/user/get', {
                params: {
                    username: user_search.value,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    users.value = resp.data;
                }
            })
        }

        const reload = (tab) => {
            const t_name = tab.props.name;
            active_name.value = t_name;
            sessionStorage.setItem('friend_active_name', t_name);
            if (should_reload.value) {
                should_reload.value = false;
                if (t_name == 'friends') {
                    get_friends();
                } else if (t_name == 'followers') {
                    get_followers();
                }
                else if (t_name == 'followings') {
                    get_followings();
                }
            }
        }

        const change_reload_status = () => {
            should_reload.value = true;
        }

        const follow = (user) => {
            axios.post('/friend/follow', {
                targetUsername: user.username,
            }).then(_ => {
                if (user.relation === 'stranger') {
                    user.relation = 'following';
                } else if (user.relation === 'follower') {
                    user.relation = 'friend';
                } else if (user.relation === 'friend') {
                    user.relation = 'follower';
                } else if (user.relation === 'following') {
                    user.relation = 'stranger';
                }
                should_reload.value = true;
                console.log(_)
            })
        }

        const change_invitation_state = () => {
            axios.get('/game/changeState').then(resp => {
                if (resp.code == 200) {
                    if (invitation_status.value == true) {
                        invitation_text.value = "允许邀请";
                        store.commit("updateInvitationState", true);
                    }
                    else {
                        invitation_text.value = "拒绝邀请";
                        store.commit("updateInvitationState", false);
                    }
                }
            })
        }

        const nav_to_user_space = (user_id) => {
            Modal.getInstance("#search").hide();
            route.push({
                name: "user_index",
                params: {
                    userId: user_id
                }
            })
        }

        const keydown_search = (e) => {
            if (e.keyCode == 13 || e.keyCode == 100) {
                search_user();
                m.show();
            }
        }

        const load_tab = (tabName) => {
            if (componentCache[tabName]) {
                // 如果组件已经在缓存中，直接返回缓存的组件
                return componentCache[tabName];
            } else {
                componentCache[tabName] = defineAsyncComponent(() => import(`@/components/friend/${tabName}.vue`));
                return componentCache[tabName];
            }
        }

        return {
            invitation_status,
            invitation_text,
            user_search,
            users,
            LEVEL,
            friends,
            friends_empty_status,
            friends_loading,
            follower_loading,
            following_loading,
            followers,
            followers_empty_status,
            followings,
            followings_empty_status,
            active_name,
            change_invitation_state,
            search_user,
            follow,
            nav_to_user_space,
            keydown_search,
            reload,
            focus,
            blur,
            load_tab,
            change_reload_status
        }
    }
}
</script>

<style scoped>
.search-user-avatar {
    width: 4vh;
    height: 4vh;
    margin-right: 10px;
    cursor: pointer;
}

.search-user {
    width: 15vw;
}

.modal-center-component {
    display: flex;
    justify-content: center;
}

.img-search {
    width: 2vw;
    height: 2vw;
    margin-left: 10px;
    cursor: pointer;
}
</style>