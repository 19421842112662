<template>
    <ContentBase>
        <el-result icon="success" title="充值成功！">
        </el-result>
    </ContentBase>
</template>

<script>
import ContentBase from '@/components/base/ContentBase.vue';
export default {
    components: {
        ContentBase,
    }
}
</script>

<style scoped></style>