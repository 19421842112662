<template>
    <div class="d-flex justify-content-start">
        <div class="card goboard" v-show="loading" style="margin-left: 8vw;">
            <div class="card-body">
                <el-skeleton :rows="12" animated :loading="loading" />
            </div>
        </div>
        <div ref="parent" class="goboard" v-show="!loading">
            <canvas ref="canvas" tabindex="0"></canvas>
        </div>
        <div class="puzzle-detail-content">
            <div class="card puzzle-info">
                <div class="card-header">
                    <el-row>
                        <el-col :span="6"></el-col>
                        <el-col :span="12">
                            <div class="title">
                                <el-text tag="b">
                                    {{ type }}
                                </el-text>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="d-flex flex-row-reverse align-items-center">
                                <el-button plain size="small" @click="exit_page">退出
                                    <el-icon class="el-icon--right">
                                        <Close />
                                    </el-icon>
                                </el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="card-body">
                    <el-descriptions direction="horizontal" border :column="2" size="large">
                        <el-descriptions-item label="难度" :span="3" align="center">
                            <div class="d-flex justify-content-center">
                                <el-tag plain effect="light" style="color: #79bbff;">
                                    {{ grade }}
                                </el-tag>
                                <el-tag :type="getType(gradeLevel)" effect="light" class="mx-2" v-if="gradeLevel <= 15">
                                    简单
                                </el-tag>
                                <el-tag :type="getType(gradeLevel)" effect="light" class="mx-2"
                                    v-else-if="gradeLevel <= 19">
                                    中等
                                </el-tag>
                                <el-tag :type="getType(gradeLevel)" effect="light" class="mx-2" v-else>
                                    困难
                                </el-tag>
                            </div>
                        </el-descriptions-item>
                        <el-descriptions-item label="评分" :span="3" align="center">
                            <el-rate v-model="value2" :colors="colors" style="margin-top: -0.7vh;">
                            </el-rate>
                        </el-descriptions-item>
                        <el-descriptions-item label="总通过数" :span="3" width="100" align="center">
                            <span>
                                {{ passCount }}
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="总尝试数" :span="3" width="100" align="center">
                            <span>
                                {{ attemptCount }}
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="提示" :span="3" align="center">
                            <el-text style="text-align: center;" v-if="description != ''" tag="b">
                                {{ description }}
                            </el-text>
                            <el-text v-else tag="b">
                                {{ play_text }}
                            </el-text>
                        </el-descriptions-item>
                        <el-descriptions-item label="操作" :span="3" align="center">
                            <el-button type="success" @click="reset" class="operation-button" plain>
                                复位
                                <el-icon class="el-icon--right">
                                    <Connection />
                                </el-icon>
                            </el-button>
                            <el-button @click="previous_puzzle" class="operation-button" plain>
                                <el-icon class="el-icon--left">
                                    <Back />
                                </el-icon>
                                上一题
                            </el-button>
                            <el-button @click="next_puzzle" class="operation-button" plain>
                                下一题
                                <el-icon class="el-icon--right">
                                    <Right />
                                </el-icon>
                            </el-button>
                            <el-button @click="auto_switch = !auto_switch" class="operation-button" plain v-if="auto_switch"
                                type="success">
                                自动切题
                                <el-icon class="el-icon--right">
                                    <Switch />
                                </el-icon>
                            </el-button>
                            <el-button @click="auto_switch = !auto_switch" class="operation-button" plain v-else
                                type="info">
                                手动切题
                                <el-icon class="el-icon--right">
                                    <Switch />
                                </el-icon>
                            </el-button>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Right, Back, Connection, Switch, Close } from '@element-plus/icons-vue'
import { useRoute, useRouter } from 'vue-router';
import { DynamicSizeBoard } from '@/assets/scripts/board/DynamicSizeBoard';
import { computed, ref } from 'vue';
import { onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import axios from 'axios';

export default {
    components: {
        Right,
        Back,
        Connection,
        Switch,
        Close
    },

    props: {
        id: {
            required: false,
            type: Number
        }
    },

    setup(props) {
        const route = useRoute();
        const router = useRouter();

        const puzzleId = route.params.puzzleId;
        const next_puzzle_id = Number(puzzleId) + 1;
        const previous_puzzle_id = Number(puzzleId) - 1;
        let loading = ref(true);
        let parent = ref(null);
        let canvas = ref(null);
        console.log(props.id)

        let description = ref('');
        let grade = ref('');
        let gradeLevel = ref(0);
        let size = ref(0);
        let type = ref('');
        let attemptCount = ref(0);
        let passCount = ref(0);
        let is_black = ref(true);
        let play_text = computed(() => is_black.value == true ? '黑先走' : '白先走');
        let initial_stones = [];
        let move_tree = {};
        let auto_switch = ref(true);

        onMounted(() => {
            axios.get('/puzzle/choose', {
                params: {
                    puzzle_id: puzzleId,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    const data = resp.data;
                    let b = data.initialBlackState;
                    let w = data.initialWhiteState;
                    is_black.value = data.initialPlayer === 1;
                    description.value = data.description;
                    grade.value = data.grade;
                    gradeLevel.value = data.gradeLevel;
                    type.value = data.type;
                    attemptCount.value = data.attemptCount;
                    passCount.value = data.passCount;
                    move_tree = data.moveTree;
                    size.value = data.size;
                    for (let i = 0; i < b.length - 1; i += 2) {
                        initial_stones.push({
                            x: b[i + 1].toUpperCase().charCodeAt() - 64,
                            y: b[i].toUpperCase().charCodeAt() - 64,
                            side: 1,
                        })
                    }
                    for (let i = 0; i < w.length - 1; i += 2) {
                        initial_stones.push({
                            x: w[i + 1].toUpperCase().charCodeAt() - 64,
                            y: w[i].toUpperCase().charCodeAt() - 64,
                            side: 2,
                        })
                    }
                    b = new DynamicSizeBoard(canvas.value.getContext('2d'), parent.value, puzzleId, size.value, size.value, initial_stones, move_tree
                        , is_black.value, gradeLevel.value, ElMessage, next_puzzle, reset);
                    loading.value = false;
                } else if (resp.code == 400) {
                    ElMessage.warning(resp.data);
                } else if (resp.code == 404) {
                    router.push({
                        name: '404',
                    });
                }
            })
        })

        const reset = () => {
            new DynamicSizeBoard(canvas.value.getContext('2d'), parent.value, puzzleId, size.value, size.value, initial_stones, move_tree,
                is_black.value, gradeLevel.value, ElMessage, next_puzzle, reset);
        }

        const next_puzzle = () => {
            if (!auto_switch.value) return;
            if (puzzleId >= 23067) {
                router.replace({
                    name: "puzzle_details",
                    params: {
                        puzzleId: 5344,
                    }
                })
            } else {
                router.replace({
                    name: "puzzle_details",
                    params: {
                        puzzleId: next_puzzle_id,
                    }
                })
            }
        }

        const previous_puzzle = () => {
            if (puzzleId <= 5344) {
                router.replace({
                    name: "puzzle_details",
                    params: {
                        puzzleId: 23067,
                    }
                })
            }
            else {
                router.replace({
                    name: "puzzle_details",
                    params: {
                        puzzleId: previous_puzzle_id,
                    }
                })
            }
        }

        const getType = (level) => {
            if (level <= 18) return "success";
            else if (level <= 22) return "warning";
            else return "danger";
        }

        const exit_page = () => {
            router.go(-1);
        }

        return {
            puzzleId,
            parent,
            canvas,
            move_tree,
            loading,
            description,
            type,
            grade,
            play_text,
            attemptCount,
            passCount,
            gradeLevel,
            auto_switch,
            reset,
            next_puzzle,
            previous_puzzle,
            getType,
            exit_page,
        }
    },

    data() {
        return {
            value2: null,
            colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
        }
    },
    watch: {
        $route() {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        }
    }
}
</script>

<style scoped>
.title {
    text-align: center;
}

.goboard {
    width: 50vw;
    height: 88vh;
    margin-top: 10vh;
}

canvas {
    margin-left: 8vw;
    display: inline-block;
    background-image: url('https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/board.90ff7d60.png');
    background-size: 70%;
    cursor: pointer;
    box-shadow: 3px 3px 3px #b9b9b9;
}

.puzzle-detail-content {
    width: 40vw;
    margin-left: 8vw;
}

.operation-button {
    width: auto;
    height: auto;
}

.puzzle-info {
    background-color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    margin-top: 10vh;
}
</style>