<template>
    <ContentBase>
        <div style="text-align: center;">
            <el-col>
                <el-result icon="error" title="404" subTitle="您所要找的页面不存在">
                </el-result>
            </el-col>
        </div>
    </ContentBase>
</template>

<script>
import ContentBase from '@/components/base/ContentBase';

export default {
    // 存放templates中用到的其他组件
    components: {
        ContentBase
    }
}
</script>

<style scoped>
</style>