<template>
    <SmallContentBase>
        <el-tabs :stretch=true tab-position="top" @tab-change="tab_change" v-model="active_name">
            <el-tab-pane label="讨论" name="posts">
                <component :is="load_tab('CommunityPosts')" />
            </el-tab-pane>
            <el-tab-pane label="关注" name="followings">
                <component :is="load_tab('FollowingUserPosts')" />
            </el-tab-pane>
            <el-tab-pane label="棋友" name="users">
                <component :is="load_tab('ShowAllUsersPage')" />
            </el-tab-pane>
        </el-tabs>
    </SmallContentBase>
    <el-backtop :right="30" :bottom="30"/>
</template>

<script>
import SmallContentBase from '@/components/base/SmallContentBase.vue';
import { ref, defineAsyncComponent } from 'vue';

export default {
    components: {
        SmallContentBase,
    },

    setup() {
        let active_name = ref('users');
        let componentCache = {}; // 用于缓存已加载的组件

        let local_community_active_name = sessionStorage.getItem('community_active_name');
        if (local_community_active_name != null && local_community_active_name !== undefined) active_name.value = local_community_active_name;

        const tab_change = (tab_name) => {
            sessionStorage.setItem('community_active_name', tab_name);
            active_name.value = tab_name;
        }

        const load_tab = (tabName) => {
            if (componentCache[tabName]) {
                // 如果组件已经加载过，直接返回缓存的组件
                return componentCache[tabName];
            } else {
                // 否则异步加载组件
                const asyncComponent = import(`../../components/community/${tabName}.vue`);
                componentCache[tabName] = defineAsyncComponent(() => asyncComponent);
                return componentCache[tabName];
            }
        }

        return {
            active_name,
            load_tab,
            tab_change,
        }
    }
}
</script>

<style scoped></style>