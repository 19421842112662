<template>
    <el-row class="align-items-center">
        <el-col :span="6"></el-col>
        <el-col :span="12">
            <div class="title">
                <span>天梯闯关</span>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="d-flex flex-row-reverse align-items-center">
                <el-button plain @click="back_to_puzzle_index">返回
                    <el-icon class="el-icon--right">
                        <Back />
                    </el-icon>
                </el-button>
            </div>
        </el-col>
    </el-row>
    <hr />
    <div class="card-container">
        <el-card v-for="(ladder_enum, index) in ladder_enums" :key="index" class="custom-card" @click="show_ladder_puzzles(ladder_enum.code)">
            <el-row class="align-items-center justify-content-center" style="justify-content: center;">
                <el-col :span="14">
                    <p class="text">{{ ladder_enum.subject }}</p>
                </el-col>
            </el-row>
            <el-row class="align-items-center justify-content-center" style="justify-content: center;">
                <el-col :span="14">
                    <p class="text-level">{{ ladder_enum.level }}</p>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { Back } from '@element-plus/icons-vue';
import { onMounted, ref } from 'vue';

export default {
    components: {
        Back
    },

    setup() {
        const router = useRouter();
        let ladder_enums = ref([]);

        onMounted(() => {
            axios.get('/puzzle/ladder/enum').then(resp => {
                if (resp.code == 200) {
                    ladder_enums.value = resp.data;
                }
            })
        })

        const show_ladder_puzzles = (ladder_code) => {
            router.push({
                name: 'puzzle_ladder_level_practice',
                params: {
                    ladder_code: ladder_code
                }
            })
        }

        const back_to_puzzle_index = () => {
            router.push({
                name: 'public_puzzles'
            })
        }

        return {
            ladder_enums,
            show_ladder_puzzles,
            back_to_puzzle_index,
        }
    }
}
</script>

<style scoped>
.title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* 让卡片在容器中居中显示 */
    align-items: flex-start;
    margin: 0 -10px;
    /* 调整卡片之间的间距 */
}

.custom-card {
    width: 200px;
    height: 80px;
    margin: 15px;
    cursor: pointer;
    background-color: rgb(235, 241, 243);
}

.text {
    margin: 0 auto;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
}

.text-level {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 500;
    color: rgb(144, 140, 140);
    white-space: nowrap;
}
</style>