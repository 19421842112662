<template>
    <ContentBase>
        <PuzzleTypeView>

        </PuzzleTypeView>
    </ContentBase>
</template>

<script>
import PuzzleTypeView from '@/components/puzzle/PuzzleTypeView.vue';
import ContentBase from '@/components/base/ContentBase.vue';

export default {
    components: {
        ContentBase,
        PuzzleTypeView,
    }
}
</script>

<style scoped></style>