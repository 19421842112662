<template>
    <el-empty description="这里空空如也" v-if="show_blank == true" />
    <el-skeleton :loading="loading" :rows="6" animated style="margin-top: 20px;"></el-skeleton>
    <el-table :data="records" v-if="!show_blank && !loading" style="text-align:center;margin-top: 10px;" align="center"
        stripe>
        <el-table-column label="订单号" align="center" width="220">
            <template v-slot="{ row }">
                <span>{{ row.outTradeNo }}</span>
            </template>
        </el-table-column>
        <el-table-column label="产品描述" align="center" min-width="100">
            <template v-slot="{ row }">
                <span>{{ row.subject }}</span>
            </template>
        </el-table-column>
        <el-table-column label="金额" align="center">
            <template v-slot="{ row }">
                <span>¥ {{ row.totalAmount }}</span>
            </template>
        </el-table-column>
        <el-table-column label="支付类型" align="center">
            <template v-slot="{ row }">
                <span>{{ row.payType }}</span>
            </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
            <template v-slot="{ row }">
                <span style="color: green;">{{ row.tradeStatus }}</span>
            </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" width="180" style="min-width: 50px;">
            <template v-slot="{ row }">
                <el-tag color="#cceeff">{{ row.createTime }}</el-tag>
            </template>
        </el-table-column>
    </el-table>

    <!-- 列表分页器 -->
    <div class="block" style="margin-top:10px; float: right">
        <el-pagination @current-change="handle_current_change" :current-page="current_page" :page-size="page_size"
            background hide-on-single-page @size-change="handle_size_change" layout="prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import axios from 'axios';
export default {

    setup() {
        let records = ref([]);
        let current_page = ref(1);
        let total = ref(0);
        let page_size = ref(12);
        let show_blank = ref(false);
        let loading = ref(true);

        onMounted(() => {
            pull_all_payment_record();
        })

        const pull_all_payment_record = () => {
            loading.value = true;
            axios.get('/recharge/record/page', {
                params: {
                    pageNum: 0,
                    pageSize: page_size.value,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    records.value = resp.data.list;
                    total.value = resp.data.totalElements;
                    if (resp.data.totalElements == 0) show_blank.value = true;
                    loading.value = false;
                }
            })
        }

        //当前页改变时触发 跳转其他页
        const handle_current_change = (val) => {
            loading.value = true;
            axios.get('/recharge/record/page', {
                params: {
                    pageNum: val - 1,
                    pageSize: page_size.value,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    current_page.value = val;
                    total.value = resp.data.totalElements;
                    records.value = resp.data.list;
                    loading.value = false;
                }
            })
        }

        const handle_size_change = (val) => {
            page_size.value = val;
            pull_all_notifications();
        }

        return {
            current_page,
            page_size,
            total,
            records,
            loading,
            show_blank,
            handle_size_change,
            handle_current_change
        }
    }
}
</script>

<style scoped></style>