<template>
    <div class="container">
        <div class="card" style="box-shadow:  2px 2px 5px rgb(213,213,213)">
            <div class="card-body">
                <el-tabs :stretch=true tab-position="top">
                    <el-tab-pane label="短信登陆">
                        <div class="d-flex flex-column mb-auto">
                            <div class="p-2">
                                <div class="row align-items-center">
                                    <div class="col-md-8">
                                        <div class="form-floating">
                                            <input v-model="phone_number_login" class="form-control" id="phone_number_login"
                                                autocomplete="tel">
                                            <label for="phone_number_login">手机号</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="row align-items-center">
                                    <div class="col-md-8">
                                        <div class="form-floating">
                                            <input v-model="verification_code_login" class="form-control"
                                                id="verification_code_login">
                                            <label for="verification_code_login">验证码</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="d-flex flex-column justify-content-between">
                                            <button class="btn btn-primary" @click="send_login_verification_code"
                                                style="background-color: rgb(110, 165, 201); border: 0px;"
                                                :disabled="total_time_login != 60">{{ countdown_content_login }}</button>
                                            <span style="color:green">{{ send_status_login }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="error_message">{{ error_message_code_login }}</div>
                                <div class="col-auto">
                                    <el-button class="btn-verification-login" type="success"
                                        @click="fun_verification_code_login">登录</el-button>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="!show_forget_password ? '密码登陆' : '找回密码'">
                        <div class="d-flex flex-column mb-auto" v-if="!show_forget_password">
                            <div class="p-2">
                                <div class="col-auto form-floating">
                                    <input v-model="account_login" type="tel" class="form-control" id="account_login"
                                        autocomplete="tel">
                                    <label for="account_login">用户名或手机号</label>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="form-floating">
                                    <input v-model="password_login" type="password" class="form-control"
                                        id="floatingPassword" autocomplete="password">
                                    <label for="floatingPassword">密码</label>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="error_message">{{ error_message_password_login }}</div>
                                <div class="col-auto">
                                    <el-button class="btn-password-login" @click="fun_password_login" type="success"
                                        @keydown="keydown_password_login">登录</el-button>
                                </div>
                            </div>
                            <div class="p-0" style="cursor: pointer; color: black;">
                                <el-button link @click="show_forget_password = true">忘记密码</el-button>
                            </div>
                        </div>
                        <div class="d-flex flex-column mb-auto" v-else>
                            <div class="p-2">
                                <div class="row align-items-center">
                                    <div class="col-md-8">
                                        <div class="form-floating">
                                            <input v-model="phone_number_reset" class="form-control" id="phone_number_reset"
                                                type="text" :class="{
                                                    'is-invalid': !reset_phone_number_reset_valid,
                                                    'is-valid': reset_phone_number_reset_valid
                                                }">
                                            <label for="phone_number_reset">手机号</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="row align-items-center">
                                    <div class="col-md-8">
                                        <div class="form-floating">
                                            <input v-model="verification_code_reset" class="form-control"
                                                id="verification_code_reset">
                                            <label for="verification_code_reset">验证码</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="d-flex flex-column justify-content-between">
                                            <button class="btn btn-primary" @click="send_reset_verification_code"
                                                style="background-color: rgb(110, 165, 201); border: 0px;"
                                                :disabled="total_time_reset != 60">{{ countdown_content_reset }}</button>
                                            <span style="color:green">{{ send_status_reset }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="col-auto form-floating">
                                    <input v-model="reset_new_password" type="password" class="form-control"
                                        id="reset_new_password" :class="{
                                            'is-invalid': !new_password_valid,
                                            'is-valid': new_password_valid
                                        }">
                                    <label for="reset_new_password">设置6-16位的新密码</label>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="col-auto form-floating">
                                    <input v-model="reset_password_confirm" type="password" class="form-control"
                                        id="reset_password_confirm" :class="{
                                            'is-invalid': !reset_password_confirm_valid,
                                            'is-valid': reset_password_confirm_valid
                                        }">
                                    <label for="reset_password_confirm">请再次输入新密码</label>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="error_message">{{ error_message_reset_password }}</div>
                                <div class="d-flex justify-content-evenly">
                                    <el-button class="btn-password-login" @click="show_forget_password = false"
                                        plain>返回</el-button>
                                    <el-button class="btn-password-login" type="success" :disabled="!enable_reset_password"
                                        @click="do_reset_password">确认</el-button>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane label="立即注册">
                        <div class="d-flex flex-column mb-auto">
                            <div class="p-2 col-auto">
                                <div class="form-floating">
                                    <input v-model="phone_number_register" class="form-control" id="phone_number_register"
                                        :class="{
                                            'is-invalid': !register_phone_number_valid,
                                            'is-valid': register_phone_number_valid
                                        }">
                                    <label for="phone_number_register">请输入11位手机号</label>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="form-floating">
                                    <input v-model="username_register" class="form-control" id="username_register" :class="{
                                        'is-invalid': !register_username_valid,
                                        'is-valid': register_username_valid
                                    }">
                                    <label for="username_register">请输入长度小于8的用户名</label>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="form-floating">
                                    <input v-model="password_register" class="form-control" id="pwd_register"
                                        type="password" :class="{
                                            'is-invalid': !register_password_valid,
                                            'is-valid': register_password_valid
                                        }">
                                    <label for="pwd_register">请输入6-16位的密码</label>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="form-floating">
                                    <input v-model="passwordConfirm" class="form-control" id="pwd_confirm" type="password"
                                        :class="{
                                            'is-invalid': !register_confirm_password_valid,
                                            'is-valid': register_confirm_password_valid
                                        }">
                                    <label for="pwd_confirm">请再次输入密码</label>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="row align-items-center">
                                    <div class="col-md-8">
                                        <div class="form-floating">
                                            <input v-model="imageCode" class="form-control" id="image_code"
                                                @blur="begin_check_imageCode()" :class="{
                                                    'is-invalid': check_imageCode && !check_imageCode_pass,
                                                    'is-valid': check_imageCode && check_imageCode_pass
                                                }">
                                            <label for="image_code">图形验证码</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="hint-text message">
                                            <img v-show="!!imageCodeSrc" :src="imageCodeSrc" alt="验证码" @click="loadImage" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="row align-items-center">
                                    <div class="col-md-8">
                                        <div class="form-floating">
                                            <input v-model="verification_code_register" class="form-control"
                                                id="verification_code_register">
                                            <label for="verification_code_register">验证码</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="d-flex flex-column justify-content-between">
                                            <button class="btn btn-primary" @click="send_register_verification_code"
                                                style="background-color: rgb(110, 165, 201); border: 0px;"
                                                :disabled="total_time_register != 60">{{ countdown_content_register
                                                }}</button>
                                            <span style="color:green">{{ send_status_register }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="error_message">{{ error_message_register }}</div>
                                <div class="col-auto">
                                    <el-button
                                        v-if="check_imageCode_pass && enable_register && verification_code_register !== '' && !is_empty(imageCode)"
                                        class="btn-verification-login" type="success" @click="register">注册</el-button>
                                    <el-button v-else class="btn-verification-login" type="success" disabled>注册</el-button>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useStore } from 'vuex';
import { ref, onMounted, computed } from 'vue'
import router from '@/router';
import { ElMessage } from 'element-plus';
import axios from 'axios';
import store from '@/store';

export default {
    beforeRouteEnter(to, from, next) {
        if (store.state.user.is_login) {
            // 如果用户已登录，则不显示登录界面
            next('/playhall');
        } else {
            // 如果用户未登录，则显示登录页面
            next();
        }
    },

    setup() {
        const store = useStore();
        let account_login = ref('');
        let username_register = ref('');

        let password_login = ref('');
        let password_register = ref('');

        let phone_number_login = ref('');
        let phone_number_register = ref('');
        let phone_number_reset = ref('');

        let verification_code_login = ref('');
        let verification_code_register = ref('');
        let verification_code_reset = ref('');

        let send_status_login = ref('');
        let send_status_register = ref('');
        let send_status_reset = ref('');

        let error_message_password_login = ref('');
        let error_message_code_login = ref('');
        let error_message_register = ref('');
        let error_message_reset_password = ref('');

        let countdown_content_login = ref('点击发送');
        let total_time_login = ref(60);
        let countdown_content_register = ref('点击发送');
        let total_time_register = ref(60);
        let countdown_content_reset = ref('点击发送');
        let total_time_reset = ref(60);

        let reset_new_password = ref('');
        let reset_password_confirm = ref('');

        // register
        let passwordConfirm = ref('');

        // 图形验证码
        let imageCodeToken = ref();
        let imageCodeSrc = ref();
        let imageCode = ref();

        let check_imageCode = ref(false);
        let check_imageCode_pass = ref(false);

        let show_forget_password = ref(false);
        let new_password_valid = computed(() => reset_new_password.value.length >= 6 && reset_new_password.value.length <= 16);
        let reset_password_confirm_valid = computed(() => reset_password_confirm.value.length >= 6 && reset_password_confirm.value.length <= 16 && reset_password_confirm.value == reset_new_password.value);
        let reset_phone_number_reset_valid = computed(() => check_phone_number_format(phone_number_reset.value));
        let enable_reset_password = computed(() => new_password_valid.value && reset_password_confirm_valid.value && reset_phone_number_reset_valid.value && verification_code_reset.value.length != 0);

        let register_phone_number_valid = computed(() => check_phone_number_format(phone_number_register.value));
        let register_username_valid = computed(() => username_register.value.length <= 8 && username_register.value.length > 0);
        let register_password_valid = computed(() => password_register.value.length >= 6 && password_register.value.length <= 16);
        let register_confirm_password_valid = computed(() => passwordConfirm.value.length >= 6 && passwordConfirm.value.length <= 16 && passwordConfirm.value == password_register.value);
        let enable_register = computed(() => register_phone_number_valid.value && register_username_valid.value && register_password_valid.value && register_confirm_password_valid.value);


        onMounted(() => {
            loadImage();
            window.addEventListener('keydown', keydown_password_login);
        });

        const loadImage = () => {
            imageCodeToken.value = get_uuid(8);
            imageCodeSrc.value = `${axios.defaults.baseURL}/kaptcha/image/` + imageCodeToken.value;
        }

        const send_login_verification_code = () => {
            error_message_code_login.value = "";
            axios.get('/account/login/verification', {
                params: {
                    phone_number: phone_number_login.value,
                }
            }).then(resp => {
                if (resp.code != 200) {
                    error_message_code_login.value = resp.data;
                } else {
                    send_status_login.value = "发送成功!";
                    countdown_content_login.value = '60s';
                    let clock = window.setInterval(() => {
                        total_time_login.value--;
                        countdown_content_login.value = total_time_login.value + 's';
                        if (total_time_login.value < 0) {     // 当倒计时小于0时清除定时器
                            window.clearInterval(clock);
                            countdown_content_login.value = '发送';
                            send_status_login.value = "";
                            total_time_login.value = 60;
                        }
                    }, 1000);
                }
            }).catch(error => {
                console.log(error);
            })
        }

        const send_register_verification_code = () => {
            if (is_empty(imageCode.value)) {
                ElMessage.warning("请先输入图形验证码");
                return;
            }
            error_message_register.value = "";
            axios.post('/account/register/verification', {
                phoneNumber: phone_number_register.value,
                imageCodeToken: imageCodeToken.value,
                imageCode: imageCode.value,
            }).then(resp => {
                if (resp.code != 200) {
                    error_message_register.value = resp.data;
                } else {
                    send_status_register.value = "发送成功!";
                    countdown_content_login.value = '60s';
                    let clock = window.setInterval(() => {
                        total_time_register.value--;
                        countdown_content_register.value = total_time_register.value + 's';
                        if (total_time_register.value < 0) {     // 当倒计时小于0时清除定时器
                            window.clearInterval(clock);
                            countdown_content_register.value = '发送';
                            send_status_register.value = "";
                            total_time_register.value = 60;
                        }
                    }, 1000);
                }
            }).catch(error => {
                console.log(error);
            })
        }

        const send_reset_verification_code = () => {
            error_message_reset_password.value = "";
            axios.get('/account/password/reset/verification', {
                params: {
                    phone_number: phone_number_reset.value,
                }
            }).then(resp => {
                if (resp.code != 200) {
                    error_message_reset_password.value = resp.data;
                } else {
                    send_status_reset.value = "发送成功!";
                    countdown_content_reset.value = '60s';
                    let clock = window.setInterval(() => {
                        total_time_reset.value--;
                        countdown_content_reset.value = total_time_reset.value + 's';
                        if (total_time_reset.value < 0) {     // 当倒计时小于0时清除定时器
                            window.clearInterval(clock);
                            countdown_content_reset.value = '发送';
                            send_status_reset.value = "";
                            total_time_reset.value = 60;
                        }
                    }, 1000);
                }
            }).catch(error => {
                console.log(error);
            })
        }

        const do_reset_password = () => {
            error_message_reset_password.value = "";
            axios.post('/account/reset/password', {
                phoneNumber: phone_number_reset.value,
                password: reset_new_password.value,
                verCode: verification_code_reset.value,
                confirmPassword: reset_password_confirm.value,
            }).then(resp => {
                if (resp.code != 200) {
                    error_message_reset_password.value = resp.data;
                } else {
                    ElMessage({
                        type: "success",
                        duration: 1000,
                        message: "重置成功",
                    });
                    show_forget_password.value = false;
                }
            }).catch(error => {
                console.log(error);
            })
        }

        const register = () => {
            error_message_register.value = "";
            axios.post('/account/register', {
                username: username_register.value,
                password: password_register.value,
                verificationCode: verification_code_register.value,
                phoneNumber: phone_number_register.value,
                imageCodeToken: imageCodeToken.value,
                imageCode: imageCode.value,
            }).then(resp => {
                if (resp.code != 200) {
                    error_message_register.value = resp.data;
                    return;
                }
                else {
                    store.dispatch("password_login", {
                        username: username_register.value,
                        password: password_register.value,
                        success() {
                            store.dispatch("getInfo", {
                                success() {
                                    router.push({ name: 'playhall' });
                                },
                                error(resp) {
                                    error_message_register.value = resp.data;
                                    return;
                                }
                            })
                        },
                        error() {
                            error_message_register.value = "请检查输入的信息";
                            return;
                        }
                    })
                }
            }).catch(error => {
                console.log(error);
            })
        }

        const fun_password_login = () => {
            error_message_password_login.value = "";
            store.dispatch("password_login", {
                account: account_login.value,
                password: password_login.value,
                success() {
                    store.dispatch("getInfo", {
                        success() {
                            localStorage.setItem('store', JSON.stringify(store.state));
                            router.push({ name: 'home' });
                        },
                        error(resp) {
                            error_message_password_login.value = resp.data;
                        }
                    })
                },
                error(resp) {
                    error_message_password_login.value = resp.data;
                }
            })
        }

        const fun_verification_code_login = () => {
            error_message_code_login.value = "";
            store.dispatch("verification_code_login", {
                phone_number: phone_number_login.value,
                verification_code: verification_code_login.value,
                success() {
                    store.dispatch("getInfo", {
                        success() {
                            localStorage.setItem('store', JSON.stringify(store.state));
                            router.push({ name: 'home' });
                        }
                    })
                },
                error(err) {
                    error_message_code_login.value = err.data;
                }
            })
        }

        const keydown_password_login = (e) => {
            if (e.keyCode == 13 || e.keyCode == 100) {
                fun_password_login();
            }
        }

        const get_uuid = (len, radix = 62) => {
            const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
            const uuid = [];
            radix = radix || chars.length;

            for (let i = 0; i < len; i++) {
                uuid[i] = chars[0 | Math.random() * radix];
            }

            return uuid.join('');
        }

        const is_empty = (obj) => {
            if ((typeof obj === 'string')) {
                return !obj || obj.replace(/\s+/g, "") === ""
            } else {
                return (!obj || JSON.stringify(obj) === "{}" || obj.length === 0);
            }
        }

        const check_phone_number_format = (phone_number) => {
            let reg = /^[1][3,4,5,7,8][0-9]{9}$/;
            if (!reg.test(phone_number)) {
                return false;
            } else {
                return true;
            }
        }

        return {
            account_login,
            username_register,
            password_login,
            password_register,

            phone_number_login,
            phone_number_register,
            phone_number_reset,

            verification_code_login,
            verification_code_register,
            verification_code_reset,

            error_message_code_login,
            error_message_password_login,
            error_message_register,
            error_message_reset_password,

            send_status_login,
            send_status_register,
            send_status_reset,

            total_time_login,
            total_time_register,
            total_time_reset,

            countdown_content_login,
            countdown_content_register,
            countdown_content_reset,

            passwordConfirm,

            imageCode,
            imageCodeSrc,

            check_imageCode,
            check_imageCode_pass,
            imageCodeToken,

            show_forget_password,
            reset_new_password,
            reset_password_confirm,

            new_password_valid,
            reset_password_confirm_valid,
            reset_phone_number_reset_valid,
            register_phone_number_valid,
            register_username_valid,
            register_password_valid,
            register_confirm_password_valid,
            enable_register,
            enable_reset_password,
            register,
            fun_password_login,
            fun_verification_code_login,
            send_login_verification_code,
            send_register_verification_code,
            send_reset_verification_code,
            loadImage,
            keydown_password_login,
            is_empty,
            check_phone_number_format,
            do_reset_password,
        }
    },

    watch: {
        $route() {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        }
    },
    methods: {
        begin_check_imageCode() {
            axios.post('/account/checkImageCode', {
                imageCode: this.imageCode,
                imageCodeToken: this.imageCodeToken
            }).then(res => {
                if (res.code === 200) {
                    this.check_imageCode_pass = true
                } else {
                    this.check_imageCode_pass = false
                }
            }).catch(error => {
                console.log(error);
            })
            this.check_imageCode = true
        },
    }

}
</script>
  
<style scoped>
.hint-text {
    min-height: 30px;
}

.btn-password-login {
    width: 60%;
    height: 38px;
    box-shadow: 1px 1px 3px #727272;
}

.btn-verification-login {
    width: 60%;
    height: 38px;
    box-shadow: 1px 1px 3px #727272;
}

.error_message {
    color: red;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.card {
    width: 80%;
    max-width: 400px;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
    padding: 20px;
    text-align: center;
    height: auto;
    width: auto;
}

.message {
    display: inline-block;
    text-align: left;
    width: 150px;
}
</style>