<template>
    <el-row class="align-items-center">
        <el-col :span="6"></el-col>
        <el-col :span="12">
            <div class="title">
                <span>分类练习</span>
            </div>
        </el-col>
        <el-col :span="6">
            <div class="d-flex flex-row-reverse align-items-center">
                <el-button plain @click="back_to_puzzle_index">返回
                    <el-icon class="el-icon--right">
                        <Back />
                    </el-icon>
                </el-button>
            </div>
        </el-col>
    </el-row>
    <hr />
    <div class="card-container">
        <el-card v-for="(puzzle_type, index) in puzzle_types" :key="index" class="custom-card" @click="show_puzzles(puzzle_type.code)">
            <el-row class="align-items-center justify-content-center" style="justify-content: center;">
                <el-col :span="10">
                    <img :src="`https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/p2.png`" alt="等级" class="image">
                </el-col>
                <el-col :span="14">
                    <p class="text">{{ puzzle_type.subject }}</p>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Back } from '@element-plus/icons-vue';

export default {
    components: {
        Back
    },
    setup() {
        const router = useRouter();
        let puzzle_types = ref([]);

        onMounted(() => {
            axios.get('/puzzle/type/enum').then(resp => {
                if (resp.code == 200) {
                    puzzle_types.value = resp.data;
                }
            })
        })

        const show_puzzles = (typeCode) => {
            router.push({
                name: 'puzzle_type_practice_list',
                params: {
                    typeCode: typeCode
                }
            })
        }

        const back_to_puzzle_index = () => {
            router.push({
                name: 'public_puzzles'
            })
        }

        return {
            puzzle_types,
            show_puzzles,
            back_to_puzzle_index,
        }
    },
}
</script>

<style scoped>
.title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* 让卡片在容器中居中显示 */
    align-items: flex-start;
    margin: 0 -10px;
    /* 调整卡片之间的间距 */
}

.custom-card {
    width: 200px;
    height: 80px;
    margin: 15px;
    cursor: pointer;
    background-color: rgb(235, 241, 243);
}

/* 添加媒体查询以根据屏幕宽度调整卡片显示数量 */
@media screen and (max-width: 1200px) {
    .custom-card {
        flex: 1 0 calc(33% - 20px);
        /* 在较小屏幕上显示 3 个卡片 */
    }
}

@media screen and (max-width: 768px) {
    .custom-card {
        flex: 1 0 calc(50% - 20px);
        /* 在小屏幕上显示 2 个卡片 */
    }
}

@media screen and (max-width: 480px) {
    .custom-card {
        flex: 1 0 100%;
        /* 在更小屏幕上卡片占满一行 */
    }
}

.image {
    max-width: 60%;
    height: auto;
}

.text {
    margin: 0 auto;
    padding: 5px;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
}
</style>