<template>
    <ContentBase>
        <PuzzleLadder>

        </PuzzleLadder>
    </ContentBase>
</template>

<script>
import PuzzleLadder from '@/components/puzzle/PuzzleLadderView.vue';
import ContentBase from '@/components/base/ContentBase.vue';

export default {
    components: {
        ContentBase,
        PuzzleLadder,
    }
}
</script>

<style scoped>

</style>