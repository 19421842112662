<template>
    <el-skeleton :loading="loading" :rows="10"></el-skeleton>
    <div v-if="!loading" class="d-flex flex-column mb-2" style="text-align: center;">
        <div class="d-flex justify-content-center">
            <div class="card-body">
                <router-link :to="{ name: 'update_info' }">
                    <img :src="guests.user_avatar" alt="" class="user-avatar-large">
                </router-link>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <el-tag :color="LEVEL[guests.user_level].color" class="level">
                <span style="color: white; font-size: larger;">
                    {{ guests.user_level }}
                </span>
            </el-tag>
        </div>
        <hr />
        <div class="container text-center">
            <div class="row">
                <span id="username" style="min-width: 50px;"> {{ guests.username }}</span>
            </div>
            <div class="row">
                <span id="profile" style="min-width: 50px;"> {{ guests.profile }}</span>
            </div>
        </div>
        <hr />
        <div class="d-flex justify-content-around">
            <span class="count-info">
                关注数
            </span>
            <span class="count-info">
                粉丝数
            </span>
        </div>
        <div class="d-flex justify-content-around">
            <span class="count-detail">
                {{ guests.following_count }}
            </span>
            <span class="count-detail">
                {{ guests.followers_count }}
            </span>
        </div>
        <div class="d-flex justify-content-center follow-op" v-if="!is_me">
            <el-button size="small" class="btn-op" v-if="guests.id != $store.state.user.id && relation === 'following'" plain>
                已关注
            </el-button>
            <el-button size="small" class="btn-op" v-else-if="guests.id != $store.state.user.id && relation === 'friend'" plain>
                互相关注
            </el-button>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, onMounted } from 'vue';
import { ref } from 'vue';
import axios from 'axios';

export default {
    props: {
        userId: {
            type: String,
            required: true,
        }
    },

    setup(props) {
        const store = useStore();
        const route = useRouter();
        const is_me = computed(() => props.userId === store.state.user.id);
        const loading = ref(true);
        const LEVEL = window.LEVEL;

        let relation = ref();
        let guests = ref([]);

        onMounted(() => {
            loading.value = true;
            pull_user_info();
            axios.get('/friend/getRelationship', {
                params: {
                    search_id: props.userId,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    relation.value = resp.data;
                    loading.value = false;
                }
            })
        })

        const pull_user_info = () => {
            axios.get('/user/getFollowingAndFollowersCount', {
                params: {
                    request_id: props.userId,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    guests.value = resp.data;
                } else if (resp.code == 404) {
                    route.push({
                        name: "404",
                    });
                }
            })
        }

        const click_profile = (user_id) => {
            router.push({
                name: 'user_index',
                params: {
                    userId: user_id,
                }
            })
        }

        return {
            is_me,
            relation,
            loading,
            LEVEL,
            guests,
            click_profile,
        }
    },

    watch: {
        $route() {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        }
    },
}
</script>

<style scoped>
#username {
    font-size: x-large;
    font-weight: 600;
    text-align: center;
}

#profile {
    font-size: medium;
    color: gray;
    text-align: center;
}

.count-info {
    font-size: medium;
    min-width: 40px;
    color: gray;
}

.count-detail {
    font-size: medium;
    min-width: 40px;
}

.guests_avatar {
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 10%;
}

.guests {
    white-space: nowrap;
    /*强制单行显示*/
    text-overflow: ellipsis;
    font-weight: 400;
    /*超出部分省略号表示*/
    overflow: hidden;
    /*超出部分隐藏*/
    width: 2.5vw;
    /*设置显示的最大宽度*/
    display: inline-block;
    vertical-align: top;
}

.info {
    margin-left: 0.5vw;
}

.follow-op {
    margin-top: 1vh;
}

.btn-op {
    display: inline-block;
    text-align: center;
    max-width: 50%;
}

.level {
    text-align: center;
    height: auto;
    width: 60%;
    border-radius: 10px;
    cursor: default;
    min-width: 80px;
    min-height: 30px;
}

.user-avatar-large {
    width: 95%;
    height: 95%;
}
</style>