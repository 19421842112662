<template>
    <el-row class="align-items-center">
        <el-col :span="6"></el-col>
        <el-col :span="12">
            <el-input v-model="search_puzzle" placeholder="搜索题号、标题" class="search-puzzle"></el-input>
            <img src="@/assets/images/icon_search.png" class="img-search" @click="get_puzzle_search" title="搜索"
                @keydown="keydown_search_puzzle">
        </el-col>
        <el-col :span="6">
            <div class="d-flex flex-row-reverse align-items-center">
                <el-button plain @click="back_to_puzzle_index">返回
                    <el-icon class="el-icon--right">
                        <Back />
                    </el-icon>
                </el-button>
            </div>
        </el-col>
    </el-row>
    <hr />
    <!-- 所有题目 -->
    <div class="grid-layout" v-if="!loading && show_lists">
        <div :class="puzzle.pass ? 'grid-item-pass' : 'grid-item-npass'" v-for="(puzzle) in puzzles_list" :key="puzzle.id"
            @click="push_to_puzzle(puzzle.id)">
            <div class="image-container">
                <img :src="`https://fcjznkj.oss-cn-qingdao.aliyuncs.com/puzzles/images/${puzzle.id}.png`"
                    class="large-image" alt="全力补充中.." />
                <img src="@/assets/images/solved.png" class="small-image" alt="您已通过" title="你已通过这道题目"
                    v-show="puzzle.pass" />
            </div>
            <div class="grid-text">
                <div class="text-line text-align-items">
                    <el-rate v-model="puzzle.rating" disabled text-color="#ff9900" size="small" />
                </div>
                <div class="text-line text-align-items">
                    <el-text :underline="false" class="puzzle-title mx-1"> {{ puzzle.id }}</el-text>
                    <el-text :type="getType(puzzle.gradeLevel)" class="mx-1">
                        {{ puzzle.grade }}
                    </el-text>
                </div>
                <div class="text-line puzzle-passrate text-align-items">通过率: {{ puzzle.passRate }}</div>
            </div>
        </div>
    </div>
    <!-- 搜索题目 -->
    <div class="grid-layout" v-if="!loading && !show_lists">
        <div :class="puzzle.pass ? 'grid-item-pass' : 'grid-item-npass'" v-for="(puzzle) in puzzles_search" :key="puzzle.id"
            @click="push_to_puzzle(puzzle.id)">
            <div class="image-container">
                <img :src="`https://fcjznkj.oss-cn-qingdao.aliyuncs.com/puzzles/images/${puzzle.id}.png`"
                    class="large-image" alt="全力补充中.." />
                <img src="@/assets/images/solved.png" class="small-image" alt="您已通过" title="你已通过这道题目"
                    v-show="puzzle.pass" />
            </div>
            <div class="grid-text">
                <div class="text-line text-align-items">
                    <el-rate v-model="puzzle.rating" disabled text-color="#ff9900" size="small" />
                </div>
                <div class="text-line text-align-items">
                    <el-text :underline="false" class="puzzle-title mx-1"> {{ puzzle.id }}</el-text>
                    <el-text :type="getType(puzzle.gradeLevel)" class="mx-1">
                        {{ puzzle.grade }}
                    </el-text>
                </div>
                <div class="text-line puzzle-passrate text-align-items">通过率: {{ puzzle.passRate }}</div>
            </div>
        </div>
    </div>
    <!-- 列表分页器 -->
    <div class="block" style="margin-top:10px; float: right" v-show="show_lists">
        <el-pagination @current-change="handle_current_list_change" :current-page="current_page_list" background
            hide-on-single-page :page-size="page_size_list" :page-sizes="[10, 20, 50, 100]"
            @size-change="handle_list_size_change" layout="sizes, prev, pager, next, jumper" :total="total_list">
        </el-pagination>
    </div>
    <!-- 搜索分页器 -->
    <div class="block" style="margin-top:10px; float: right" v-show="!show_lists">
        <el-pagination @current-change="handle_current_search_change" :current-page="current_page_search" background
            hide-on-single-page :page-size="page_size_search" @size-change="handle_search_size_change"
            layout="prev, pager, next, jumper" :total="total_search">
        </el-pagination>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { Back } from '@element-plus/icons-vue'

export default {
    components: {
        Back
    },

    setup() {
        const router = useRouter();

        let search_puzzle = ref('');
        let show_lists = ref(true);
        let loading = ref(true);

        let puzzles_list = ref([]);
        let current_page_list = ref(1); // 当前页码
        let total_list = ref(0); // 总条数
        let page_size_list = ref(50); // 每页的数据条数

        let puzzles_search = ref([]);
        let current_page_search = ref(1); // 当前页码
        let total_search = ref(0); // 总条数
        let page_size_search = ref(20); // 每页的数据条数

        onMounted(() => {
            get_puzzle_list();
            window.addEventListener('keydown', keydown_search_puzzle);
        })

        const get_puzzle_list = () => {
            axios.get('/puzzle/page', {
                params: {
                    pageNum: current_page_list.value - 1,
                    pageSize: page_size_list.value,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    total_list.value = resp.data.totalElements;
                    puzzles_list.value = resp.data.dataList;
                    loading.value = false;
                } else if (resp.code == 400) {
                    ElMessage.warning(resp.data);
                }
            })
        }

        const get_puzzle_search = () => {
            if (search_puzzle.value.trim() == '') return;
            show_lists.value = false;
            loading.value = true;
            axios.get('/puzzle/vaguePage', {
                params: {
                    pageNum: 0,
                    pageSize: page_size_search.value,
                    pattern: search_puzzle.value,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    current_page_search.value = 1;
                    total_search.value = resp.data.totalElements;
                    puzzles_search.value = resp.data.dataList;
                    loading.value = false;
                }
            })
        }

        //当前页改变时触发 跳转其他页
        const handle_current_list_change = (val) => {
            loading.value = true;
            axios.get('/puzzle/page', {
                params: {
                    pageNum: val - 1,
                    pageSize: page_size_list.value,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    current_page_list.value = val;
                    total_list.value = resp.data.totalElements;
                    puzzles_list.value = resp.data.dataList;
                    loading.value = false;
                } else if (resp.code == 400) {
                    ElMessage.warning(resp.data);
                }
            })
        }

        //当前页改变时触发 跳转其他页
        const handle_current_search_change = (val) => {
            loading.value = true;
            axios.get('/puzzle/vaguePage', {
                params: {
                    pageNum: val - 1,
                    pageSize: page_size_search.value,
                    pattern: search_puzzle.value,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    current_page_search.value = val;
                    total_search.value = resp.data.totalElements;
                    puzzles_search.value = resp.data.dataList;
                    loading.value = false;
                }
            })
        }

        const handle_list_size_change = (val) => {
            page_size_list.value = val;
            get_puzzle_list();
        }

        const handle_search_size_change = (val) => {
            page_size_search.value = val;
            get_puzzle_search();
        }

        const back_to_puzzle_index = () => {
            router.push({
                name: 'public_puzzles'
            })
        }

        const push_to_puzzle = (puzzle_id) => {
            router.push({
                name: "puzzle_details",
                params: {
                    puzzleId: puzzle_id,
                }
            })
        }

        const getType = (level) => {
            if (level <= 18) return "success";
            else if (level <= 22) return "warning";
            else return "danger";
        }

        const keydown_search_puzzle = (e) => {
            if (e.keyCode == 13 || e.keyCode == 100) {
                get_puzzle_search();
            }
        }

        return {
            puzzles_list,
            current_page_list,
            total_list,
            page_size_list,
            puzzles_search,
            current_page_search,
            total_search,
            page_size_search,
            search_puzzle,
            loading,
            show_lists,
            back_to_puzzle_index,
            handle_current_list_change,
            handle_current_search_change,
            handle_list_size_change,
            handle_search_size_change,
            get_puzzle_search,
            push_to_puzzle,
            getType,
            keydown_search_puzzle
        }
    },
}
</script>

<style scoped>
.puzzle-title {
    font-size: small;
    cursor: pointer;
    color: rgb(40, 116, 159);
    margin: 5px auto;
}

.puzzle-title:hover {
    text-decoration: underline;
}

.puzzle-passrate {
    margin: 5px auto;
    text-align: center;
    font-size: small;
    color: rgba(80, 79, 79, 0.7);
}

.search-puzzle {
    width: 25vw;
}

.img-search {
    width: 2vw;
    height: 2vw;
    margin-left: 10px;
    cursor: pointer;
}

.grid-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 10px;
    /* 间距 */
}

.grid-item-pass {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: rgba(209, 227, 240, 0.5);
    border: 1px solid #dddddd;
    cursor: pointer;
}

.grid-item-npass {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #dddddd;
    cursor: pointer;
}

.large-image {
    width: 130px;
    /* 调整图片大小 */
    height: auto;
}

.small-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    /* 调整小图片的大小 */
    height: auto;
    z-index: 1;
    /* 确保小图片在大图片之上 */
}

.grid-text {
    text-align: center;
}

.text-line {
    margin: 0 auto;
}

.image-container {
    position: relative;
    display: inline-block;
}
</style>