<template>
    <ContentBase>
        <el-row class="align-items-center">
            <el-col :span="6"></el-col>
            <el-col :span="12">
                <div class="title">
                    <span>天梯闯关 - 第{{ ladder_code }}级</span>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="d-flex flex-row-reverse align-items-center">
                    <el-button plain @click="back_to_ladder_index">返回
                        <el-icon class="el-icon--right">
                            <Back />
                        </el-icon>
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <hr />
        <div class="card-container">
            <el-card v-for="(ladder, index) in ladders" :key="index" :class=" ladder.passed ? 'custom-card-passed' : 'custom-card'"
                @click="show_ladder_puzzles(ladder.ladderId)">
                <el-row class="align-items-center" style="justify-content: center;">
                    <el-col :span="10">
                        <img :src="`https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/p1.png`" alt="等级" class="image">
                    </el-col>
                    <el-col :span="14">
                        <p class="text">{{ ladder.ladderId }}</p>
                    </el-col>
                </el-row>
                <div style="display: inline-flex; align-items: center;">
                    <el-icon :size="14" color="gray">
                        <UserFilled />
                    </el-icon>
                    <p class="text_pass_count">&nbsp;{{ ladder.passCount }}</p>
                </div>
            </el-card>
        </div>
    </ContentBase>
</template>

<script>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { Back, UserFilled } from '@element-plus/icons-vue';
import ContentBase from '@/components/base/ContentBase.vue';

export default {
    components: {
        ContentBase,
        Back,
        UserFilled,
    },

    setup() {
        const route = useRoute();
        const router = useRouter();
        let ladders = ref([]);
        const ladder_code = route.params.ladder_code;

        onMounted(() => {
            axios.get('/puzzle/ladder/group', {
                params: {
                    group: ladder_code,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    ladders.value = resp.data;
                }
            })
        })

        const back_to_ladder_index = () => {
            router.push({
                name: 'puzzle_ladder_practice'
            });
        }

        const show_ladder_puzzles = (group_code) => {
            router.push({
                name: 'puzzle_ladder_level_group',
                params: {
                    group_code: group_code
                }
            })
        }

        return {
            ladder_code,
            ladders,
            back_to_ladder_index,
            show_ladder_puzzles,
        }
    }
}
</script>

<style scoped>
.title {
    text-align: center;
    font-size: x-large;
    font-weight: bold;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* 让卡片在容器中居中显示 */
    align-items: flex-start;
    margin: 0 -10px;
    /* 调整卡片之间的间距 */
}

.custom-card {
    width: 150px;
    height: 80px;
    margin: 15px;
    cursor: pointer;
    background-color: rgb(235, 241, 243);
}

.custom-card-passed {
    width: 150px;
    height: 80px;
    margin: 15px;
    cursor: pointer;
    background-color: rgb(221, 238, 224);
}

/* 添加媒体查询以根据屏幕宽度调整卡片显示数量 */
@media screen and (max-width: 1200px) {
    .custom-card {
        flex: 1 0 calc(33% - 20px);
        /* 在较小屏幕上显示 3 个卡片 */
    }
}

@media screen and (max-width: 768px) {
    .custom-card {
        flex: 1 0 calc(50% - 20px);
        /* 在小屏幕上显示 2 个卡片 */
    }
}

@media screen and (max-width: 480px) {
    .custom-card {
        flex: 1 0 100%;
        /* 在更小屏幕上卡片占满一行 */
    }
}

.image {
    max-width: 80%;
    height: auto;
}

.text {
    margin: 0 auto;
    padding: 2px;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
}

.text_pass_count {
    margin: 0 auto;
    padding: 0px;
    white-space: nowrap;
    color: gray;
}
</style>