<template>
    <div>
        <NavBar v-if="$router.currentRoute.value.name != 'recharge_success'"/>
        <router-view />
    </div>
</template>

<script>
import NavBar from './components/base/NavBar.vue';
import router from './router';
import { useRoute } from 'vue-router';
import { request_draw_eb, go_resign, room_destroy, room_info } from '@/views/play/PlayRoomView.vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { WS_URL } from './assets/apis/api';
import { total_notification_unread } from '@/components/base/NavBar.vue';
import { users_in_room } from './components/room/RoomUserList.vue';
import { room_dissolve } from './views/play/PracticeRoomView.vue';
import { games, show_game_blank } from './views/community/PlayHallView.vue'

export let request_play = ElMessageBox;
export default {
    components: {
        NavBar
    },

    // 解决f5刷新后数据不丢失的问题
    created() {
        // 在页面加载时读取localStorage里的状态信息
        if (localStorage.getItem('store')) {
            // 存储状态
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem('store'))))
        }
        // 在页面刷新时将vue里的信息保存到localStorage里
        window.addEventListener('beforeunload', () => {
            localStorage.setItem('store', JSON.stringify(this.$store.state));
            this.$store.state.gogame.socket.close();
        })
        const socketUrl = `${WS_URL}/${this.$store.state.user.token}`;
        const store = this.$store;
        const route = useRoute();
        const play_request = ElMessageBox;
        const play_audio = new Audio();
        const remove1_audio = new Audio();
        const remove2_audio = new Audio();
        const remove3_audio = new Audio();
        const remove4_audio = new Audio();
        const start_audio = new Audio();
        start_audio.src = require('@/assets/audios/gameStart.wav');
        remove1_audio.src = require('@/assets/audios/remove1.wav');
        remove2_audio.src = require('@/assets/audios/remove2.wav');
        remove3_audio.src = require('@/assets/audios/remove3.wav');
        remove4_audio.src = require('@/assets/audios/remove4.wav');
        play_audio.src = require('@/assets/audios/play.wav');
        let socket = new WebSocket(socketUrl);
        socket.onopen = () => {
            console.log("Socket Connnected!");
            store.commit("updateSocket", socket);
        }
        socket.onmessage = msg => {
            const data = JSON.parse(msg.data);
            if (data.event === 'play') {
                if (data.valid === 'y') {
                    room_info.data.play_count = data.play_count;
                    room_info.data.current = data.current;
                    room_info.data.black_time = data.black_time;
                    room_info.data.white_time = data.white_time;
                    store.commit("updateBoard", data.board);
                    store.commit("updateLastStep", {
                        last_x: data.last_x,
                        last_y: data.last_y,
                    });
                    const caputred_size = data.captured_size;
                    play_audio.play();
                    play_audio.onended = () => {
                        if (caputred_size >= 10) {
                            remove4_audio.play();
                        } else if (caputred_size >= 5) {
                            remove3_audio.play();
                        } else if (caputred_size >= 2) {
                            remove2_audio.play();
                        } else if (caputred_size == 1) {
                            remove1_audio.play();
                        }
                    }
                }
                if (data.room_id == store.state.gogame.room_id) {
                    store.commit("updateCurrent", data.current);
                }
            }
            else if (data.event === 'notification') {
                total_notification_unread.value = data.unread_notifications_count;
            }
            else if (data.event === "start") {
                start_audio.play();
                request_play.close();
                const roomId = data.game.room_id;
                store.commit("updateLastStep", {
                    last_x: -1,
                    last_y: -1,
                })
                store.commit("updateOpponentId", data.opponent_userid, data.opponent_did);
                sessionStorage.setItem('game_status', 'playing');
                store.commit("updateBoard", data.game.board);
                store.commit("updateRoomId", roomId);
                if (data.game.black_id == store.state.user.id) { // 执黑
                    store.commit("updateWhich", 1);
                    store.commit("updateCurrent", 1);
                } else {  // 执白
                    if (data.game.black_id == -1) store.commit("updateCurrent", 2);
                    else store.commit("updateCurrent", 1);
                    store.commit("updateWhich", 2);
                }
                router.push({
                    name: 'playroom',
                    params: {
                        roomId: roomId,
                    },
                });
            }
            else if (data.event === "result") {
                store.commit("updateWhich", 0);
                store.commit("updateCurrent", 0);
                store.commit("updateRoomId", null);
                sessionStorage.setItem('game_status', 'waiting');
                store.commit("updateLastStep", {
                    last_x: -2,
                    last_y: -2,
                })
                go_resign.close();
                request_draw_eb.close();
                let msg;
                if (data.loser == 1) {
                    msg = "白中盘胜";
                } else if (data.loser == 2) {
                    msg = "黑中盘胜";
                } else {
                    msg = "和棋";
                }
                room_info.data.result = msg;
                ElMessageBox.alert(msg, {
                    confirmButtonText: '确定',
                    type: 'info',
                    showClose: false,
                    center: true,
                })
            }
            else if (data.event === 'regret_success') {
                request_draw_eb.close();
                store.commit("updateBoard", data.board);
                store.commit("updateLastStep", {
                    last_x: data.last_x,
                    last_y: data.last_y,
                })
                if (data.room_id == store.state.gogame.room_id) {
                    store.commit("updateCurrent", data.current);
                }
                if (store.state.gogame.which == 1) {
                    room_info.data.regret_count = data.black_regret_count;
                } else if (store.state.gogame.which == 2) {
                    room_info.data.regret_count = data.white_regret_count;
                }
            }
            else if (data.event === 'request_play') {     // 接受到一名玩家发出的邀请
                if (data.type == 2) {
                    play_request.confirm(data.username + " 请求五子棋对弈", {
                        confirmButtonText: '同意',
                        cancelButtonText: '拒绝',
                        title: "对局邀请",
                        showClose: false,
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        type: 'info',
                        center: true
                    }).then(() => {
                        socket.send(JSON.stringify({
                            event: "accept_invitation",
                            friend_id: data.request_id,
                            type: data.type,
                            size: data.size,
                        }));
                    }).catch(() => {
                        socket.send(JSON.stringify({
                            event: "refuse_invitation",
                            friend_id: data.request_id,
                        }));
                    })
                }
                else if (data.type == 1) {
                    play_request.confirm(data.username + "   " + data.level + "  " + data.win + "胜" +
                        "  " + data.lose + "负    向您发来" + data.size + "路 " + "对局邀请", {
                        confirmButtonText: '同意',
                        cancelButtonText: '拒绝',
                        title: "对局邀请",
                        showClose: false,
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        type: 'info',
                        center: true
                    }).then(() => {
                        socket.send(JSON.stringify({
                            event: "accept_invitation",
                            friend_id: data.request_id,
                            size: data.size,
                            type: data.type,
                        }));
                    }).catch(() => {
                        socket.send(JSON.stringify({
                            event: "refuse_invitation",
                            friend_id: data.request_id,
                        }));
                    });
                }

            }
            else if (data.event === 'request_cancel') {   // 另一名玩家取消了邀请(对局邀请、和棋邀请、悔棋申请)
                play_request.close();
            }
            else if (data.event === 'request_draw') {    // 另一名玩家请求和棋
                play_request.confirm("对方向您发来和棋请求", {
                    cancelButtonText: '同意',
                    confirmButtonText: '拒绝',
                    title: "和棋请求",
                    type: 'info',
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    showClose: false,
                    center: true
                }).then(() => {
                    socket.send(JSON.stringify({
                        event: "refuse_invitation",
                        friend_id: store.state.gogame.opponent_userid,
                        friend_carry_id: store.state.gogame.opponent_device_id,
                    }));
                }).catch(() => {
                    socket.send(JSON.stringify({
                        event: "accept_draw",
                    }));
                })
            }
            else if (data.event === 'request_regret') {    // 另一名玩家请求悔棋
                play_request.confirm("对方请求悔棋", {
                    cancelButtonText: '同意',
                    confirmButtonText: '拒绝',
                    title: "悔棋请求",
                    type: 'info',
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    showClose: false,
                    center: true
                }).then(() => {
                    socket.send(JSON.stringify({
                        event: "refuse_invitation",
                        friend_id: store.state.gogame.opponent_userid,
                        friend_carry_id: store.state.gogame.opponent_device_id,
                    }));
                }).catch(() => {
                    let regret_count;
                    if (store.state.gogame.which == store.state.gogame.current) {
                        regret_count = 1;
                    } else regret_count = 2;
                    socket.send(JSON.stringify({
                        event: "accept_regret",
                        regret_count: regret_count,
                    }));
                })
            }
            else if (data.event === 'friend_refuse') {    // 另一名玩家拒绝了对局邀请
                request_play.close();
                request_draw_eb.close();
                request_play.alert('对局拒绝了请求', {
                    confirmButtonText: '确定',
                    type: 'error',
                    showClose: false,
                    center: true,
                })
            }
            else if (data.event === 'room_user_changed') {
                users_in_room.data = data.data;
            }
            else if (data.event === 'dissolve_room') {
                room_dissolve.alert('房间已被房主解散', {
                    confirmButtonText: '确定',
                    type: 'error',
                    showClose: false,
                    center: true,
                }).then(() => {
                    router.push({
                        name: 'playhall',
                    })
                })
            }
            else if (data.event === 'destroy_room') {
                let cur = route.name;
                if (cur == 'playroom') {
                    room_destroy.alert('房间已被解散', {
                        confirmButtonText: '确定',
                        type: 'error',
                        center: true,
                        showClose: false,
                    }).then(() => {
                        router.push({
                            name: 'playhall',
                        })
                    })
                }

            }
            else if (data.event === 'engine_fail') {
                ElMessage.error("引擎出错啦，请稍后再试");
            }
            else if (data.event === 'rooms_changed') {
                games.data = data.games;
                show_game_blank.show = data.games.length == 0;
            }
            else if (data.event == 'regret_count') {
                room_info.data.regret_count = data.regret_count;
            }
        }
        socket.onclose = () => {
            console.log("Disconnected");
        };
    },
} 
</script>

<style>
body {
    background-image: url('https://fcjznkj.oss-cn-qingdao.aliyuncs.com/fcjznkj%E7%BD%91%E7%AB%99/background.png');
    background-repeat: repeat;
    background-size: 10%;
    background-attachment: fixed;
    z-index: -999;
}
</style>
