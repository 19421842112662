<template>
    <ContentBase>
        <div class="title">
            <h2>方寸商城</h2>
        </div>
        <el-divider />
        <el-skeleton :rows="2" v-if="loading" />
        <div v-else>
            <div class="d-flex justify-content-evenly align-items-center" style="margin-bottom: 10px;">
                <div class="d-flex align-items-center">
                    <img :src="$store.state.user.avatar" class="user-avatar">&nbsp;
                    <span class="text">{{ $store.state.user.username }}</span>
                </div>
                <div class="d-flex align-items-center">
                    <span class="text">本次消费将从您的余额扣除，您当前余额：￥{{ currency_db }} CNY</span>
                    <el-link :underline="false" type="primary">
                        <router-link :to="{ name: 'recharge_normal' }" class="text">&nbsp;&nbsp;去充值</router-link>
                    </el-link>
                </div>
                <div>
                    <router-link :to="{ name: 'user_purchase_record' }" class="router-link-exact-active">
                        <el-button class="button" text>消费记录</el-button>
                    </router-link>
                </div>
            </div>
            <div class="d-flex justify-content-evenly align-items-center" style="margin-bottom: 10px;">
                <div v-if="is_member" class="align_items-center">
                    <span>您已是会员，会员到期时间为</span>
                    <el-text type="primary" size="large">{{ expire_time }}, </el-text>
                    <span>您拥有悔棋卡{{ regret_count_db }}张，领地卡{{ score_count_db }}张</span>
                </div>
                <div v-else>
                    <span>您还不是会员</span>
                    <span>，您拥有悔棋卡{{ regret_count_db }}张，领地卡{{ score_count_db }}张</span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-evenly">
            <!--会员兑换-->
            <el-card :body-style="{ padding: '0px' }">
                <el-container style="padding: 0px;">
                    <el-main>
                        <el-tooltip class="item" effect="light" content="请使用随身板后的兑换码" placement="right">
                            <img src="../../assets/images/icon_exchange.png" class="image" />
                        </el-tooltip>
                    </el-main>
                    <el-footer style="padding: 0px; margin-top: -20px;">
                        <el-row>
                            <el-col :span="12" :offset="6">
                                <span>会员码兑换</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :offset="6">
                                <el-button text data-bs-toggle="modal" data-bs-target="#do-exchange">兑换</el-button>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-container>
            </el-card>
            <!--会员充值-->
            <el-card :body-style="{ padding: '0px' }">
                <el-container style="padding: 0px;">
                    <el-main>
                        <el-tooltip class="item" effect="light" content="开通后享受更多权益" placement="right">
                            <img src="../../assets/images/icon_member_card.png" class="image" />
                        </el-tooltip>
                    </el-main>
                    <el-footer style="padding: 0px; margin-top: -20px;">
                        <el-row>
                            <el-col :span="12" :offset="6">
                                <span>开通会员</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :offset="6">
                                <el-button text class="operation-buy" data-bs-toggle="modal"
                                    data-bs-target="#do-member">开通</el-button>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-container>
            </el-card>
            <!--悔棋卡-->
            <el-card :body-style="{ padding: '0px' }">
                <el-container style="padding: 0px;">
                    <el-main>
                        <el-tooltip class="item" effect="light" content="下错了随时悔棋" placement="right">
                            <img src="../../assets/images/icon_regret_card.png" class="image" />
                        </el-tooltip>
                    </el-main>
                    <el-footer style="padding: 0px; margin-top: -20px;">
                        <el-row>
                            <el-col :span="12" :offset="6">
                                <span>悔棋道具</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :offset="6">
                                <el-button text data-bs-toggle="modal" data-bs-target="#do-regret">购买</el-button>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-container>
            </el-card>
            <!--形势判断卡-->
            <el-card :body-style="{ padding: '0px' }">
                <el-container style="padding: 0px;">
                    <el-main>
                        <el-tooltip class="item" effect="light" content="随时计算盘面局面" placement="right">
                            <img src="../../assets/images/icon_area_card.png" class="image" />
                        </el-tooltip>
                    </el-main>
                    <el-footer style="padding: 0px; margin-top: -20px;">
                        <el-row>
                            <el-col :span="12" :offset="6">
                                <span>领地道具</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :offset="6">
                                <el-button text data-bs-toggle="modal" data-bs-target="#do-score">购买</el-button>
                            </el-col>
                        </el-row>
                    </el-footer>
                </el-container>
            </el-card>
        </div>
    </ContentBase>

    <!-- 兑换码兑换会员模态框 -->
    <div class="modal fade" id="do-exchange" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header modal-center-component">
                    <h1 class="modal-title fs-5">兑换码兑换会员</h1>
                </div>
                <div class="modal-body">
                    <div class="modal-center-component">
                        <div>
                            <el-input v-model="exchange_id" placeholder="请输入兑换码"></el-input>
                        </div>
                    </div>
                </div>
                <div class="modal-footer modal-center-component">
                    <button type="button" class="btn btn-success btn-sm btn-do-op" @click="do_exchange">
                        兑换
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- 开通会员模态框 -->
    <div class="modal fade" id="do-member" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header modal-center-component">
                    <h1 class="modal-title fs-5">开通会员</h1>
                </div>
                <div class="modal-body">
                    <div class="d-flex justify-content-evenly align-items-center">
                        <el-radio-group v-model="member_duration">
                            <el-radio :label=3 border>月卡 ¥10</el-radio>
                            <el-radio :label=4 border>年卡 ¥100</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="modal-footer modal-center-component">
                    <button type="button" class="btn btn-success btn-sm btn-do-op" @click="do_buy_member">
                        开通
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- 购买悔棋模态框 -->
    <div class="modal fade" id="do-regret" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header modal-center-component">
                    <h1 class="modal-title fs-5">悔棋卡购买</h1>
                </div>
                <div class="modal-body">
                    <div class="d-flex justify-content-evenly align-items-center">
                        <el-input-number v-model="regret_num" :min="10" :max="100" step="10" @change="handleRegretChange" />
                        <span>{{ regret_count }} ¥</span>
                    </div>
                </div>
                <div class="modal-footer modal-center-component">
                    <button type="button" class="btn btn-success btn-sm btn-do-op" @click="do_buy_regret">
                        购买
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- 购买领地模态框 -->
    <div class="modal fade" id="do-score" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header modal-center-component">
                    <h1 class="modal-title fs-5">领地卡购买</h1>
                </div>
                <div class="modal-body">
                    <div class="d-flex justify-content-evenly align-items-center">
                        <el-input-number v-model="score_num" :min="10" :max="100" step="10" @change="handleScoreChange" />
                        <span>{{ score_count }} ¥</span>
                    </div>
                </div>
                <div class="modal-footer modal-center-component">
                    <button type="button" class="btn btn-success btn-sm btn-do-op" @click="do_buy_score">
                        购买
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContentBase from '@/components/base/ContentBase.vue';
import { ref, onMounted } from 'vue';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { ElNotification } from 'element-plus'
import { useStore } from 'vuex';
import axios from 'axios';

export default {
    components: {
        ContentBase,
    },

    setup() {
        const store = useStore();
        let loading = ref(true);
        let exchange_id = ref();
        let currency_db = ref("");
        let regret_count_db = ref();
        let score_count_db = ref();

        let regret_num = ref(10);
        let score_num = ref(10);
        let regret_count = ref(parseFloat(0.01));
        let score_count = ref(parseFloat(0.01));
        let member_duration = ref(3);
        let is_member = ref(false);
        let expire_time = ref('');

        onMounted(() => {
            axios.get("/user-account/info").then(resp => {
                if (resp.code == 200) {
                    currency_db.value = resp.data.currency;
                    regret_count_db.value = resp.data.regretCount;
                    score_count_db.value = resp.data.scoreCount;
                    is_member.value = resp.data.isMember;
                    expire_time.value = resp.data.expireTime;
                    loading.value = false;
                }
            })
        })

        const do_exchange = () => {
            axios.post('/shopping/exchange', {
                exchangeCode: exchange_id.value,
            }).then(resp => {
                if (resp.code == 200) {
                    Modal.getInstance("#do-exchange").hide();
                    ElNotification({
                        message: '兑换成功！',
                        showClose: false,
                        duration: 1500,
                        type: 'success',
                    });
                }
                else {
                    ElNotification({
                        message: resp.data,
                        showClose: false,
                        duration: 1500,
                        type: 'error',
                    })
                }
            }).catch(() => {
                ElNotification({
                    message: '兑换失败',
                    showClose: false,
                    duration: 1500,
                    type: 'error',
                })
            })
        }

        const do_buy_regret = () => {
            axios.post('/shopping/buy', {
                count: regret_num.value,
                type: 1,
            }).then(resp => {
                Modal.getInstance("#do-regret").hide();
                if (resp.code == 200) {
                    ElNotification({
                        message: '购买成功！',
                        showClose: false,
                        duration: 1500,
                        type: 'success',
                    });
                    regret_count_db.value += regret_num.value;
                    store.commit("updateCurrency", resp.data);
                }
                else {
                    ElNotification({
                        message: '余额不足，请先充值',
                        showClose: false,
                        duration: 1500,
                        type: 'error',
                    });
                }
            }).catch(() => {
                ElNotification({
                    message: '网络错误，购买失败',
                    showClose: false,
                    duration: 1500,
                    type: 'error',
                })
            })
        }

        const do_buy_score = () => {
            axios.post('/shopping/buy', {
                count: score_num.value,
                type: 2,
            }).then(resp => {
                Modal.getInstance("#do-score").hide();
                if (resp.code == 200) {
                    ElNotification({
                        message: '购买成功！',
                        showClose: false,
                        duration: 1500,
                        type: 'success',
                    });
                    score_count_db.value += score_num.value;
                    store.commit("updateCurrency", resp.data);
                }
                else {
                    ElNotification({
                        message: '余额不足，请先充值',
                        showClose: false,
                        duration: 1500,
                        type: 'error',
                    });
                }
            }).catch(() => {
                ElNotification({
                    message: '网络错误，购买失败',
                    showClose: false,
                    duration: 1500,
                    type: 'error',
                })
            })
        }

        const do_buy_member = () => {
            axios.post('/shopping/join/member', {
                type: member_duration.value,
            }).then(resp => {
                Modal.getInstance("#do-member").hide();
                if (resp.code == 200) {
                    ElNotification({
                        message: '开通成功！',
                        showClose: false,
                        duration: 1500,
                        type: 'success',
                    });
                    store.commit("updateCurrency", resp.data);
                    store.commit("updateMember", true);
                }
                else {
                    ElNotification({
                        message: '余额不足，请先充值',
                        showClose: false,
                        duration: 1500,
                        type: 'error',
                    });
                }
            }).catch(() => {
                ElNotification({
                    message: '网络错误，开通失败',
                    showClose: false,
                    duration: 1500,
                    type: 'error',
                })
            })
        }

        const handleRegretChange = (regret_num) => {
            regret_count.value = 0.01 * regret_num / 10;
        }

        const handleScoreChange = (score_num) => {
            score_count.value = 0.01 * score_num / 10;
        }
        return {
            loading,
            exchange_id,
            currency_db,
            regret_num,
            score_num,
            regret_count,
            score_count,
            regret_count_db,
            score_count_db,
            member_duration,
            is_member,
            expire_time,
            handleRegretChange,
            handleScoreChange,
            do_exchange,
            do_buy_regret,
            do_buy_score,
            do_buy_member,
        }
    }
}
</script>

<style scoped>
.title {
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
}

img {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
}

.image:hover {
    transform: scale(1.25);
}

.modal-center-component {
    display: flex;
    justify-content: center;
}

.btn-do-op {
    background-color: rgb(82, 178, 130);
    border-color: aliceblue;
}

.btn-do-op:hover {
    background-color: rgb(52, 139, 97);
}

el-card {
    min-height: 100px;
    min-width: 100px;
}

.user-avatar {
    width: 3vw;
    height: 3vw;
}

.text {
    font-size: 18px;
    text-decoration: none;
}
</style>