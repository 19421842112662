import axios from "axios";

const PuzzleModule = {
    state: {

    },
    getters: {
    },
    // 在mutations里更新state的状态 在actions里调用, 在mutations里不能执行异步操作
    mutations: {

    },
    actions: {
        solvePuzzle(context, puzzleInfo) {
            axios.get('/puzzle/solve', {
                params: {
                    puzzle_id: puzzleInfo.puzzle_id,
                    puzzle_level: puzzleInfo.puzzle_level
                }
            }).then(resp => {
                if (resp.code == 200) {
                    puzzleInfo.success(resp.data);
                }
            })
        },

        attemptPuzzle(context, puzzleInfo) {
            axios.get('/puzzle/attempt', {
                params: {
                    puzzle_id: puzzleInfo.puzzle_id,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    console.log(resp);
                }
            })
        }
    },
    modules: {
    }
}

export default PuzzleModule;