<template>
    <el-empty description="这里空空如也" v-if="show_blank == true" />
    <el-skeleton :loading="loading" :rows="6" animated style="margin-top: 20px;"></el-skeleton>
    <el-table :data="notifications" v-if="!show_blank && !loading" style="text-align:center;margin-top: 10px;"
        align="center" stripe>
        <el-table-column label="用户" align="left" header-align="center">
            <template v-slot="{ row }">
                <div style="padding-left: 60px;">
                    <img :src="row.sendUserAvatar" alt="" class="notification-user-avatar"
                        @click="nav_to_user_space(row.sendUserid)">
                    &nbsp;
                    <span class="notification-user-username"> {{ row.sendUsername }}</span>
                </div>
            </template>
        </el-table-column>

        <el-table-column label="消息" align="center">
            <template v-slot="{ row }">
                <span v-if="row.type == 1">评论了</span>
                <span v-else-if="row.type == 2">回复了</span>
                <span v-else-if="row.type == 3">赞了</span>
                <span v-else-if="row.type == 4">关注了我</span>
                <span v-else-if="row.type == 5">分享了棋谱</span>
                <span v-else-if="row.type == 6">拒绝了您的关注请求</span>
                &nbsp;
                <router-link :to="{ name: 'post_details', params: { postId: row.attachId } }" v-if="row.type <= 3">
                    <span class="notification-user-username">
                        你的帖子
                    </span>
                </router-link>
                <router-link :to="{ name: 'record_review', params: { recordId: row.attachId } }" v-if="row.type == 5">
                    <span class="notification-user-username">
                        详细
                    </span>
                </router-link>
            </template>
        </el-table-column>

        <el-table-column label="时间" align="center">
            <template v-slot="{ row }">
                <el-tag color="#cceeff">{{ row.createTime }}</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
            <template v-slot="{ row }">
                <el-tag type="info" v-if="row.read == 0" class="unread-status" @click="read_notification(row)">标为已读</el-tag>
                <el-tag type="success" v-else>已读</el-tag>
            </template>
        </el-table-column>
    </el-table>

    <!-- 列表分页器 -->
    <div class="block" style="margin-top:10px; float: right">
        <el-pagination @current-change="handle_current_change" :current-page="current_page" :page-size="page_size"
            background hide-on-single-page @size-change="handle_size_change" layout="prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import router from '@/router';
import { total_notification_unread } from '@/components/base/NavBar.vue';

export default {
    setup() {
        let notifications = ref([]);
        let show_blank = ref(false);
        let current_page = ref(1);
        let total = ref(0);
        let loading = ref(true);
        let page_size = ref(11);

        onMounted(() => {
            pull_all_notifications();
        })

        const pull_all_notifications = () => {
            loading.value = true;
            axios.get('/notification/get', {
                params: {
                    pageNum: 0,
                    pageSize: page_size.value,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    notifications.value = resp.data.list;
                    total.value = resp.data.totalElements;
                    if (resp.data.totalElements == 0) show_blank.value = true;
                    loading.value = false;
                }
            })
        }

        const nav_to_user_space = (user_id) => {
            router.push({
                name: "user_index",
                params: {
                    userId: user_id
                }
            })
        }

        const read_notification = (notification) => {
            axios.post('/notification/read', {
                notificationId: notification.id,
            }).then(resp => {
                if (resp.code == 200) {
                    notification.read = 1;
                    if (total_notification_unread.value > 0) total_notification_unread.value--;
                } else {
                    ElMessage.warning("网络波动，请刷新重试");
                }
            })
        }

        //当前页改变时触发 跳转其他页
        const handle_current_change = (val) => {
            loading.value = true;
            axios.get('/notification/get', {
                params: {
                    pageNum: val - 1,
                    pageSize: page_size.value,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    current_page.value = val;
                    total.value = resp.data.totalElements;
                    notifications.value = resp.data.list;
                    loading.value = false;
                }
            })
        }

        const handle_size_change = (val) => {
            page_size.value = val;
            pull_all_notifications();
        }

        const nav_to_record = (record_id) => {
            router.push({
                name: "record_review",
                params: {
                    recordId: record_id
                }
            })
        }

        return {
            notifications,
            show_blank,
            current_page,
            total,
            page_size,
            loading,
            handle_current_change,
            handle_size_change,
            nav_to_user_space,
            read_notification,
            nav_to_record,
        }
    }
}
</script>

<style scoped>
img.notification-user-avatar {
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;
}

.notification-user-username {
    font-weight: 500;
    cursor: pointer;
}

.unread-status {
    cursor: pointer;
}
</style>