<template>
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark hd">
        <div class="container">
            <a class="navbar-brand ms-5" href="#">
                <span class="active">
                    <img src="https://fcjznkj.oss-cn-qingdao.aliyuncs.com/img/logo.7656c479.png" class="logo">
                </span>
            </a>
            <div class="navbar-brand">
                <span style="font-weight: 500;">
                    方寸围棋
                </span>
            </div>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav me-auto mb-lg-0 ml-5">
                    <li class="nav-item nv">
                        <router-link v-bind:class="route_name == 'goplay' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'goplay' }">对弈</router-link>
                    </li>
                    <li class="nav-item nv">
                        <router-link v-bind:class="route_name == 'playhall' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'playhall' }">大厅</router-link>
                    </li>
                    <li class="nav-item nv">
                        <router-link v-bind:class="route_name == 'record' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'record' }">棋谱</router-link>
                    </li>
                    <li class="nav-item nv">
                        <router-link v-bind:class="route_name == 'public_puzzles' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'public_puzzles' }">练习</router-link>
                    </li>
                    <li class="nav-item nv">
                        <router-link v-bind:class="route_name == 'friend' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'friend' }">好友</router-link>
                    </li>
                    <li class="nav-item nv">
                        <router-link v-bind:class="route_name == 'community' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'community' }">社区</router-link>
                    </li>
                    <li class="nav-item nv">
                        <router-link v-bind:class="route_name == 'recharge_normal' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'recharge_normal' }">充值</router-link>
                    </li>
                    <li class="nav-item nv">
                        <router-link v-bind:class="route_name == 'shop_index' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'shop_index' }">商城</router-link>
                    </li>
                </ul>

                <ul class="navbar-nav me-1 ms-5" v-if="is_login">
                    <li class="nav-item">
                        <el-badge :value="total_notification_unread <= 0 ? '' : total_notification_unread">
                            <router-link :to="{ name: 'user_notification' }">
                                <img src="@/assets/images/icon_notification.png" class="icon_notification">
                            </router-link>
                        </el-badge>
                    </li>
                </ul>
                <ul class="navbar-nav me-auto ms-3" v-if="is_login">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">
                            <span style="font-weight: 400;">{{ $store.state.user.username }}</span>
                        </a>
                        <ul class="dropdown-menu">
                            <li>
                                <div class="dropdown-item d-flex justify-content-start align-items-center">
                                    <el-space :size="3" spacer=" ">
                                        <img src="@/assets/images/fcb.png" class="icon_fcb">
                                        <span style="font-size: small;">{{ $store.state.user.currency }}</span>
                                    </el-space>
                                </div>
                            </li>
                            <li>
                                <router-link class="dropdown-item"
                                    :to="{ name: 'user_index', params: { userId: $store.state.user.id } }">
                                    <span style="font-size: small;">我的空间</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" :to="{ name: 'update_info' }">
                                    <span style="font-size: small;">个人信息</span>
                                </router-link>
                            </li>
                            <li v-if="$store.state.user.type === 'ADMIN'">
                                <hr class="dropdown-divider">
                                <router-link class="dropdown-item" :to="{ name: 'admin_base' }">
                                    <span style="font-size: small;">管理员</span>
                                </router-link>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li v-if="$store.state.gogame.room_id == null || $store.state.gogame.room_id == ''">
                                <a class="dropdown-item" href="#" @click="logout">
                                    <span style="font-size: small;">退出登陆</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item d-flex justify-content-center align-items-center" v-if="$store.state.user.is_member">
                        <img src="@/assets/images/vip.png" class="icon_notification">
                    </li>
                </ul>

                <!--若用户未登录则展示登录-注册界面-->
                <ul class="navbar-nav me-auto" v-if="!is_login">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'login' }">登录/注册</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
    
<script>
import { useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export let total_notification_unread = ref(0);

export default {
    components: {

    },
    setup() {
        const route = useRoute();
        const store = useStore();
        let show_nav = ref(true);
        let route_name = computed(() => route.name);
        const is_login = computed(() => store.state.user.is_login == true);

        const logout = () => {
            store.commit("updateWhich", 0);
            store.commit("updateCurrent", 0);
            store.commit("updateBoard", null);
            store.dispatch("logout");
        }

        return {
            route_name,
            total_notification_unread,
            is_login,
            show_nav,
            logout,
        }
    },
}
</script>
    
<style scoped>
.nv {
    margin-left: 1vw;
}

.icon_notification {
    width: 1.6vw;
    height: auto;
}

.icon_fcb {
    width: 1vw;
    height: 1vw;
}

.logo {
    width: 4.5vw;
    height: auto;
    margin: 0 10px auto 6vw;
    cursor: pointer;
    min-width: 60px;
}

.nav-item {
    min-width: 50px;
}
</style>