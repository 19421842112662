<template>
    <el-skeleton :loading="follower_loading" :rows="6" animated></el-skeleton>
    <el-empty description="您还没有粉丝" v-if="followers_empty_status == true"></el-empty>
    <div v-else-if="!follower_loading">
        <el-table :data="followers" style="width: 100%; text-align: center;" align="center" stripe max-height="600">
            <el-table-column align="left" header-align="center" label="用户" width="180">
                <template v-slot="{ row }">
                    <div style="padding-left: 20px;">
                        <img :src="row.avatar" alt="" class="user-avatar" @click="nav_to_user_space(row.id)">
                        &nbsp;
                        <span class="user-info"> {{ row.username }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center" :sortable="true" :sort-method="sortStatus">
                <template v-slot="{ row }">
                    <div v-if="row.state == true">
                        <el-tag type="success">在线</el-tag>
                    </div>
                    <div v-else>
                        <el-tag type="info">不在线</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="棋力" align="center" :sortable="true" :sort-method="sortLevel" width="80">
                <template v-slot="{ row }">
                    <el-tag :color="LEVEL[row.level].color">
                        <span style="color: white;">
                            {{ row.level }}
                        </span>
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="战绩" align="center">
                <template v-slot="{ row }">
                    <el-tag> {{ row.win + "胜" }} &nbsp;{{ row.lose + "负" }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button size="small" plain @click="follow(row)" v-if="row.follow === true">取消关注</el-button>
                    <el-button size="small" type="success" plaing @click="follow(row)" v-else>关注</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import router from '@/router';
import axios from 'axios';

export default {
    props: {
        followers: {
            type: Object,
            required: true,
        },
        followers_empty_status: {
            type: Boolean,
            required: true,
        },
        follower_loading: {
            type: Boolean,
            required: true,
        }
    },
    emits: ['change_reload_status'],
    setup(props, context) {
        const LEVEL = window.LEVEL;

        const follow = (user) => {
            axios.post('/friend/follow', {
                targetUsername: user.username,
            }).then(_ => {
                if (user.follow) {
                    user.follow = false;
                } else {
                    user.follow = true;
                }
                console.log(_)
                context.emit('change_reload_status');
            })
        }

        const nav_to_user_space = (user_id) => {
            router.push({
                name: "user_index",
                params: {
                    userId: user_id
                }
            })
        }

        return {
            LEVEL,
            follow,
            nav_to_user_space,
        }
    },

    methods: {
        sortLevel(a, b) {
            const aL = this.LEVEL[a.level].level;
            const bL = this.LEVEL[b.level].level;
            if (aL > bL) return 1;
            else if (aL < bL) return -1;
            else return 0;
        },

        sortStatus(a, b) {
            let aS = a.state == true ? 1 : -1;
            let bS = b.state == true ? 1 : -1;
            if (aS > bS) return -1;
            else if (aS < bS) return 1;
            else return 0;
        }
    }
}
</script>

<style scoped>
.user-info {
    font-weight: 500;
}

img.user-avatar {
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
    cursor: pointer;
}
</style>