<template>
    <el-empty description="没有添加方寸棋盘" v-if="show_blank == true"></el-empty>
    <el-table :data="user_devices" style="text-align:center;margin-top: 10px;" align="center" stripe v-else>
        <el-table-column label="设备id" align="center">
            <template v-slot="{ row }">
                <span style="font-size: medium; color: rgb(42, 65, 128);"> {{ row.carryId }}</span>
            </template>
        </el-table-column>
        <el-table-column label="备注" align="center" width="120">
            <template v-slot="{ row }">
                <span style="color: rgb(83, 157, 218); font-size: medium;"> {{ row.commentName }}</span>
            </template>
        </el-table-column>

        <el-table-column label="状态" align="center">
            <template v-slot="{ row }">
                <span v-if="row.online" style="color: green;">在线</span>
                <span v-else style="color:rgb(129, 144, 157)">离线</span>
            </template>
        </el-table-column>

        <el-table-column label="绑定时间" align="center">
            <template v-slot="{ row }">
                <span>{{ row.createTime }}</span>
            </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
            <template v-slot="{ row }">
                <el-button type="success" size="small" v-if="!is_me && row.online" data-bs-toggle="modal"
                    data-bs-target="#invite" @click="invite(row.carryId, row.userId)">邀请对局</el-button>
                <el-button type="primary" size="small" v-else-if="is_me">编辑</el-button>
                <el-button type="info" size="small" disabled v-else>邀请对局</el-button>
            </template>
        </el-table-column>
    </el-table>

    <!-- 邀请好友模态框 -->
    <div class="modal fade" id="invite" tabindex="-1">
        <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header modal-center-component">
                    <h1 class="modal-title fs-5">邀请好友</h1>
                </div>
                <div class="modal-body">
                    <div class="modal-center-component">
                        <el-form>
                            <el-form-item label="类型">
                                <el-select v-model="room_type" placeholder="五子棋/围棋">
                                    <el-option label="围棋" :value=1></el-option>
                                    <el-option label="五子棋" :value=2></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="棋手">
                                <el-select v-model="room_order" placeholder="请选择先后手">
                                    <el-option label="执黑" value="1"></el-option>
                                    <el-option label="执白" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="modal-footer modal-center-component">
                    <button type="button" class="btn btn-primary btn-function" @click="invite_play"> 邀请
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { request_play } from '@/App.vue';
import axios from 'axios';

export default {
    props: {
        userId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();
        const is_me = computed(() => props.userId == store.state.user.id);
        let show_blank = ref(false);
        let user_devices = ref([]);
        let invite_carry_id = ref();
        let invite_friend_id = ref();
        let room_type = ref(1);
        let room_order = ref("1");

        onMounted(() => {
            axios.get('/user/device/get', {
                params: {
                    userId: props.userId,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    user_devices.value = resp.data;
                    if (resp.data.length == 0) show_blank.value = true;
                }
            })
        })

        const invite_play = () => {
            Modal.getInstance("#invite").hide();
            let socket = store.state.gogame.socket;
            if (socket != null) {
                store.state.gogame.socket.send(JSON.stringify({
                    event: "request_play",
                    user: "carry",
                    type: room_type.value,
                    size: 13,
                    order: room_order.value,
                    device_id: invite_carry_id.value,
                }));
                request_play.alert('等待对方回应', {
                    confirmButtonText: '取消',
                    type: 'info',
                    center: true,
                    callback: () => {
                        store.state.gogame.socket.send(JSON.stringify({
                            event: "request_cancel",
                            friend_id: invite_friend_id.value,
                            device_id: invite_carry_id.value,
                        }));
                    }
                })
            }
            else {
                ElMessage.warning("请保持您只有一个账号在线");
            }

        }

        const invite = (carry_id, friend_id) => {
            invite_carry_id.value = carry_id;
            invite_friend_id.value = friend_id;
        }

        return {
            user_devices,
            is_me,
            show_blank,
            room_type,
            room_order,
            invite,
            invite_play,
        }
    }
}
</script>

<style scoped>
.modal-center-component {
    display: flex;
    justify-content: center;
}

.btn-function {
    background-color: rgb(140, 181, 235);
    border-color: aliceblue;
}

.btn-function:hover {
    background-color: rgb(65, 113, 143);
}
</style>