<template>
    <ContentBase>
        <PuzzleCollection>

        </PuzzleCollection>
    </ContentBase>
</template>

<script>
import PuzzleCollection from '@/components/puzzle/PuzzleCollectionView.vue';
import ContentBase from '@/components/base/ContentBase.vue';

export default {
    components: {
        ContentBase,
        PuzzleCollection,
    }
}
</script>

<style scoped></style>