<template>
    <div class="container">
        <div class="card">
            <div class="card-body">
                <slot class="child"></slot>
                <!--slot存放父组件传来的所有children-->
            </div>
        </div>
    </div>

</template>
  
<script>
export default {
    
}
</script>
  
<style scoped>
.container {
    margin-top: 10vh;
    margin-bottom: 3vh;
    width: 60%;
}

.card-body {
    display: flex;
    align-items: center; /* 水平居中 */
    flex-direction: column; /* 竖直排列 */
    text-align: center;
    height: auto;
    width: auto;
}
</style>