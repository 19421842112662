<template>
    <div class="room-users">
        <!--所有在房间的人-->
        <el-table :data="users_in_room.data" style="width: 100%; text-align: center;" align="center" stripe
            max-height="500">
            <el-table-column label="用户" align="center">
                <template v-slot="{ row }">
                    <img :src="row.user_avatar" alt="" class="user-avatar-guest">
                    &nbsp;
                    <span> {{ row.user_name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="棋力" align="center" width="80">
                <template v-slot="{ row }">
                    <el-tag :color="LEVEL[row.user_level].color">
                        <span style="color: white;">
                            {{ row.user_level }}
                        </span>
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="胜" align="center">
                <template v-slot="{ row }">
                    <span> {{ row.user_win }}</span>
                </template>
            </el-table-column>
            <el-table-column label="负" align="center">
                <template v-slot="{ row }">
                    <span> {{ row.user_lose }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { reactive } from 'vue';

export let users_in_room = reactive({
    data: [],
});
export default {
    props: {
        roomId: {
            type: String,
            required: true,
        }
    },
    setup() {
        const LEVEL = window.LEVEL;
        return {
            users_in_room,
            LEVEL,
        }
    },
}
</script>

<style scoped>
.room-users {
    margin-top: 2vh;
}

.user-avatar-guest {
    border-radius: 10%;
    width: 4vh;
    height: 4vh;
    text-align: center;
}
</style>